import { useState, useMemo } from 'react';
import * as Icons from '@revel-transit/revel-icons';
import {
  SpotlightProvider,
  SpotlightAction,
  DriverAvatar,
} from '@revel-transit/revel-components-internal';
import { gql } from '@apollo/client';
import { useRouter } from 'next/router';
import { useSpotlightDriverSearchQuery } from 'generated/page';
import { useSession } from 'next-auth/client';
import { triggerApolloErrorNotification } from 'services/utils';

gql`
  query SpotlightDriverSearch($filters: DriverOpsDriversFilters) {
    drivers: getDriverOpsDrivers(filters: $filters) {
      results {
        id
        avatar
        firstName
        lastName
      }
    }
  }
`;

export function Spotlight() {
  const router = useRouter();
  const [query, setQuery] = useState('');
  const [session] = useSession();

  const { data } = useSpotlightDriverSearchQuery({
    variables: {
      filters: {
        search: query,
      },
    },
    skip: !session,
    onError: (err) => {
      triggerApolloErrorNotification(err, 'Error loading drivers');
    },
  });

  const isLoadingData = !data;

  const actions: SpotlightAction[] = useMemo(() => {
    if (!query || isLoadingData) return [];
    return data.drivers.results.map((driver) => ({
      id: driver.id,
      title: `${driver.firstName} ${driver.lastName}`,
      icon: (
        <DriverAvatar
          size={32}
          src={driver.avatar}
          firstName={driver.firstName}
          lastName={driver.lastName}
        />
      ),
      onTrigger: () => {
        router.push(`/drivers/${driver.id}`);
      },
    }));
  }, [data]);

  return (
    <SpotlightProvider
      query={query}
      onQueryChange={setQuery}
      actions={actions}
      searchIcon={<Icons.MagnifyingGlass />}
      searchPlaceholder="Search drivers..."
      nothingFoundMessage="Nothing found..."
      filter={() => actions}
    />
  );
}
