import { signOut, useSession } from 'next-auth/client';
import Link from 'next/link';
import {
  Text,
  Box,
  Group,
  NavLink,
  NavTabs,
  Divider,
  Button,
  ActionIcon,
  Tooltip,
  Burger,
  Drawer,
  createStyles,
  spotlight,
  Title,
  useSmallerThan,
  ActionIconProps,
} from '@revel-transit/revel-components-internal';
import * as Icons from '@revel-transit/revel-icons';

import { useRouter } from 'next/router';
import { useState } from 'react';

const pages = [
  {
    label: 'Shifts',
    href: '/shifts',
    icon: <Icons.City />,
  },
  {
    label: 'Schedules',
    href: '/schedules',
    icon: <Icons.Calendar />,
  },
  {
    label: 'Map',
    href: '/map',
    icon: <Icons.Map />,
  },
  {
    label: 'Drivers',
    href: '/drivers',
    icon: <Icons.Person />,
  },
  {
    label: 'Vehicles',
    href: '/vehicles',
    icon: <Icons.Car />,
  },
  {
    label: 'Invoices',
    href: '/invoices',
    icon: <Icons.Money />,
  },
];

function SearchButton({ size = 'sm' }: { size?: ActionIconProps['size'] }) {
  return (
    <Tooltip label="Driver Search (⌘K)" withArrow>
      <ActionIcon
        variant="subtle"
        color="gray"
        size={size}
        onClick={() => {
          spotlight.open();
        }}
        aria-label="Universal Search"
      >
        <Icons.MagnifyingGlass />
      </ActionIcon>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  headerWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.other.headerHeight,
    flexShrink: 0,
    zIndex: 100,
    borderBottom: `2px solid ${theme.fn.themeColor('gray', 2)}`,
  },
  headerContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: `0 ${theme.spacing.md}`,
  },
}));

export function AppHeader() {
  const [session] = useSession();

  const router = useRouter();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useSmallerThan('md');

  const { classes } = useStyles();

  const currentPage = pages.find((page) => router.asPath.startsWith(page.href));

  if (!session) return null;

  return (
    <Box component="header" className={classes.headerWrap}>
      <Box className={classes.headerContent}>
        {!isMobile && (
          <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', gap: theme.spacing.md })}>
            <Box sx={(theme) => ({ flexShrink: 0, color: theme.fn.themeColor('brand', 4) })}>
              <Icons.RevelLogoText width={64} />
            </Box>
            <Text fw="600">Driver Ops</Text>
          </Box>
        )}
        {isMobile && (
          <>
            {currentPage && <Title size={20}>{currentPage.label}</Title>}
            <Group ml="auto">
              <SearchButton size="md" />
              <Burger opened={drawerOpen} onClick={() => setDrawerOpen(!drawerOpen)} />
            </Group>
            <Drawer
              opened={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              position="top"
              size="auto"
              withCloseButton={false}
              overlayProps={{
                opacity: 0.3,
              }}
              transitionProps={{
                transition: 'scale-y',
              }}
              styles={(theme) => ({
                root: {
                  top: theme.other.headerHeight,
                  left: 0,
                  right: 0,
                },
                inner: {
                  top: theme.other.headerHeight,
                  left: 0,
                  right: 0,
                },
                overlay: {
                  top: theme.other.headerHeight,
                },
              })}
            >
              {pages.map((page) => (
                <NavLink
                  key={page.href}
                  active={router.asPath.startsWith(page.href)}
                  component="a"
                  href={page.href}
                  label={page.label}
                  icon={page.icon}
                  sx={(theme) => ({
                    height: '48px',
                    fontWeight: 600,
                    '&[data-active]': {
                      color: theme.fn.themeColor('blue', 6),
                      backgroundColor: theme.fn.themeColor('blue', 0),
                    },
                  })}
                />
              ))}
              <Divider color="gray.1" />
              <NavLink
                sx={{
                  height: '48px',
                  fontWeight: 600,
                }}
                label="Logout"
                icon={<Icons.Logout />}
                onClick={() => signOut()}
              />
            </Drawer>
          </>
        )}
        {!isMobile && (
          <>
            <NavTabs
              sx={{
                position: 'absolute',
                top: '0',
                left: '46%',
                transform: 'translateX(-41%)',
              }}
            >
              {pages.map((page) => (
                <Link href={page.href} key={page.href} passHref>
                  <NavTabs.Tab
                    leftIcon={page.icon}
                    active={router.asPath.startsWith(page.href)}
                    sx={(theme) => ({
                      height: theme.other.headerHeight,
                      [theme.fn.smallerThan('lg')]: {
                        fontSize: theme.fontSizes.xs,
                        padding: theme.spacing.xs,

                        '& svg': {
                          width: '20px',
                          height: '20px',
                        },
                      },
                    })}
                  >
                    {page.label}
                  </NavTabs.Tab>
                </Link>
              ))}
            </NavTabs>

            <Group spacing="sm" ml="auto">
              <SearchButton />

              <Button variant="subtle" color="gray" size="sm" onClick={() => signOut()} px={8}>
                Logout
              </Button>
            </Group>
          </>
        )}
      </Box>
    </Box>
  );
}
