/* eslint-disable */
/* @ts-nocheck */
// THIS FILE IS GENERATED AUTOMATICALLY FOR driver-ops, DO NOT EDIT DIRECTLY
// This file contains Types, Interfaces, enums, namedOperations
import { Date } from '../../../../customScalars';
import { Time } from '../../../../customScalars';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Coordinates: { input: any; output: any };
  CurrentSafetyExamJson: { input: any; output: any };
  Date: { input: Date; output: Date };
  DateTime: { input: any; output: any };
  Geojson: { input: any; output: any };
  JSON: { input: any; output: any };
  SegmentData: { input: any; output: any };
  SignedUrls: { input: any; output: any };
  Time: { input: Time; output: Time };
};

export type AboundToken = {
  __typename?: 'AboundToken';
  aboundToken: Scalars['String']['output'];
};

export type ActiveCXRentalsList = {
  __typename?: 'ActiveCXRentalsList';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<CXRental>>;
  systemCounts?: Maybe<Array<ActiveRentalCountBySystem>>;
  totalCount: Scalars['Int']['output'];
};

export type ActiveRentalCountBySystem = {
  __typename?: 'ActiveRentalCountBySystem';
  count: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
};

export type ActiveRentalsFilters = {
  ordering?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddRentalIdToCommunityReportInput = {
  communityReportId: Scalars['ID']['input'];
  rentalId: Scalars['ID']['input'];
};

export type AddRenterViolationImagesInput = {
  hasParkingCameraViolation?: InputMaybe<Scalars['Boolean']['input']>;
  hasParkingTicket?: InputMaybe<Scalars['Boolean']['input']>;
  hasTowTicket?: InputMaybe<Scalars['Boolean']['input']>;
  renterId: Scalars['ID']['input'];
  renterViolationId: Scalars['ID']['input'];
};

export type AddRenterViolationInput = {
  action: ViolationActions;
  cxAgentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  rentalId?: InputMaybe<Scalars['ID']['input']>;
  renterId: Scalars['ID']['input'];
  rideHailId?: InputMaybe<Scalars['ID']['input']>;
  summonsImageUrl?: InputMaybe<Scalars['String']['input']>;
  summonsNumber?: InputMaybe<Scalars['String']['input']>;
  suspensionDays?: InputMaybe<Scalars['Int']['input']>;
  violationName: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  countryName?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  location: Point;
  name?: Maybe<Scalars['String']['output']>;
  pointOfInterest?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  sourceIdentifier: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type AgreeToReservationContractInput = {
  legalDocumentId: Scalars['ID']['input'];
  reservationId: Scalars['ID']['input'];
};

export type Amenity = {
  __typename?: 'Amenity';
  amenityType: AmenityTypes;
  id: Scalars['ID']['output'];
  location: Point;
  metadata?: Maybe<AmenityMetadata>;
  source?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['String']['output']>;
};

export type AmenityFilters = {
  amenityType?: InputMaybe<AmenityTypes>;
  location?: InputMaybe<PointInput>;
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type AmenityMetadata = {
  __typename?: 'AmenityMetadata';
  numberOfSpaces?: Maybe<Scalars['Int']['output']>;
};

export type AmenityResponse = {
  __typename?: 'AmenityResponse';
  count: Scalars['Int']['output'];
  results: Array<Amenity>;
};

export enum AmenityTypes {
  fhv_spot = 'fhv_spot',
}

export type Assignment = {
  __typename?: 'Assignment';
  assignmentType: Scalars['String']['output'];
  averageDriveTime?: Maybe<Scalars['Int']['output']>;
  averageWorkOrderDuration?: Maybe<Scalars['Int']['output']>;
  batteryLevelThreshold?: Maybe<Scalars['Int']['output']>;
  batterySkipCount: Scalars['Int']['output'];
  batterySwapCount: Scalars['Int']['output'];
  completedWorkOrderCount: Scalars['Int']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Identity>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  dispatchNotes?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastLocation?: Maybe<Point>;
  manualSkippedWorkOrderCount?: Maybe<Scalars['Int']['output']>;
  operator?: Maybe<Operator>;
  remainingWorkOrderCount?: Maybe<Scalars['Int']['output']>;
  resourceTasks?: Maybe<Array<Task>>;
  skippedWorkOrderCount: Scalars['Int']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
  startLocation?: Maybe<Point>;
  status?: Maybe<AssignmentStatus>;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  systemSkippedWorkOrderCount?: Maybe<Scalars['Int']['output']>;
  taskTypeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  taskTypes?: Maybe<Array<TaskType>>;
  totalWorkOrderCount: Scalars['Int']['output'];
};

export type AssignmentresourceTasksArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentFilters = {
  assignmentId?: InputMaybe<Scalars['String']['input']>;
  assignmentType?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  includeDev?: InputMaybe<Scalars['Boolean']['input']>;
  operatorId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type AssignmentList = {
  __typename?: 'AssignmentList';
  count: Scalars['Int']['output'];
  results: Array<Assignment>;
};

export type AssignmentOperatorLocation = {
  __typename?: 'AssignmentOperatorLocation';
  id: Scalars['ID']['output'];
  location: Point;
};

export enum AssignmentStatus {
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  DELETED = 'DELETED',
  DELETING = 'DELETING',
  DRAFT = 'DRAFT',
  PROCESSING = 'PROCESSING',
  UPCOMING = 'UPCOMING',
}

export type BalancerMapConfig = {
  __typename?: 'BalancerMapConfig';
  calculateRelativeBalance: Scalars['Boolean']['output'];
  deficitColorDark: Scalars['String']['output'];
  deficitColorLight: Scalars['String']['output'];
  maxDeficit: Scalars['Float']['output'];
  maxOpacity: Scalars['Float']['output'];
  maxSurplus: Scalars['Float']['output'];
  minDeficit: Scalars['Float']['output'];
  minOpacity: Scalars['Float']['output'];
  minSurplus: Scalars['Float']['output'];
  showDeficit: Scalars['Boolean']['output'];
  showSurplus: Scalars['Boolean']['output'];
  surplusColorDark: Scalars['String']['output'];
  surplusColorLight: Scalars['String']['output'];
};

export type CXAgent = {
  __typename?: 'CXAgent';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Resolver fetched fields */
  identity?: Maybe<Identity>;
  lastName?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<RoleMember>>>;
};

export type CXAgentFilters = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CXBillingFrequency {
  annual = 'annual',
  monthly = 'monthly',
  non_recurring = 'non_recurring',
  quarterly = 'quarterly',
  weekly = 'weekly',
}

export type CXCreditBalance = {
  __typename?: 'CXCreditBalance';
  created: Scalars['DateTime']['output'];
  creditDefinition: CXCreditDefinition;
  /** Resolver fetched fields */
  cxAgent?: Maybe<CXAgent>;
  cxAgentId?: Maybe<Scalars['String']['output']>;
  cxReason?: Maybe<Scalars['String']['output']>;
  cxReasonDescription?: Maybe<Scalars['String']['output']>;
  expiredByIdentity?: Maybe<Identity>;
  expiredByIdentityAt?: Maybe<Scalars['DateTime']['output']>;
  expiredByIdentityId?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  remainingBalance: Scalars['Int']['output'];
  startingBalance: Scalars['Int']['output'];
};

export type CXCreditDefinition = {
  __typename?: 'CXCreditDefinition';
  code?: Maybe<Scalars['String']['output']>;
  creditType: Scalars['String']['output'];
  internalDescription?: Maybe<Scalars['String']['output']>;
  internalName?: Maybe<Scalars['String']['output']>;
};

export type CXCurrentDiscountRates = {
  __typename?: 'CXCurrentDiscountRates';
  rental?: Maybe<CXRenterDiscountRate>;
  rideHail?: Maybe<CXRenterDiscountRate>;
};

export type CXDriver = {
  __typename?: 'CXDriver';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  stats?: Maybe<CXDriverStats>;
  tlcLicenseNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum CXDriverShiftEvaluatedStates {
  active_shifts = 'active_shifts',
  inactive_shifts = 'inactive_shifts',
  shifts_eligible_to_start = 'shifts_eligible_to_start',
  shifts_with_vehicle = 'shifts_with_vehicle',
}

export type CXDriverShiftFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  evaluatedState?: InputMaybe<CXDriverShiftEvaluatedStates>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  resourceId?: InputMaybe<Scalars['ID']['input']>;
  states?: InputMaybe<Array<CXDriverShiftStates>>;
};

export enum CXDriverShiftStates {
  activating = 'activating',
  active = 'active',
  canceled = 'canceled',
  complete = 'complete',
  draft = 'draft',
  driver_on_the_way = 'driver_on_the_way',
  no_show = 'no_show',
  scheduled = 'scheduled',
  scheduled_break = 'scheduled_break',
  stand_by = 'stand_by',
  unscheduled_break = 'unscheduled_break',
}

export type CXDriverStats = {
  __typename?: 'CXDriverStats';
  canceledRideCount?: Maybe<Scalars['Int']['output']>;
  completedRideCount?: Maybe<Scalars['Int']['output']>;
  noShowRideCount?: Maybe<Scalars['Int']['output']>;
};

export type CXIdVerification = {
  __typename?: 'CXIdVerification';
  cxAgentId?: Maybe<Scalars['ID']['output']>;
  faceImages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  idBackImage?: Maybe<Scalars['String']['output']>;
  idCountry?: Maybe<Scalars['String']['output']>;
  idDob?: Maybe<Scalars['Date']['output']>;
  idExpiry?: Maybe<Scalars['Date']['output']>;
  idFirstName?: Maybe<Scalars['String']['output']>;
  idFrontImage?: Maybe<Scalars['String']['output']>;
  idIssueDate?: Maybe<Scalars['Date']['output']>;
  idLastName?: Maybe<Scalars['String']['output']>;
  idNumber?: Maybe<Scalars['String']['output']>;
  idUsState?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use jumioIdentityVerificationSimilarity */
  identityVerificationSimilarity?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use jumioIdentityVerificationValidity */
  identityVerificationValidity?: Maybe<Scalars['Boolean']['output']>;
  jumioIdScanReference?: Maybe<Scalars['String']['output']>;
  jumioIdentityVerificationSimilarity?: Maybe<Scalars['String']['output']>;
  jumioIdentityVerificationValidity?: Maybe<Scalars['Boolean']['output']>;
  lastEdited?: Maybe<Scalars['DateTime']['output']>;
  /** Resolver fetched fields */
  lastEditedBy?: Maybe<Identity>;
  personaInquiryReferenceId?: Maybe<Scalars['String']['output']>;
  renterId: Scalars['String']['output'];
  transactionDate?: Maybe<Scalars['DateTime']['output']>;
  verificationStatus: Scalars['String']['output'];
};

export type CXIdVerificationLink = {
  __typename?: 'CXIdVerificationLink';
  link?: Maybe<Scalars['String']['output']>;
};

export type CXIneligibilityReason = {
  __typename?: 'CXIneligibilityReason';
  reasonName: CXIneligibiltyReasonName;
  state: CXIneligibilityState;
};

export enum CXIneligibilityState {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  INACTIVE = 'INACTIVE',
  INCOMPLETE = 'INCOMPLETE',
  SUSPENDED = 'SUSPENDED',
  UNAPPROVED = 'UNAPPROVED',
}

export enum CXIneligibiltyReasonName {
  android_terms_of_use_privacy_policy_reaccept_required = 'android_terms_of_use_privacy_policy_reaccept_required',
  background_check_consider = 'background_check_consider',
  background_check_failed = 'background_check_failed',
  background_check_pending = 'background_check_pending',
  background_check_suspended = 'background_check_suspended',
  deletion_requested = 'deletion_requested',
  drivers_license_agreement_not_accepted = 'drivers_license_agreement_not_accepted',
  duplicate_id = 'duplicate_id',
  ebike_manual_suspension = 'ebike_manual_suspension',
  ef_rejected = 'ef_rejected',
  face_mismatch = 'face_mismatch',
  fee_agreement_not_accepted = 'fee_agreement_not_accepted',
  fraud = 'fraud',
  id_expired = 'id_expired',
  id_verification_fraud = 'id_verification_fraud',
  id_verification_not_started = 'id_verification_not_started',
  id_verification_pending = 'id_verification_pending',
  id_verification_unexpected_response = 'id_verification_unexpected_response',
  invalid_default_payment_method = 'invalid_default_payment_method',
  learners_permit = 'learners_permit',
  manual_ban = 'manual_ban',
  manual_suspension = 'manual_suspension',
  need_to_improve_selfie = 'need_to_improve_selfie',
  no_payment_method = 'no_payment_method',
  payment_not_received = 'payment_not_received',
  privacy_policy_not_accepted = 'privacy_policy_not_accepted',
  rental_agreement_not_accepted = 'rental_agreement_not_accepted',
  ride_hail_agreement_not_accepted = 'ride_hail_agreement_not_accepted',
  ride_hail_manual_suspension = 'ride_hail_manual_suspension',
  ride_hail_manual_temporary_suspension = 'ride_hail_manual_temporary_suspension',
  safety_exam_not_completed = 'safety_exam_not_completed',
  safety_exam_refresh_required = 'safety_exam_refresh_required',
  safety_exam_retake_for_violation = 'safety_exam_retake_for_violation',
  second_idv_failure_contact_cx_required = 'second_idv_failure_contact_cx_required',
  temporary_suspension = 'temporary_suspension',
  terms_and_conditions_not_accepted = 'terms_and_conditions_not_accepted',
  under_18_or_not_licensed_less_than_year = 'under_18_or_not_licensed_less_than_year',
  under_21 = 'under_21',
  unreadable_id_scan = 'unreadable_id_scan',
  unsupported_id_country = 'unsupported_id_country',
  unsupported_id_type = 'unsupported_id_type',
  user_education_agreement_not_accepted = 'user_education_agreement_not_accepted',
}

export type CXInvoice = {
  __typename?: 'CXInvoice';
  appliedCreditTotal: Scalars['Int']['output'];
  /** @deprecated Use CXInvoiceItems.description = 'billable_duration_reduction' */
  billableDurationReduction?: Maybe<Scalars['Int']['output']>;
  category: CXInvoiceCategoryEnum;
  created: Scalars['DateTime']['output'];
  createdByIdentityId?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  cxInvoiceReason?: Maybe<Scalars['String']['output']>;
  cxInvoiceReasonFk?: Maybe<CXInvoiceReason>;
  description?: Maybe<Scalars['String']['output']>;
  discountRateReduction?: Maybe<Scalars['Int']['output']>;
  experiment?: Maybe<Scalars['String']['output']>;
  experimentGroup?: Maybe<Scalars['String']['output']>;
  experimentalPricingReduction?: Maybe<Scalars['Int']['output']>;
  fromEf?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  invoiceItems?: Maybe<Array<CXInvoiceItems>>;
  mergedPaymentIntents?: Maybe<Array<PaymentIntent>>;
  /** @deprecated Use CXInvoiceItems.description = 'minimum_stripe_charge_reduction' */
  minimumStripeChargeReduction?: Maybe<Scalars['Int']['output']>;
  paymentIntents?: Maybe<Array<PaymentIntent>>;
  refundedByIdentity?: Maybe<Identity>;
  refundedByIdentityId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use CXInvoiceItems.description = 'rental_pass_reduction' */
  rentalPassReduction?: Maybe<Scalars['Int']['output']>;
  renterRentalPass?: Maybe<CXRenterRentalPass>;
  /** @deprecated Use CXInvoiceItems.description = 'renter_unable_to_ride_reduction' */
  renterUnableToRideReduction?: Maybe<Scalars['Int']['output']>;
  /** Resolver fetched fields */
  ridehail?: Maybe<CXRideHail>;
  ridehailId?: Maybe<Scalars['ID']['output']>;
  status: CXInvoiceStatus;
  subtotal: Scalars['Int']['output'];
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tax: Scalars['Int']['output'];
  taxPercentage: Scalars['Float']['output'];
  tipPaymentIntent?: Maybe<PaymentIntent>;
  totalAmount: Scalars['Int']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
  voidedByIdentity?: Maybe<Identity>;
  voidedByIdentityId?: Maybe<Scalars['ID']['output']>;
};

export enum CXInvoiceCategoryEnum {
  CUSTOMER_EXPERIENCE = 'CUSTOMER_EXPERIENCE',
  EBIKE = 'EBIKE',
  RENTAL = 'RENTAL',
  RENTAL_PASS = 'RENTAL_PASS',
  RIDE_HAIL = 'RIDE_HAIL',
  SIGN_UP_FEE = 'SIGN_UP_FEE',
}

export enum CXInvoiceCreatedReason {
  DAMAGED_STOLEN_LOST = 'DAMAGED_STOLEN_LOST',
  DAMAGES = 'DAMAGES',
  DISPUTE_FEE = 'DISPUTE_FEE',
  EBIKE_USER_CHARGEBACK = 'EBIKE_USER_CHARGEBACK',
  IMPROPER_PARKING = 'IMPROPER_PARKING',
  METER = 'METER',
  MOVING_VIOLATION = 'MOVING_VIOLATION',
  NO_PARKING = 'NO_PARKING',
  OTHER_FEE = 'OTHER_FEE',
  TICKET = 'TICKET',
  TOW = 'TOW',
  VIOLATION_FEE = 'VIOLATION_FEE',
  WAV_RIDE = 'WAV_RIDE',
}

export type CXInvoiceItems = {
  __typename?: 'CXInvoiceItems';
  amount: Scalars['Int']['output'];
  coupon?: Maybe<Coupon>;
  couponId?: Maybe<Scalars['ID']['output']>;
  description: Scalars['String']['output'];
  discountRate?: Maybe<DiscountRate>;
  dwellTimeUnit?: Maybe<Scalars['String']['output']>;
  dwellTimeValue?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  stopPointType?: Maybe<CXStopPointTypes>;
  territoryFee?: Maybe<TerritoryFee>;
  tollFee?: Maybe<TollFee>;
};

export type CXInvoiceReason = {
  __typename?: 'CXInvoiceReason';
  id: Scalars['ID']['output'];
  internalName: Scalars['String']['output'];
  product: Scalars['String']['output'];
  taxable?: Maybe<Scalars['Boolean']['output']>;
};

export type CXInvoiceReasonsFilter = {
  product?: InputMaybe<Scalars['String']['input']>;
};

export enum CXInvoiceStatus {
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  EF_OPEN = 'EF_OPEN',
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  VOIDED = 'VOIDED',
}

export type CXInvoicesList = {
  __typename?: 'CXInvoicesList';
  count: Scalars['Int']['output'];
  results: Array<CXInvoice>;
};

export type CXMobileVersions = {
  __typename?: 'CXMobileVersions';
  android?: Maybe<CXMobileVersionsSinglePlatform>;
  ios?: Maybe<CXMobileVersionsSinglePlatform>;
};

export type CXMobileVersionsSinglePlatform = {
  __typename?: 'CXMobileVersionsSinglePlatform';
  force?: Maybe<Scalars['String']['output']>;
  latestLive?: Maybe<Scalars['String']['output']>;
  warning?: Maybe<Scalars['String']['output']>;
};

export type CXOrder = {
  __typename?: 'CXOrder';
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
  addressRegion?: Maybe<Scalars['String']['output']>;
  addressStreet?: Maybe<Scalars['String']['output']>;
  addressStreet2?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledReason?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  curencyCode?: Maybe<Scalars['String']['output']>;
  deliveryInstructions?: Maybe<Scalars['String']['output']>;
  expectedDeliveryEnd?: Maybe<Scalars['DateTime']['output']>;
  expectedDeliveryStart?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fulfilmentStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lineItems?: Maybe<Array<CXOrderLineItem>>;
  middleName?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<CXOrderPaymentStatus>;
  phone?: Maybe<Scalars['String']['output']>;
  renterId?: Maybe<Scalars['ID']['output']>;
  subtotal?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<Scalars['Int']['output']>;
  totalAmount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CXOrderLineItem = {
  __typename?: 'CXOrderLineItem';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  frequency?: Maybe<CXBillingFrequency>;
  id: Scalars['ID']['output'];
  orderId: Scalars['ID']['output'];
  productOffer: CXProductOffer;
  productOfferId?: Maybe<Scalars['ID']['output']>;
  quantity: Scalars['Int']['output'];
  subscriptionId?: Maybe<Scalars['ID']['output']>;
  subtotal: Scalars['Int']['output'];
  taxable: Scalars['Boolean']['output'];
  unitCost: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum CXOrderPaymentStatus {
  canceled = 'canceled',
  denied = 'denied',
  paid_in_full = 'paid_in_full',
  pending = 'pending',
  processing = 'processing',
}

export type CXOrderSubscriptionCancellation = {
  __typename?: 'CXOrderSubscriptionCancellation';
  addressCity: Scalars['String']['output'];
  addressCountry: Scalars['String']['output'];
  addressPostalCode: Scalars['String']['output'];
  addressRegion: Scalars['String']['output'];
  addressStreet: Scalars['String']['output'];
  addressStreet2?: Maybe<Scalars['String']['output']>;
  canceledByIdentityId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  pickupInstructions?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  requestedPickupEnd?: Maybe<Scalars['DateTime']['output']>;
  requestedPickupStart?: Maybe<Scalars['DateTime']['output']>;
  subscriptionId: Scalars['ID']['output'];
};

export type CXOrderSubscriptionCancellationInput = {
  addressCity: Scalars['String']['input'];
  addressCountry: Scalars['String']['input'];
  addressPostalCode: Scalars['String']['input'];
  addressRegion: Scalars['String']['input'];
  addressStreet: Scalars['String']['input'];
  addressStreet2?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  pickupInstructions?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  requestedPickupEnd?: InputMaybe<Scalars['DateTime']['input']>;
  requestedPickupStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CXOrderSubscriptions = {
  __typename?: 'CXOrderSubscriptions';
  cancellation?: Maybe<CXOrderSubscriptionCancellation>;
  createdAt: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  frequency?: Maybe<CXBillingFrequency>;
  id: Scalars['ID']['output'];
  lastPaymentStatus?: Maybe<Scalars['String']['output']>;
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>;
  nextBillingSubtotal?: Maybe<Scalars['Int']['output']>;
  nextBillingTax?: Maybe<Scalars['Int']['output']>;
  nextBillingTotal?: Maybe<Scalars['Int']['output']>;
  order: CXOrder;
  orderLineItemId?: Maybe<Scalars['ID']['output']>;
  recurringCost?: Maybe<Scalars['Int']['output']>;
  renterId?: Maybe<Scalars['ID']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  subscriptionNumber?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['ID']['output']>;
  taxable?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CXOrderUpdateInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  addressRegion?: InputMaybe<Scalars['String']['input']>;
  addressStreet?: InputMaybe<Scalars['String']['input']>;
  addressStreet2?: InputMaybe<Scalars['String']['input']>;
  deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  expectedDeliveryEnd?: InputMaybe<Scalars['DateTime']['input']>;
  expectedDeliveryStart?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CXProduct = {
  __typename?: 'CXProduct';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  manufacturer: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CXProductOffer = {
  __typename?: 'CXProductOffer';
  availability: CXProductOfferStock;
  currencyCode: Scalars['String']['output'];
  frequency: CXBillingFrequency;
  id: Scalars['ID']['output'];
  nonRecurringCost: Scalars['Int']['output'];
  productVariant: CXProductVariant;
  recurringCost: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
  taxable: Scalars['Boolean']['output'];
};

export enum CXProductOfferStock {
  in_stock = 'in_stock',
  out_of_stock = 'out_of_stock',
}

export type CXProductVariant = {
  __typename?: 'CXProductVariant';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  product: CXProduct;
  updatedAt: Scalars['DateTime']['output'];
};

export type CXRental = {
  __typename?: 'CXRental';
  created: Scalars['DateTime']['output'];
  currentRentalStatus: CXRentalStatus;
  distanceTraveled?: Maybe<Scalars['Int']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  endAddress?: Maybe<Scalars['String']['output']>;
  fleetResource: FleetResource;
  fleetResourceId: Scalars['ID']['output'];
  fromEf?: Maybe<Scalars['Boolean']['output']>;
  gisViolationGeometries: Array<MultiLineStringType>;
  /** @deprecated in favor of gisViolationGeometries */
  gisViolationRoutes: Array<LineString>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<CXInvoice>;
  invoiceTotal?: Maybe<InvoiceLineItemDeprecated>;
  isBusinessProfile: Scalars['Boolean']['output'];
  numPassengers?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<CXRentalRating>;
  renter?: Maybe<CXRenter>;
  renterId?: Maybe<Scalars['ID']['output']>;
  renterRentalPass?: Maybe<CXRenterRentalPass>;
  route?: Maybe<LineString>;
  selfie?: Maybe<CXRentalSelfie>;
  start?: Maybe<Scalars['DateTime']['output']>;
  startAddress?: Maybe<Scalars['String']['output']>;
  streetCleaning?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  /** Resolver fetched fields */
  timeline?: Maybe<Array<RentalTimelineStatus>>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  userCreatedLocation: Point;
  userEndLocation?: Maybe<Point>;
  userStartLocation?: Maybe<Point>;
  userUpdatedLocation: Point;
  vehicleEndLocation?: Maybe<Point>;
  vehicleStartLocation?: Maybe<Point>;
};

export type CXRentalPass = {
  __typename?: 'CXRentalPass';
  hours: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  nameKey: Scalars['String']['output'];
  passType: CXRentalPassType;
  price: Scalars['Int']['output'];
  systemId: Scalars['String']['output'];
};

export enum CXRentalPassType {
  day = 'day',
  month = 'month',
}

export type CXRentalRating = {
  __typename?: 'CXRentalRating';
  category?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Int']['output'];
};

export type CXRentalSelfie = {
  __typename?: 'CXRentalSelfie';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  rider1HasHelmet?: Maybe<Scalars['Boolean']['output']>;
  rider1HasPerson?: Maybe<Scalars['Boolean']['output']>;
  rider1ImageUrl?: Maybe<Scalars['String']['output']>;
  rider1Valid?: Maybe<Scalars['Boolean']['output']>;
  rider2HasHelmet?: Maybe<Scalars['Boolean']['output']>;
  rider2HasPerson?: Maybe<Scalars['Boolean']['output']>;
  rider2ImageUrl?: Maybe<Scalars['String']['output']>;
  rider2Valid?: Maybe<Scalars['Boolean']['output']>;
  ridersValid?: Maybe<Scalars['Boolean']['output']>;
  state: RentalSelfieState;
  updatedAt: Scalars['DateTime']['output'];
};

export type CXRentalStatus = {
  __typename?: 'CXRentalStatus';
  actor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  start: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  userStartLocation: Point;
  vehicleAddress?: Maybe<Scalars['String']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type CXRentalsFilters = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  renterId?: InputMaybe<Scalars['ID']['input']>;
};

export type CXRenter = {
  __typename?: 'CXRenter';
  abTestVariant?: Maybe<Scalars['String']['output']>;
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  activeIneligibilities?: Maybe<Array<Ineligibility>>;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
  addressRegion?: Maybe<Scalars['String']['output']>;
  addressStreet?: Maybe<Scalars['String']['output']>;
  appVersion?: Maybe<Scalars['String']['output']>;
  completedRideHailCount?: Maybe<Scalars['Int']['output']>;
  convertedAt?: Maybe<Scalars['DateTime']['output']>;
  coupons?: Maybe<CouponList>;
  created: Scalars['DateTime']['output'];
  credit?: Maybe<Scalars['Int']['output']>;
  currentDiscountRates?: Maybe<CXCurrentDiscountRates>;
  currentState?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** @deprecated no longer using default system */
  defaultSystemId?: Maybe<Scalars['ID']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedByIdentityId?: Maybe<Scalars['ID']['output']>;
  deletedReason?: Maybe<Scalars['String']['output']>;
  deletionType?: Maybe<RenterDeletionTypes>;
  ebikeCurrentState?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  experimentGroup?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fromEf?: Maybe<Scalars['Boolean']['output']>;
  hasPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  idVerificationApproved?: Maybe<Scalars['DateTime']['output']>;
  isUnder21?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  mostRecentRentalSystem?: Maybe<System>;
  mostRecentRentalSystemId?: Maybe<Scalars['ID']['output']>;
  osName?: Maybe<OSName>;
  osVersion?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  problemReportCount?: Maybe<Scalars['Int']['output']>;
  profilePhoto?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  rentalCount?: Maybe<Scalars['Int']['output']>;
  rentalDistanceMeters?: Maybe<Scalars['Int']['output']>;
  renterAgreements: CXRenterAgreement;
  renterIneligibilities?: Maybe<Array<Maybe<CXRenterIneligibility>>>;
  renterStats: CXRenterStats;
  reportVerificationApproved?: Maybe<Scalars['DateTime']['output']>;
  reservationCount?: Maybe<Scalars['Int']['output']>;
  rideHailCurrentState?: Maybe<Scalars['String']['output']>;
  signUpSystem?: Maybe<System>;
  signUpSystemId?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['ID']['output']>;
  /**
   * Resolver fetched fields
   * @deprecated no longer using default system
   */
  system?: Maybe<System>;
  updated: Scalars['DateTime']['output'];
  waitlists?: Maybe<Array<CXWaitlist>>;
  zendeskId?: Maybe<Scalars['String']['output']>;
};

export type CXRenterAgreement = {
  __typename?: 'CXRenterAgreement';
  certificationOfDisabilitySignedAt?: Maybe<Scalars['DateTime']['output']>;
  checkrMotorVehicleReportRequestIp?: Maybe<Scalars['String']['output']>;
  checkrMotorVehicleReportSignature?: Maybe<Scalars['String']['output']>;
  checkrMotorVehicleReportSignedAt?: Maybe<Scalars['DateTime']['output']>;
  driversLicenseAgreementRequestIp?: Maybe<Scalars['String']['output']>;
  driversLicenseAgreementSignedAt?: Maybe<Scalars['DateTime']['output']>;
  feeAcceptanceRequestIp?: Maybe<Scalars['String']['output']>;
  feeAcceptanceSignedAt?: Maybe<Scalars['DateTime']['output']>;
  privacyPolicyRequestIp?: Maybe<Scalars['String']['output']>;
  privacyPolicySignedAt?: Maybe<Scalars['DateTime']['output']>;
  rentalAgreementRequestIp?: Maybe<Scalars['String']['output']>;
  rentalAgreementSignedAt?: Maybe<Scalars['DateTime']['output']>;
  smsMessageAgreementRequestIp?: Maybe<Scalars['String']['output']>;
  smsMessageAgreementSignedAt?: Maybe<Scalars['DateTime']['output']>;
  termsAndConditionsRequestIp?: Maybe<Scalars['String']['output']>;
  termsAndConditionsSignedAt?: Maybe<Scalars['DateTime']['output']>;
  userEducationAgreementRequestIp?: Maybe<Scalars['String']['output']>;
  userEducationAgreementSignedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CXRenterDiscountRate = {
  __typename?: 'CXRenterDiscountRate';
  created?: Maybe<Scalars['DateTime']['output']>;
  discountRate?: Maybe<DiscountRate>;
  ended?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  product?: Maybe<Scalars['String']['output']>;
  renterId: Scalars['ID']['output'];
  started?: Maybe<Scalars['DateTime']['output']>;
  startedByAgentId?: Maybe<Scalars['ID']['output']>;
  startedByIdentity?: Maybe<Identity>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type CXRenterIneligibility = {
  __typename?: 'CXRenterIneligibility';
  ineligibilityReason: CXIneligibilityReason;
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type CXRenterRentalPass = {
  __typename?: 'CXRenterRentalPass';
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  rentalPass?: Maybe<CXRentalPass>;
};

export type CXRenterStats = {
  __typename?: 'CXRenterStats';
  rentalCount: Scalars['Int']['output'];
  rentalTotalDistance: Scalars['Int']['output'];
  rideHailCount: Scalars['Int']['output'];
  totalDistance: Scalars['Int']['output'];
  totalRides: Scalars['Int']['output'];
};

export type CXRentersEmail = {
  __typename?: 'CXRentersEmail';
  created: Scalars['DateTime']['output'];
  /** Resolver fetched fields */
  cxAgent?: Maybe<Identity>;
  cxAgentId?: Maybe<Scalars['ID']['output']>;
  email: Scalars['String']['output'];
};

export type CXRentersFilters = {
  email?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceFilters?: InputMaybe<InvoiceFilters>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CXRideHail = {
  __typename?: 'CXRideHail';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  acceptedOffers?: Maybe<Array<CXRideHailOffer>>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentStopPoint: CXStopPoint;
  dispatchedRoute?: Maybe<LineString>;
  driver?: Maybe<CXDriver>;
  driverId?: Maybe<Scalars['ID']['output']>;
  driverRideHailId?: Maybe<Scalars['ID']['output']>;
  dropOffAddress: Scalars['String']['output'];
  dropOffLocation: Point;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<CXInvoice>;
  invoiceTotal?: Maybe<Scalars['Int']['output']>;
  isBusinessProfile: Scalars['Boolean']['output'];
  pickUpAddress: Scalars['String']['output'];
  pickUpLocation: Point;
  rating?: Maybe<CXRideHailRating>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  renter?: Maybe<CXRenter>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  rideHailOffer?: Maybe<CXRideHailOffer>;
  rideHailTimeline?: Maybe<Array<Maybe<RideHailTimeline>>>;
  route?: Maybe<LineString>;
  state: CXRideHailStates;
  stopPoints?: Maybe<Array<CXStopPoint>>;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tip?: Maybe<Tip>;
  vehicle?: Maybe<CXVehicle>;
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
  violations: Array<RenterViolation>;
};

export type CXRideHailFilters = {
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  renterId?: InputMaybe<Scalars['ID']['input']>;
  resourceId?: InputMaybe<Scalars['ID']['input']>;
};

export type CXRideHailList = {
  __typename?: 'CXRideHailList';
  count: Scalars['Int']['output'];
  results: Array<CXRideHail>;
};

export type CXRideHailOffer = {
  __typename?: 'CXRideHailOffer';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  amount: Scalars['Int']['output'];
  baseFeeAmount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  designatedDropOffRideLocation?: Maybe<Scalars['String']['output']>;
  designatedPickUpRideLocation?: Maybe<Scalars['String']['output']>;
  /** @deprecated use priceModifications */
  discountRateReduction?: Maybe<Scalars['Int']['output']>;
  driverRideHailId?: Maybe<Scalars['String']['output']>;
  dropOffAddress: Scalars['String']['output'];
  dropOffLocation: Point;
  dropOffZoneId?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  estimatedArrivalTime: Scalars['DateTime']['output'];
  estimatedArrivalTimeRange?: Maybe<Scalars['DateTime']['output']>;
  estimatedDropoffTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedRemainingTripDurationMinutes?: Maybe<Scalars['Int']['output']>;
  estimatedTripDurationMinutes: Scalars['Int']['output'];
  estimatedTripMeters?: Maybe<Scalars['Int']['output']>;
  fees: Array<TerritoryFee>;
  id: Scalars['ID']['output'];
  isWaveOffer?: Maybe<Scalars['Boolean']['output']>;
  minimumBaseFareDifference?: Maybe<Scalars['Int']['output']>;
  offerStops?: Maybe<Array<Maybe<CXRideHailOfferStop>>>;
  originalOffer?: Maybe<Scalars['String']['output']>;
  perMileAmount?: Maybe<Scalars['Int']['output']>;
  perMinuteAmount?: Maybe<Scalars['Int']['output']>;
  pickUpAddress: Scalars['String']['output'];
  pickUpLocation: Point;
  pickUpZoneId?: Maybe<Scalars['String']['output']>;
  previousOffer?: Maybe<Scalars['String']['output']>;
  priceModifications?: Maybe<Array<PriceModification>>;
  priceMultiplier?: Maybe<Scalars['String']['output']>;
  priceMultiplierDifference?: Maybe<Scalars['Int']['output']>;
  pricing: CXRideHailPricing;
  renterDiscount?: Maybe<Scalars['String']['output']>;
  renterId?: Maybe<Scalars['String']['output']>;
  rideHail?: Maybe<Scalars['String']['output']>;
  route: LineString;
  strikePrice?: Maybe<Scalars['Int']['output']>;
  subtotal: Scalars['Int']['output'];
  systemId?: Maybe<Scalars['String']['output']>;
  tax: Scalars['Int']['output'];
  tolls: Array<RideHailOfferTollFee>;
  userLocation?: Maybe<Point>;
  zonePricing?: Maybe<Scalars['String']['output']>;
};

export type CXRideHailOfferStop = {
  __typename?: 'CXRideHailOfferStop';
  address: Scalars['String']['output'];
  addressObject: Address;
  location: Point;
  order: Scalars['Int']['output'];
  requestedArrivalAt?: Maybe<Scalars['DateTime']['output']>;
  stopType: CXStopPointTypes;
};

export type CXRideHailPricing = {
  __typename?: 'CXRideHailPricing';
  baseFeeAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  minimumBaseFare: Scalars['Int']['output'];
  perMileAmount: Scalars['Int']['output'];
  perMinuteAmount: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
  taxPercentage: Scalars['Float']['output'];
};

export type CXRideHailRating = {
  __typename?: 'CXRideHailRating';
  category?: Maybe<CXRideHailRatingCategory>;
  details?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Int']['output'];
  ratingReasons: Array<Scalars['String']['output']>;
};

export enum CXRideHailRatingCategory {
  better_than_alternatives = 'better_than_alternatives',
  clean_and_safe = 'clean_and_safe',
  cleanliness = 'cleanliness',
  conversation = 'conversation',
  driving = 'driving',
  friendly_driver = 'friendly_driver',
  great_driving = 'great_driving',
  navigation = 'navigation',
  other_issue = 'other_issue',
  pickup_dropoff = 'pickup_dropoff',
  price = 'price',
  professionalism = 'professionalism',
  zero_emissions = 'zero_emissions',
}

export enum CXRideHailStates {
  active = 'active',
  arrived = 'arrived',
  canceled = 'canceled',
  completed = 'completed',
  destination_changed = 'destination_changed',
  dispatched = 'dispatched',
  driver_canceled = 'driver_canceled',
  driver_rejected = 'driver_rejected',
  failed = 'failed',
  matching = 'matching',
  offer = 'offer',
  offer_rejected = 'offer_rejected',
  pending = 'pending',
  pick_up_changed = 'pick_up_changed',
  rejected = 'rejected',
  resumed = 'resumed',
  scheduled = 'scheduled',
  stops_changed = 'stops_changed',
  system_rejected = 'system_rejected',
}

export type CXShift = {
  __typename?: 'CXShift';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  driver?: Maybe<CXDriver>;
  id: Scalars['ID']['output'];
  issueType?: Maybe<CXShiftIssueTypes>;
  resourceId?: Maybe<Scalars['String']['output']>;
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  state: CXDriverShiftStates;
  updatedAt: Scalars['DateTime']['output'];
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export enum CXShiftIssueTypes {
  accident = 'accident',
  end_shift_early = 'end_shift_early',
  failed_to_acknowledge_ride = 'failed_to_acknowledge_ride',
  failed_to_start_shift = 'failed_to_start_shift',
  issue_with_vehicle = 'issue_with_vehicle',
  lost_and_found = 'lost_and_found',
  low_battery = 'low_battery',
  needs_cleaning = 'needs_cleaning',
  non_vehicle_issue = 'non_vehicle_issue',
  special_assignment = 'special_assignment',
  unknown = 'unknown',
}

export type CXStopPoint = {
  __typename?: 'CXStopPoint';
  actualAddress?: Maybe<Scalars['String']['output']>;
  actualArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  driverStopPointId: Scalars['ID']['output'];
  estimatedArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedArrivalTimePostMatch?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  location: Point;
  requestedArrivalAt?: Maybe<Scalars['DateTime']['output']>;
  rideHail?: Maybe<Scalars['ID']['output']>;
  state: CXStopPointStates;
  stopType: CXStopPointTypes;
  userLocation?: Maybe<Point>;
  waitTimeEndsAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum CXStopPointStates {
  arrived = 'arrived',
  assigned = 'assigned',
  canceled = 'canceled',
  completed = 'completed',
  failed = 'failed',
  matching = 'matching',
  offer = 'offer',
  offer_rejected = 'offer_rejected',
  pending = 'pending',
  removed = 'removed',
}

export enum CXStopPointTypes {
  dropoff = 'dropoff',
  intermediate = 'intermediate',
  pickup = 'pickup',
}

export type CXVehicle = {
  __typename?: 'CXVehicle';
  driverMetadata?: Maybe<CXVehicleDriverMetadata>;
  id: Scalars['ID']['output'];
  licensePlate: Scalars['String']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  passengerCapacity: Scalars['Int']['output'];
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['String']['output']>;
  totalMileage?: Maybe<Scalars['Float']['output']>;
  wheelchairAccessible: Scalars['Boolean']['output'];
};

export type CXVehicleDriverMetadata = {
  __typename?: 'CXVehicleDriverMetadata';
  dispatchingTlcBase: CXVehicleMetadataTLCBase;
  fhvLicenseNumber: Scalars['String']['output'];
  fleetResourceId: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  tlcBase: CXVehicleMetadataTLCBase;
};

export type CXVehicleMetadataTLCBase = {
  __typename?: 'CXVehicleMetadataTLCBase';
  name: Scalars['String']['output'];
  tlcBaseId: Scalars['String']['output'];
};

export type CXWaitlist = {
  __typename?: 'CXWaitlist';
  createdAt: Scalars['DateTime']['output'];
  dequeuedAt?: Maybe<Scalars['DateTime']['output']>;
  dequeuedReason?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  productId: Scalars['ID']['output'];
  renterId?: Maybe<Scalars['ID']['output']>;
  waitlistProduct: WaitlistProduct;
};

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type CalendlyUrls = {
  __typename?: 'CalendlyUrls';
  onboardingMeeting?: Maybe<Scalars['String']['output']>;
};

export type CancelDriverOpsDriverShiftInput = {
  note: Scalars['String']['input'];
  shiftCancellationReasonId: Scalars['String']['input'];
  shiftId: Scalars['ID']['input'];
};

export type CancelDriverShiftInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  ptoRequested: Scalars['Boolean']['input'];
  shiftId: Scalars['ID']['input'];
  shortNote: Scalars['String']['input'];
};

export type CheckInShiftInput = {
  location: PointInput;
  locationAccuracy: Scalars['Float']['input'];
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type CheckInShiftOverrideInput = {
  shiftId: Scalars['ID']['input'];
};

export type ClaimOpenShiftTemplatesInput = {
  beginGeneratingAt?: InputMaybe<Scalars['DateTime']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  openShiftTemplateIds: Array<Scalars['ID']['input']>;
};

export type ClimateSensors = {
  __typename?: 'ClimateSensors';
  batteryHeater?: Maybe<Scalars['Boolean']['output']>;
  climateKeeperMode?: Maybe<TeslaSensorOnOff>;
  defrostMode?: Maybe<Scalars['Int']['output']>;
  driverTempSetting?: Maybe<Scalars['Float']['output']>;
  fanStatus?: Maybe<Scalars['Int']['output']>;
  insideTemp?: Maybe<Scalars['Float']['output']>;
  isAutoConditioningOn?: Maybe<Scalars['Boolean']['output']>;
  isClimateOn?: Maybe<Scalars['Boolean']['output']>;
  isFrontDefroster_on?: Maybe<Scalars['Boolean']['output']>;
  isPreconditioning?: Maybe<Scalars['Boolean']['output']>;
  isRearDefrosterOn?: Maybe<Scalars['Boolean']['output']>;
  leftTempDirection?: Maybe<Scalars['Int']['output']>;
  maxAvailTemp?: Maybe<Scalars['Float']['output']>;
  minAvailTemp?: Maybe<Scalars['Float']['output']>;
  outsideTemp?: Maybe<Scalars['Float']['output']>;
  passengerTempSetting?: Maybe<Scalars['Float']['output']>;
  remoteHeaterControlEnabled?: Maybe<Scalars['Boolean']['output']>;
  rightTempDirection?: Maybe<Scalars['Int']['output']>;
  seatHeaterLeft?: Maybe<Scalars['Int']['output']>;
  seatHeaterRearCenter?: Maybe<Scalars['Int']['output']>;
  seatHeaterRearLeft?: Maybe<Scalars['Int']['output']>;
  seatHeaterRearRight?: Maybe<Scalars['Int']['output']>;
  seatHeaterRight?: Maybe<Scalars['Int']['output']>;
  sideMirrorHeaters?: Maybe<Scalars['Boolean']['output']>;
  wiperBladeHeater?: Maybe<Scalars['Boolean']['output']>;
};

export type CloseIssueInput = {
  closeReason: Scalars['String']['input'];
  closeReasonDescription?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type CommandResourceInput = {
  command: ResourceCommand;
};

export type CommandResourceResponse = {
  __typename?: 'CommandResourceResponse';
  actionType: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  sensors?: Maybe<Sensors>;
};

export type CommandShiftFleetResourceInput = {
  command: ResourceCommand;
};

export type CommunityReport = {
  __typename?: 'CommunityReport';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifiedRental?: Maybe<CXRental>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  locationAddress: Scalars['String']['output'];
  locationPoint: Point;
  photos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Resolver fetched fields */
  rentalSuggestions: Array<CommunityReportRentalSuggestion>;
  reporterEmail?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Maybe<TagCX>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  violationAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CommunityReportrentalSuggestionsArgs = {
  filters?: InputMaybe<CommunityReportRentalSuggestionFilters>;
};

export type CommunityReportRentalSuggestion = {
  __typename?: 'CommunityReportRentalSuggestion';
  distance: Scalars['Int']['output'];
  location: Point;
  rental: CXRental;
  timestamp: Scalars['DateTime']['output'];
};

export type CommunityReportRentalSuggestionFilters = {
  distanceBuffer?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<PointInput>;
  resultsLimit?: InputMaybe<Scalars['Int']['input']>;
  timeBuffer?: InputMaybe<Scalars['Int']['input']>;
  violationAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  couponDefinition: CouponDefinition;
  couponDefinitionId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  remainingUses?: Maybe<Scalars['Int']['output']>;
  usedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponDefinition = {
  __typename?: 'CouponDefinition';
  id: Scalars['ID']['output'];
  incentiveAmount?: Maybe<Scalars['Int']['output']>;
  incentiveRate?: Maybe<Scalars['Float']['output']>;
  internalName?: Maybe<Scalars['String']['output']>;
  maxIncentiveAmount?: Maybe<Scalars['Int']['output']>;
  minOfferSubtotal?: Maybe<Scalars['Int']['output']>;
  numCouponsIssued: Scalars['Int']['output'];
  product?: Maybe<CouponDefinitionProductsEnum>;
  reusableCoupon?: Maybe<Scalars['Boolean']['output']>;
  rideTypeDisplayKey?: Maybe<Scalars['String']['output']>;
};

export enum CouponDefinitionProductsEnum {
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export type CouponList = {
  __typename?: 'CouponList';
  count: Scalars['Int']['output'];
  results: Array<Coupon>;
};

export type CreateAssignmentInput = {
  assignmentType?: InputMaybe<Scalars['String']['input']>;
  batteryLevelThreshold?: InputMaybe<Scalars['Int']['input']>;
  operatorId?: InputMaybe<Scalars['ID']['input']>;
  startLocation?: InputMaybe<PointInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDriverContractConversionFormInput = {
  convertAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
};

export type CreateDriverShiftInput = {
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId: Scalars['ID']['input'];
  scheduledEndAt: Scalars['String']['input'];
  scheduledStartAt: Scalars['String']['input'];
  settings?: InputMaybe<ShiftSettingsInput>;
  systemId: Scalars['ID']['input'];
};

export type CreateFlagInput = {
  active: Scalars['Boolean']['input'];
  address: Scalars['String']['input'];
  expirationDate: Scalars['DateTime']['input'];
  flagTypeId: Scalars['ID']['input'];
  internalTitle: Scalars['String']['input'];
  location: PointInput;
  startDate: Scalars['DateTime']['input'];
  systemId: Scalars['ID']['input'];
};

export type CreateInvoiceInput = {
  amount: Scalars['Int']['input'];
  applyTax: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  reason?: InputMaybe<CXInvoiceCreatedReason>;
  reasonId?: InputMaybe<Scalars['ID']['input']>;
  renterId: Scalars['ID']['input'];
  renterViolationId?: InputMaybe<Scalars['ID']['input']>;
  systemId: Scalars['ID']['input'];
};

export type CreateLicenseInput = {
  driverId: Scalars['ID']['input'];
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  licenseNumber: Scalars['String']['input'];
  licenseType: LicenseTypes;
  photoBackUri?: InputMaybe<Scalars['String']['input']>;
  photoFrontUri?: InputMaybe<Scalars['String']['input']>;
};

export type CreateManualInvoiceInput = {
  amount: Scalars['Int']['input'];
  displayDetailText?: InputMaybe<Scalars['String']['input']>;
  driverId: Scalars['ID']['input'];
  invoiceItemType: DriverInvoiceItemDescriptor;
};

export type CreateOperatorInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreateRecurringShiftInput = {
  beginGeneratingAt?: InputMaybe<Scalars['DateTime']['input']>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId: Scalars['ID']['input'];
  endTime: Scalars['String']['input'];
  endWeekday: Scalars['Int']['input'];
  settings?: InputMaybe<ShiftSettingsInput>;
  startTime: Scalars['String']['input'];
  startWeekday: Scalars['Int']['input'];
  systemId: Scalars['ID']['input'];
};

export type CreateRepairInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  completedById: Scalars['ID']['input'];
  fleetResourceId: Scalars['ID']['input'];
  repairTypeId: Scalars['ID']['input'];
  reservationId?: InputMaybe<Scalars['ID']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateServiceLogInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTaskInput = {
  description: Scalars['String']['input'];
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  status: Scalars['String']['input'];
  taskTypeId: Scalars['ID']['input'];
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreditInput = {
  context: Scalars['String']['input'];
  issueId?: InputMaybe<Scalars['ID']['input']>;
  reason: Scalars['String']['input'];
  reasonDescription?: InputMaybe<Scalars['String']['input']>;
  renterId: Scalars['ID']['input'];
  value: Scalars['Int']['input'];
};

export type CreditMutationResponse = {
  __typename?: 'CreditMutationResponse';
  applied?: Maybe<Scalars['Int']['output']>;
  creditType?: Maybe<Scalars['String']['output']>;
  renterCreditBalanceId?: Maybe<Scalars['ID']['output']>;
};

export type Depot = {
  __typename?: 'Depot';
  address: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  location: Point;
  name: Scalars['String']['output'];
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  vehicleFacility?: Maybe<VehicleFacility>;
  vehicleFacilityId?: Maybe<Scalars['ID']['output']>;
};

export enum DeviceTypes {
  android = 'android',
  ios = 'ios',
}

export type DiscountRate = {
  __typename?: 'DiscountRate';
  created?: Maybe<Scalars['DateTime']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  product?: Maybe<DiscountRateProductType>;
  rate?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export enum DiscountRateProductType {
  ebike = 'ebike',
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export enum Divisions {
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export type Driver = {
  __typename?: 'Driver';
  addresses?: Maybe<Array<DriverAddress>>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dayforceXrefCode?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  depot?: Maybe<Depot>;
  driverLicenseIssuer?: Maybe<Scalars['String']['output']>;
  driverLicenseNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employmentStatus?: Maybe<DriverEmploymentStatus>;
  employmentType?: Maybe<EmploymentTypes>;
  firstName: Scalars['String']['output'];
  firstShiftCompleted?: Maybe<Scalars['Boolean']['output']>;
  fleetResource?: Maybe<FleetResource>;
  fleetResourceId?: Maybe<Scalars['String']['output']>;
  hasRestrictions: Scalars['Boolean']['output'];
  hcmClient?: Maybe<Scalars['String']['output']>;
  hcmClientId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  licenses?: Maybe<Array<License>>;
  nextShiftFleetType?: Maybe<DriverShiftFleetType>;
  onboardedAt?: Maybe<Scalars['DateTime']['output']>;
  otp?: Maybe<DriverOTP>;
  phoneNumber: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  recurringShifts: Array<RecurringShift>;
  shifts: Array<DriverShift>;
  sizedAvatar?: Maybe<ImgSrc>;
  stats?: Maybe<DriverStats>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  terminationDate?: Maybe<Scalars['DateTime']['output']>;
  timeAway: Array<DriverTimeAway>;
  tlcLicenseExpires?: Maybe<Scalars['Date']['output']>;
  tlcLicenseNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DriveraddressesArgs = {
  filters?: InputMaybe<DriverAddressFilters>;
};

export type DriverhasRestrictionsArgs = {
  platform?: InputMaybe<RestrictionReasonPlatforms>;
};

export type DriverlicensesArgs = {
  filters?: InputMaybe<LicenseFilters>;
};

export type DriverrecurringShiftsArgs = {
  filters?: InputMaybe<ListRecurringShiftsFilters>;
};

export type DrivershiftsArgs = {
  filters?: InputMaybe<DriverShiftFilters>;
};

export type DriversizedAvatarArgs = {
  height: Scalars['Int']['input'];
  scale?: InputMaybe<Scalars['Int']['input']>;
  width: Scalars['Int']['input'];
};

export type DrivertimeAwayArgs = {
  filters?: InputMaybe<DriverTimeAwayFilters>;
};

export type DriverAddress = {
  __typename?: 'DriverAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  addressType: DriverAddressTypes;
  city: Scalars['String']['output'];
  driverId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type DriverAddressFilters = {
  addressType?: InputMaybe<DriverAddressTypes>;
  driverId: Scalars['ID']['input'];
};

export type DriverAddressResponse = {
  __typename?: 'DriverAddressResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverAddress>>;
};

export enum DriverAddressTypes {
  home = 'home',
}

export type DriverAppConfig = {
  __typename?: 'DriverAppConfig';
  balancerMap: BalancerMapConfig;
  calendlyUrls: CalendlyUrls;
  driverEditShiftWindowMinutes: Scalars['Int']['output'];
  driverSupportNumber: Scalars['String']['output'];
  featureFlags: FeatureFlags;
  minimumAppVersions: MinimumAppVersions;
  newHireDepots?: Maybe<Array<HireDepotConfig>>;
  timers: Timers;
};

export type DriverAssetPresignedPost = {
  __typename?: 'DriverAssetPresignedPost';
  fields: Scalars['JSON']['output'];
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DriverContractConversionForm = {
  __typename?: 'DriverContractConversionForm';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  convertAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DriverCredit = {
  __typename?: 'DriverCredit';
  additionalDetails?: Maybe<Scalars['String']['output']>;
  amountAvailable: Scalars['Int']['output'];
  amountIssued: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  driverId: Scalars['ID']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reasonGranted: DriverCreditGrantingReasons;
};

export type DriverCreditAmountResponse = {
  __typename?: 'DriverCreditAmountResponse';
  creditAmount: Scalars['Int']['output'];
};

export enum DriverCreditGrantingReasons {
  ended_reservation_early = 'ended_reservation_early',
  forgot_to_end_reservation = 'forgot_to_end_reservation',
  mechanical_issue = 'mechanical_issue',
  other = 'other',
  software_issue = 'software_issue',
  tolls = 'tolls',
  vehicle_lacked_charge = 'vehicle_lacked_charge',
  vehicle_not_available = 'vehicle_not_available',
  vehicle_required_cleaning = 'vehicle_required_cleaning',
}

export enum DriverEmploymentStatus {
  ACTIVE = 'ACTIVE',
  FURLOUGHED = 'FURLOUGHED',
  INACTIVE = 'INACTIVE',
  LOA = 'LOA',
  PRESTART = 'PRESTART',
  REVOKED = 'REVOKED',
  TERMINATED = 'TERMINATED',
}

export enum DriverFilteredStopPointTypes {
  dropoff = 'dropoff',
  generic = 'generic',
  intermediate = 'intermediate',
  issue = 'issue',
  none = 'none',
  pickup = 'pickup',
  rebalance = 'rebalance',
  recall = 'recall',
  scheduled_break = 'scheduled_break',
  shift_end = 'shift_end',
  unscheduled_break = 'unscheduled_break',
  vehicle_check = 'vehicle_check',
}

export type DriverFilters = {
  driverEmploymentStatus__in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  employmentType?: InputMaybe<EmploymentTypes>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  ordering?: InputMaybe<Array<DriverOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DriverFleetResource = {
  __typename?: 'DriverFleetResource';
  activeShift?: Maybe<DriverShift>;
  assignedDriver?: Maybe<Driver>;
  batteryLevel?: Maybe<Scalars['Float']['output']>;
  color: Scalars['String']['output'];
  detail: DriverFleetResourceDetail;
  fleetResourceMetadata?: Maybe<FleetResourceMetadata>;
  id: Scalars['ID']['output'];
  licensePlate: Scalars['String']['output'];
  location?: Maybe<Point>;
  qrCode?: Maybe<Scalars['String']['output']>;
  speedLimitPin?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  systemId: Scalars['ID']['output'];
  telematicsId: Scalars['String']['output'];
  telematicsProvider: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  vin: Scalars['String']['output'];
};

export enum DriverFleetResourceAssignmentValues {
  all = 'all',
  assigned = 'assigned',
  driver = 'driver',
  shift = 'shift',
  unassigned = 'unassigned',
}

export type DriverFleetResourceCountFilters = {
  assignment?: InputMaybe<DriverFleetResourceAssignmentValues>;
  excludeModels?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeResourceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeResourceStatuses?: InputMaybe<Array<FleetResourceStatusValues>>;
  insuranceProviderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  makes?: InputMaybe<Array<Scalars['String']['input']>>;
  maxBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  minBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<Array<Scalars['String']['input']>>;
  notAtFacility?: InputMaybe<Scalars['Boolean']['input']>;
  resourceStatuses?: InputMaybe<Array<FleetResourceStatusValues>>;
  search?: InputMaybe<Scalars['String']['input']>;
  vehicleFacilityId?: InputMaybe<Scalars['ID']['input']>;
};

export type DriverFleetResourceDetail = {
  __typename?: 'DriverFleetResourceDetail';
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  passengerCapacity?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type DriverFleetResourceDetailFilters = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type DriverFleetResourceDetailsResponse = {
  __typename?: 'DriverFleetResourceDetailsResponse';
  count: Scalars['Int']['output'];
  results: Array<Maybe<DriverFleetResourceDetail>>;
};

export type DriverFleetResourceFilters = {
  assignment: DriverFleetResourceAssignmentValues;
  excludeModels?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeResourceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeResourceStatuses?: InputMaybe<Array<FleetResourceStatusValues>>;
  insuranceProviderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  makes?: InputMaybe<Array<Scalars['String']['input']>>;
  maxBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  minBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<Array<Scalars['String']['input']>>;
  notAtFacility?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<Array<DriverFleetResourceOrderingValues>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  resourceStatuses?: InputMaybe<Array<FleetResourceStatusValues>>;
  search?: InputMaybe<Scalars['String']['input']>;
  vehicleFacilityId?: InputMaybe<Scalars['ID']['input']>;
  vehicleTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum DriverFleetResourceOrderingValues {
  batteryLevelAscending = 'batteryLevelAscending',
  batteryLevelDescending = 'batteryLevelDescending',
  updatedAscending = 'updatedAscending',
  updatedDescending = 'updatedDescending',
}

export type DriverFleetResourcesResponse = {
  __typename?: 'DriverFleetResourcesResponse';
  count: Scalars['Int']['output'];
  results: Array<Maybe<DriverFleetResource>>;
};

export type DriverInsuranceApproval = {
  __typename?: 'DriverInsuranceApproval';
  id: Scalars['ID']['output'];
  insuranceProvider: InsuranceProvider;
};

export type DriverInsuranceApprovalFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
};

export type DriverInsuranceApprovalResponse = {
  __typename?: 'DriverInsuranceApprovalResponse';
  count: Scalars['Int']['output'];
  results: Array<Maybe<DriverInsuranceApproval>>;
};

export type DriverInvoice = {
  __typename?: 'DriverInvoice';
  appliedCredit?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByIdentityId?: Maybe<Scalars['ID']['output']>;
  descriptor?: Maybe<DriverInvoiceDescriptors>;
  driver?: Maybe<Driver>;
  driverId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoiceItems?: Maybe<Array<DriverInvoiceItem>>;
  paymentIntents?: Maybe<Array<DriverPaymentIntent>>;
  status: DriverInvoiceStatuses;
  subtotal: Scalars['Int']['output'];
  tax: Scalars['Int']['output'];
  taxPercentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
};

export enum DriverInvoiceDescriptors {
  charging = 'charging',
  external_charging_session = 'external_charging_session',
  late_weekly_rental_return_fee = 'late_weekly_rental_return_fee',
  toll = 'toll',
  vehicle_damage = 'vehicle_damage',
  vehicle_rental = 'vehicle_rental',
  vehicle_rental_weekly = 'vehicle_rental_weekly',
  vehicle_security_deposit = 'vehicle_security_deposit',
  vehicle_traffic_violation = 'vehicle_traffic_violation',
}

export type DriverInvoiceFilters = {
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  descriptors?: InputMaybe<Array<InputMaybe<DriverInvoiceDescriptors>>>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  reservationId?: InputMaybe<Scalars['ID']['input']>;
  statuses?: InputMaybe<Array<DriverInvoiceStatuses>>;
};

export type DriverInvoiceItem = {
  __typename?: 'DriverInvoiceItem';
  amount?: Maybe<Scalars['Int']['output']>;
  descriptor?: Maybe<DriverInvoiceItemDescriptor>;
  displayDetailText?: Maybe<Scalars['String']['output']>;
  displayTitleText?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  itemType?: Maybe<DriverInvoiceItemDescriptor>;
  order?: Maybe<Scalars['Int']['output']>;
};

export enum DriverInvoiceItemDescriptor {
  charging_fee = 'charging_fee',
  credit = 'credit',
  external_charging_session = 'external_charging_session',
  ez_pass_rental = 'ez_pass_rental',
  failed_invoice_fee = 'failed_invoice_fee',
  insurance_premium = 'insurance_premium',
  late_vehicle_return = 'late_vehicle_return',
  late_weekly_rental_return_fee = 'late_weekly_rental_return_fee',
  security_deposit = 'security_deposit',
  tax = 'tax',
  ticket = 'ticket',
  ticket_processing_fee = 'ticket_processing_fee',
  toll = 'toll',
  vehicle_damage = 'vehicle_damage',
  vehicle_rental = 'vehicle_rental',
}

export enum DriverInvoiceStatuses {
  canceled = 'canceled',
  failed = 'failed',
  paid = 'paid',
  pending = 'pending',
  ready_for_payment = 'ready_for_payment',
  refund_failed = 'refund_failed',
  refunded = 'refunded',
  requires_manual_intervention = 'requires_manual_intervention',
  sms_warning_sent = 'sms_warning_sent',
  voided = 'voided',
}

export type DriverInvoicesResponse = {
  __typename?: 'DriverInvoicesResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverInvoice>>;
};

export type DriverOTP = {
  __typename?: 'DriverOTP';
  expiresAt: Scalars['DateTime']['output'];
  verificationCode?: Maybe<Scalars['String']['output']>;
};

export type DriverOpsDriverAndDetails = {
  __typename?: 'DriverOpsDriverAndDetails';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dayforceXrefCode?: Maybe<Scalars['String']['output']>;
  employmentStatus?: Maybe<DriverEmploymentStatus>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  terminationDate?: Maybe<Scalars['DateTime']['output']>;
  tlcLicenseNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DriverOpsDriverListResponse = {
  __typename?: 'DriverOpsDriverListResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverOpsDriverAndDetails>>;
};

export enum DriverOpsDriverOrderingField {
  createdAtAscending = 'createdAtAscending',
  createdAtDescending = 'createdAtDescending',
  updatedAtAscending = 'updatedAtAscending',
  updatedAtDescending = 'updatedAtDescending',
}

export type DriverOpsDriverResponse = {
  __typename?: 'DriverOpsDriverResponse';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dayforceXrefCode?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  employmentStatus?: Maybe<DriverEmploymentStatus>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['DateTime']['output']>;
  tlcLicenseNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DriverOpsDriverShift = {
  __typename?: 'DriverOpsDriverShift';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  breaks?: Maybe<Array<ShiftBreak>>;
  cancelationReason?: Maybe<Scalars['String']['output']>;
  driver: DriverOpsDriverAndDetails;
  id: Scalars['ID']['output'];
  issueType?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledRecallAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  state: DriverOpsShiftStates;
  vehicleLocation?: Maybe<Point>;
};

export type DriverOpsDriverShiftsResponse = {
  __typename?: 'DriverOpsDriverShiftsResponse';
  count: Scalars['Int']['output'];
  results: Array<DriverOpsDriverShift>;
};

export type DriverOpsDriverTimeAway = {
  __typename?: 'DriverOpsDriverTimeAway';
  createdAt: Scalars['DateTime']['output'];
  employeeMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  managerMessage?: Maybe<Scalars['String']['output']>;
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  status: DriverOpsDriverTimeAwayStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type DriverOpsDriverTimeAwayFilters = {
  scheduledEndAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledEndAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  statusIn?: InputMaybe<Array<DriverOpsDriverTimeAwayStatus>>;
};

export type DriverOpsDriverTimeAwayResponse = {
  __typename?: 'DriverOpsDriverTimeAwayResponse';
  count: Scalars['Int']['output'];
  results: Array<DriverOpsDriverTimeAway>;
};

export enum DriverOpsDriverTimeAwayStatus {
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  CANCELPENDING = 'CANCELPENDING',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
}

export type DriverOpsDriversFilters = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  ordering?: InputMaybe<Array<DriverOpsDriverOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum DriverOpsEvaluatedState {
  active_shifts = 'active_shifts',
  inactive_shifts = 'inactive_shifts',
  shifts_eligible_to_start = 'shifts_eligible_to_start',
  shifts_with_vehicle = 'shifts_with_vehicle',
}

export type DriverOpsShift = {
  __typename?: 'DriverOpsShift';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  driver: DriverOpsDriverAndDetails;
  id: Scalars['ID']['output'];
  issueType?: Maybe<Scalars['String']['output']>;
  liveRouteUrl?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledRecallAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  state: DriverOpsShiftStates;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type DriverOpsShiftFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  evaluatedState?: InputMaybe<DriverOpsEvaluatedState>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  ordering?: InputMaybe<Array<DriverOpsShiftOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state__in?: InputMaybe<Array<DriverOpsShiftStates>>;
  state__nin?: InputMaybe<Array<DriverOpsShiftStates>>;
};

export enum DriverOpsShiftOrderingField {
  scheduledRecallAtAscending = 'scheduledRecallAtAscending',
  scheduledRecallAtDescending = 'scheduledRecallAtDescending',
  scheduledStartAtAscending = 'scheduledStartAtAscending',
  scheduledStartAtDescending = 'scheduledStartAtDescending',
}

export enum DriverOpsShiftStates {
  activating = 'activating',
  active = 'active',
  canceled = 'canceled',
  complete = 'complete',
  driver_on_the_way = 'driver_on_the_way',
  no_show = 'no_show',
  scheduled = 'scheduled',
  scheduled_break = 'scheduled_break',
  stand_by = 'stand_by',
  unscheduled_break = 'unscheduled_break',
}

export enum DriverOrderingField {
  createdAtAscending = 'createdAtAscending',
  createdAtDescending = 'createdAtDescending',
  updatedAtAscending = 'updatedAtAscending',
  updatedAtDescending = 'updatedAtDescending',
}

export type DriverPayment = {
  __typename?: 'DriverPayment';
  baseFeeAmount?: Maybe<Scalars['Int']['output']>;
  consumerTotalAmount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  driverId: Scalars['ID']['output'];
  driverTakeRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  offerEstimatedDurationSeconds?: Maybe<Scalars['Int']['output']>;
  offerEstimatedTripMeters?: Maybe<Scalars['Int']['output']>;
  offerPricingPerMileAmount?: Maybe<Scalars['Int']['output']>;
  offerPricingPerMinuteAmount?: Maybe<Scalars['Int']['output']>;
  paymentItems?: Maybe<Array<DriverPaymentItem>>;
  paymentType: DriverPaymentTypes;
  perMileAmount?: Maybe<Scalars['Int']['output']>;
  perMinuteAmount?: Maybe<Scalars['Int']['output']>;
  referenceExternalId: Scalars['ID']['output'];
  shiftId: Scalars['ID']['output'];
  status: DriverPaymentStatuses;
  totalAmount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DriverPaymentFilters = {
  referenceExternalId?: InputMaybe<Scalars['ID']['input']>;
};

export type DriverPaymentIntent = {
  __typename?: 'DriverPaymentIntent';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  declineReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paymentMethod?: Maybe<DriverPaymentMethod>;
  paymentMethodInfo?: Maybe<Scalars['JSON']['output']>;
  status?: Maybe<DriverPaymentIntentStatuses>;
  stripeExternalId?: Maybe<Scalars['String']['output']>;
};

export enum DriverPaymentIntentStatuses {
  canceled = 'canceled',
  created = 'created',
  processing = 'processing',
  requires_action = 'requires_action',
  requires_capture = 'requires_capture',
  requires_confirmation = 'requires_confirmation',
  requires_payment_method = 'requires_payment_method',
  succeeded = 'succeeded',
}

export type DriverPaymentItem = {
  __typename?: 'DriverPaymentItem';
  amount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  itemType?: Maybe<DriverPaymentItemType>;
};

export enum DriverPaymentItemType {
  base_fare = 'base_fare',
  cancellation_fee = 'cancellation_fee',
  dwell_time_fee = 'dwell_time_fee',
  no_show_fee = 'no_show_fee',
  territory_fee = 'territory_fee',
  tip = 'tip',
  toll_fee = 'toll_fee',
}

export type DriverPaymentMethod = {
  __typename?: 'DriverPaymentMethod';
  brand?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isSelected?: Maybe<Scalars['Boolean']['output']>;
  lastFour?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DriverPaymentMethodTypes>;
};

export type DriverPaymentMethodFilters = {
  driverId: Scalars['ID']['input'];
  isSelected?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum DriverPaymentMethodTypes {
  card = 'card',
  link = 'link',
}

export enum DriverPaymentStatuses {
  revel_manual_payout = 'revel_manual_payout',
}

export enum DriverPaymentTypes {
  ride_hail = 'ride_hail',
  ride_hail_tip = 'ride_hail_tip',
}

export type DriverPhoneMaskingSession = {
  __typename?: 'DriverPhoneMaskingSession';
  maskedConsumerPhoneNumber: Scalars['String']['output'];
};

export type DriverPunchAuthURL = {
  __typename?: 'DriverPunchAuthURL';
  url: Scalars['String']['output'];
};

export type DriverRenter = {
  __typename?: 'DriverRenter';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  profilePhoto?: Maybe<Scalars['String']['output']>;
};

export type DriverRequestPhoneLoginResponse = {
  __typename?: 'DriverRequestPhoneLoginResponse';
  phoneLastFour: Scalars['String']['output'];
  twoFactorToken: Scalars['String']['output'];
};

export type DriverRestrictionItem = {
  __typename?: 'DriverRestrictionItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: RestrictionReasonNameKeys;
  label: Scalars['String']['output'];
  link?: Maybe<DriverRestrictionItemLink>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type DriverRestrictionItemLink = {
  __typename?: 'DriverRestrictionItemLink';
  href: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type DriverRestrictionReason = {
  __typename?: 'DriverRestrictionReason';
  driverId: Scalars['ID']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  restrictionReason: RestrictionReason;
  startedAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DriverRestrictionReasonFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  endedAt__isnull?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  platform?: InputMaybe<RestrictionReasonPlatforms>;
  reasons?: InputMaybe<Array<RestrictionReasonNameKeys>>;
};

export type DriverRestrictionReasonResponse = {
  __typename?: 'DriverRestrictionReasonResponse';
  count: Scalars['Int']['output'];
  results: Array<DriverRestrictionReason>;
};

export type DriverRestrictionStatusContentFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  platform?: InputMaybe<RestrictionReasonPlatforms>;
  reason?: InputMaybe<RestrictionReasonNameKeys>;
};

export type DriverRestrictionStatusGroup = {
  __typename?: 'DriverRestrictionStatusGroup';
  description?: Maybe<Scalars['String']['output']>;
  extendedDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<DriverRestrictionItem>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DriverRestrictionsStatusContent = {
  __typename?: 'DriverRestrictionsStatusContent';
  groups: Array<DriverRestrictionStatusGroup>;
};

export type DriverRideHail = {
  __typename?: 'DriverRideHail';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  consumerMetadata?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentStopPoint: DriverStopPoint;
  dispatchedRoute?: Maybe<LineString>;
  driverId?: Maybe<Scalars['ID']['output']>;
  dropOffAddress: Scalars['String']['output'];
  dropOffLocation: Point;
  estimatedTripMeters?: Maybe<Scalars['Int']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  fleetResourceMetadata?: Maybe<FleetResourceMetadata>;
  id: Scalars['ID']['output'];
  invoiceTotal?: Maybe<Scalars['Int']['output']>;
  payments?: Maybe<Array<DriverPayment>>;
  pickUpAddress: Scalars['String']['output'];
  pickUpLocation: Point;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  renter?: Maybe<CXRenter>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  route?: Maybe<LineString>;
  shift?: Maybe<DriverShift>;
  state: DriverRideHailStates;
  stopPoints?: Maybe<Array<DriverStopPoint>>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tip?: Maybe<Tip>;
  tripDurationSeconds?: Maybe<Scalars['Int']['output']>;
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type DriverRideHailFilters = {
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  finalizedAtEnd?: InputMaybe<Scalars['String']['input']>;
  finalizedAtStart?: InputMaybe<Scalars['String']['input']>;
  hasPayment?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<Array<InputMaybe<DriverRideHailOrderingField>>>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  states?: InputMaybe<Array<DriverRideHailStates>>;
};

export enum DriverRideHailOrderingField {
  finalizedAtAscending = 'finalizedAtAscending',
  finalizedAtDescending = 'finalizedAtDescending',
}

export enum DriverRideHailStates {
  active = 'active',
  arrived = 'arrived',
  canceled = 'canceled',
  completed = 'completed',
  destination_changed = 'destination_changed',
  dispatched = 'dispatched',
  failed = 'failed',
  matching = 'matching',
  offer = 'offer',
  offer_rejected = 'offer_rejected',
  pending = 'pending',
  pick_up_changed = 'pick_up_changed',
  rejected = 'rejected',
  resumed = 'resumed',
  scheduled = 'scheduled',
  stops_changed = 'stops_changed',
}

export type DriverSetupIntent = {
  __typename?: 'DriverSetupIntent';
  clientSecret?: Maybe<Scalars['String']['output']>;
  stripeExternalId?: Maybe<Scalars['String']['output']>;
};

export type DriverShift = {
  __typename?: 'DriverShift';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  breaks?: Maybe<Array<DriverShiftBreak>>;
  clockIn?: Maybe<Scalars['DateTime']['output']>;
  clockOut?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentIssue?: Maybe<ShiftIssue>;
  currentIssueId?: Maybe<Scalars['ID']['output']>;
  currentStopPoint?: Maybe<DriverStopPoint>;
  depot?: Maybe<Depot>;
  depotId?: Maybe<Scalars['ID']['output']>;
  driver: Driver;
  driverArrivedForShiftAt?: Maybe<Scalars['DateTime']['output']>;
  estimatedPay?: Maybe<Scalars['Int']['output']>;
  fleetResourceMetadata?: Maybe<FleetResourceMetadata>;
  hasNote: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  issueType?: Maybe<DriverShiftIssueTypes>;
  liveRouteUrl?: Maybe<Scalars['String']['output']>;
  logisticsManager?: Maybe<LogisticsManagers>;
  note?: Maybe<ShiftNote>;
  remainingUnscheduledBreaks?: Maybe<RemainingUnscheduledBreaks>;
  reservationContract?: Maybe<ReservationContract>;
  reservationId?: Maybe<Scalars['ID']['output']>;
  reservationType?: Maybe<ReservationTypes>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledRecallAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt: Scalars['DateTime']['output'];
  settings?: Maybe<ShiftSettings>;
  shiftCancellation?: Maybe<ShiftCancellation>;
  state: DriverShiftStates;
  stats?: Maybe<ShiftStats>;
  systemId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
  vehicleLocationUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DriverShiftBreak = {
  __typename?: 'DriverShiftBreak';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<DriverShiftBreakTypes>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum DriverShiftBreakTypes {
  scheduled = 'scheduled',
  unscheduled = 'unscheduled',
}

export enum DriverShiftCancelationReasons {
  call_out_within_two_hours = 'call_out_within_two_hours',
  call_out_within_two_hours_pto = 'call_out_within_two_hours_pto',
  canceled_shift_paid = 'canceled_shift_paid',
  driver_ops_override = 'driver_ops_override',
  driver_revoked = 'driver_revoked',
  driver_sick = 'driver_sick',
  driver_termination = 'driver_termination',
  inactive_driver = 'inactive_driver',
  leave_of_absence = 'leave_of_absence',
  low_demand_rebalance = 'low_demand_rebalance',
  no_call_no_show = 'no_call_no_show',
  other = 'other',
  pto = 'pto',
  recurring_shift_update = 'recurring_shift_update',
  same_day_call_out = 'same_day_call_out',
  tardy = 'tardy',
  unpaid_time = 'unpaid_time',
  vehicle_issue = 'vehicle_issue',
  weather = 'weather',
}

export type DriverShiftDevice = {
  __typename?: 'DriverShiftDevice';
  appVersion?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type DriverShiftFilters = {
  currentStopPointType__in?: InputMaybe<Array<DriverFilteredStopPointTypes>>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  depotId__in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  evaluatedState?: InputMaybe<EvaluatedState>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  hasNote?: InputMaybe<Scalars['Boolean']['input']>;
  hasVehicle?: InputMaybe<Scalars['Boolean']['input']>;
  isInIssue?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<Array<DriverShiftOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  reservationType?: InputMaybe<ReservationTypes>;
  scheduledEndAtEnd?: InputMaybe<Scalars['String']['input']>;
  scheduledEndAtStart?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  state__in?: InputMaybe<Array<DriverShiftStates>>;
  state__nin?: InputMaybe<Array<DriverShiftStates>>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export enum DriverShiftFleetType {
  centralized_fleet = 'centralized_fleet',
  distributed_fleet = 'distributed_fleet',
  none = 'none',
}

export enum DriverShiftIssueTypes {
  accident = 'accident',
  end_shift_early = 'end_shift_early',
  failed_to_acknowledge_ride = 'failed_to_acknowledge_ride',
  failed_to_start_shift = 'failed_to_start_shift',
  issue_with_vehicle = 'issue_with_vehicle',
  lost_and_found = 'lost_and_found',
  low_battery = 'low_battery',
  needs_cleaning = 'needs_cleaning',
  non_vehicle_issue = 'non_vehicle_issue',
  special_assignment = 'special_assignment',
  stale_location = 'stale_location',
  unknown = 'unknown',
}

export enum DriverShiftOrderingField {
  clockInAscending = 'clockInAscending',
  clockInDescending = 'clockInDescending',
  clockOutAscending = 'clockOutAscending',
  clockOutDescending = 'clockOutDescending',
  idAscending = 'idAscending',
  idDescending = 'idDescending',
  inIssueFirst = 'inIssueFirst',
  inIssueLast = 'inIssueLast',
  scheduledEndAtAscending = 'scheduledEndAtAscending',
  scheduledEndAtDescending = 'scheduledEndAtDescending',
  scheduledRecallAtAscending = 'scheduledRecallAtAscending',
  scheduledRecallAtDescending = 'scheduledRecallAtDescending',
  scheduledStartAtAscending = 'scheduledStartAtAscending',
  scheduledStartAtDescending = 'scheduledStartAtDescending',
  stateAscending = 'stateAscending',
  stateDescending = 'stateDescending',
}

export enum DriverShiftStates {
  activating = 'activating',
  active = 'active',
  canceled = 'canceled',
  complete = 'complete',
  driver_on_the_way = 'driver_on_the_way',
  no_show = 'no_show',
  scheduled = 'scheduled',
  scheduled_break = 'scheduled_break',
  stand_by = 'stand_by',
  unscheduled_break = 'unscheduled_break',
}

export type DriverShiftsResponse = {
  __typename?: 'DriverShiftsResponse';
  count: Scalars['Int']['output'];
  results: Array<DriverShift>;
};

export type DriverSignUpInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneVerificationKey: Scalars['String']['input'];
};

export type DriverSignUpResponse = {
  __typename?: 'DriverSignUpResponse';
  driver: Driver;
  identity: Identity;
  token: Token;
};

export type DriverStartShiftInput = {
  accuracy?: InputMaybe<Scalars['Float']['input']>;
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  deviceName?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<DeviceTypes>;
  expoPushToken?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<PointInput>;
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  pushTokenType?: InputMaybe<PushTokenTypes>;
  shiftId: Scalars['ID']['input'];
};

export type DriverStats = {
  __typename?: 'DriverStats';
  averageNumberOfSecondsToAckRides?: Maybe<Scalars['Int']['output']>;
  criticalAlertsPer100Miles?: Maybe<Scalars['Int']['output']>;
  overallDriverRating?: Maybe<Scalars['Float']['output']>;
  percentageOfAvailability?: Maybe<Scalars['Float']['output']>;
  percentageOfRidesUnder3Stars?: Maybe<Scalars['Float']['output']>;
  performanceStatus?: Maybe<Scalars['Float']['output']>;
  performanceStatusThresholds?: Maybe<Scalars['JSON']['output']>;
  totalNumberOfCompletedRides?: Maybe<Scalars['Int']['output']>;
};

export type DriverStopPoint = {
  __typename?: 'DriverStopPoint';
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use arrivedAt */
  actualArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  address: Scalars['String']['output'];
  arrivedAt?: Maybe<Scalars['DateTime']['output']>;
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  consumerRideHailId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentRoute?: Maybe<LineString>;
  currentRouteGeojsonString?: Maybe<Scalars['String']['output']>;
  driverRenter?: Maybe<DriverRenter>;
  estimatedArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedArrivalTimeAtOffer?: Maybe<Scalars['DateTime']['output']>;
  estimatedCompletionTime?: Maybe<Scalars['DateTime']['output']>;
  estimatedCompletionTimeAtOffer?: Maybe<Scalars['DateTime']['output']>;
  estimatedTripMeters?: Maybe<Scalars['Int']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  finalizedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use parentId */
  internalParentId?: Maybe<Scalars['ID']['output']>;
  isCurrent?: Maybe<Scalars['Boolean']['output']>;
  location: Point;
  metadata?: Maybe<StoppointMeta>;
  order?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  priority: Scalars['Int']['output'];
  /** @deprecated Use driverRenter */
  renter?: Maybe<Identity>;
  renterId?: Maybe<Scalars['ID']['output']>;
  requestedArrivalAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  state: DriverStopPointStates;
  stopType: DriverStopPointTypes;
  systemId?: Maybe<Scalars['ID']['output']>;
};

export enum DriverStopPointStates {
  arrived = 'arrived',
  assigned = 'assigned',
  canceled = 'canceled',
  completed = 'completed',
  failed = 'failed',
  matching = 'matching',
  offer = 'offer',
  offer_rejected = 'offer_rejected',
  pending = 'pending',
  removed = 'removed',
}

export enum DriverStopPointTypes {
  dropoff = 'dropoff',
  generic = 'generic',
  intermediate = 'intermediate',
  issue = 'issue',
  pickup = 'pickup',
  rebalance = 'rebalance',
  recall = 'recall',
  scheduled_break = 'scheduled_break',
  shift_end = 'shift_end',
  unscheduled_break = 'unscheduled_break',
  vehicle_check = 'vehicle_check',
}

export type DriverStopPointsResponse = {
  __typename?: 'DriverStopPointsResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<Maybe<DriverStopPoint>>>;
};

export type DriverSubmitPhoneLoginResponse = {
  __typename?: 'DriverSubmitPhoneLoginResponse';
  driver: Driver;
  identity: Identity;
  token: Token;
};

export type DriverSubmitTwoFactorLoginInput = {
  twoFactorToken: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type DriverSystemZone = {
  __typename?: 'DriverSystemZone';
  areaGeojsonString: Scalars['String']['output'];
  areaInvertedGeojsonString?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  markerLocation?: Maybe<Point>;
  nameKey?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  systemId: Scalars['ID']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
  zoneType: Scalars['String']['output'];
};

export type DriverTimeAway = {
  __typename?: 'DriverTimeAway';
  employeeMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  managerMessage?: Maybe<Scalars['String']['output']>;
  netHours: Scalars['String']['output'];
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  status: DriverTimeAwayStatus;
};

export type DriverTimeAwayFilters = {
  scheduledEndAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledEndAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusIn?: InputMaybe<Array<DriverTimeAwayStatus>>;
};

export enum DriverTimeAwayStatus {
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  CANCELPENDING = 'CANCELPENDING',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
}

export enum DriverUpdateEmploymentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REVOKED = 'REVOKED',
}

export type DriverVehicleExclusionMakeAndModel = {
  __typename?: 'DriverVehicleExclusionMakeAndModel';
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
};

export type DriverVehiclePreferenceResponse = {
  __typename?: 'DriverVehiclePreferenceResponse';
  preferredVehicleType: DriverVehiclePreferenceType;
};

export enum DriverVehiclePreferenceType {
  premium = 'premium',
  standard = 'standard',
}

export type DriversResponse = {
  __typename?: 'DriversResponse';
  count: Scalars['Int']['output'];
  results: Array<Driver>;
};

export enum EmploymentTypes {
  W2 = 'W2',
  contractor = 'contractor',
}

export type EmptyResponse = {
  __typename?: 'EmptyResponse';
  id?: Maybe<Scalars['ID']['output']>;
};

export enum EvaluatedState {
  active_shifts = 'active_shifts',
  inactive_shifts = 'inactive_shifts',
  shifts_eligible_to_start = 'shifts_eligible_to_start',
  shifts_with_vehicle = 'shifts_with_vehicle',
}

export enum ExpoPlatforms {
  android = 'android',
  ios = 'ios',
  macos = 'macos',
  web = 'web',
  windows = 'windows',
}

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  /** @deprecated Never used. Remove soon™️ */
  isCarControlsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isDeferredBreakEnabled?: Maybe<Scalars['Boolean']['output']>;
  isDevAllowed: Scalars['Boolean']['output'];
  isDriverInvoicesEnabled?: Maybe<Scalars['Boolean']['output']>;
  isDriverPaymentMethodsEnabled?: Maybe<Scalars['Boolean']['output']>;
  isDriverSignUpEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isPerformanceDriverStandingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isRentalAppEnabled?: Maybe<Scalars['Boolean']['output']>;
  isReservationAgreementEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isScheduledBreakExtendedActionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isShiftActivityPremiumEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isUnscheduledBreakCapEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isUnscheduledBreakUrgentEnabled?: Maybe<Scalars['Boolean']['output']>;
  isVariableLeaseRatesEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Never used. Remove soon™️ */
  isWeeklyScheduleEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type Flag = {
  __typename?: 'Flag';
  active: Scalars['Boolean']['output'];
  address: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  descriptionDisplayKey?: Maybe<Scalars['String']['output']>;
  expirationDate: Scalars['DateTime']['output'];
  flagStatus: FlagStatus;
  flagType: FlagType;
  id: Scalars['ID']['output'];
  internalTitle: Scalars['String']['output'];
  location: Point;
  startDate: Scalars['DateTime']['output'];
  system: System;
  titleDisplayKey?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
};

export type FlagFilters = {
  excludeExpired?: InputMaybe<Scalars['Boolean']['input']>;
  flagTypeId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
  systemName?: InputMaybe<Scalars['String']['input']>;
};

export enum FlagStatus {
  ENDED = 'ENDED',
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING',
}

export type FlagType = {
  __typename?: 'FlagType';
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type FleetResource = {
  __typename?: 'FleetResource';
  batteryLevel: Scalars['Float']['output'];
  created: Scalars['DateTime']['output'];
  detail?: Maybe<FleetResourceDetail>;
  division?: Maybe<Divisions>;
  estimateMileage?: Maybe<Scalars['Int']['output']>;
  googleDirections?: Maybe<GoogleDirections>;
  hasBlockingTask?: Maybe<Scalars['Boolean']['output']>;
  hubId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isReservable?: Maybe<Scalars['Boolean']['output']>;
  lastServicedAt?: Maybe<Scalars['DateTime']['output']>;
  latestServiceLog?: Maybe<ServiceLog>;
  licensePlate: Scalars['String']['output'];
  location: Point;
  locationGroup?: Maybe<LocationGroup>;
  matchesFilters?: Maybe<Scalars['Boolean']['output']>;
  model: Scalars['String']['output'];
  passengerAccount?: Maybe<PassengerAppAccount>;
  range?: Maybe<Scalars['Int']['output']>;
  reservationStatus?: Maybe<FleetResourceReservationStatus>;
  sensors?: Maybe<Sensors>;
  session?: Maybe<FleetResourceSession>;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  tasks?: Maybe<Array<Task>>;
  telematicsId: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  vin: Scalars['String']['output'];
  workOrder?: Maybe<WorkOrder>;
  workOrderId?: Maybe<Scalars['ID']['output']>;
  workOrderType?: Maybe<WorkOrderType>;
};

export type FleetResourcetasksArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type FleetResourceDetail = {
  __typename?: 'FleetResourceDetail';
  id: Scalars['ID']['output'];
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  passengerCapacity?: Maybe<Scalars['Int']['output']>;
  wheelchair_accessible?: Maybe<Scalars['Boolean']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type FleetResourceEvent = {
  __typename?: 'FleetResourceEvent';
  eventType?: Maybe<FleetResourceEventType>;
  resource?: Maybe<FleetResource>;
};

export enum FleetResourceEventType {
  AVAILABLE = 'AVAILABLE',
  SENSOR_UPDATE = 'SENSOR_UPDATE',
  TAG_ADDED = 'TAG_ADDED',
  TAG_REMOVED = 'TAG_REMOVED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export type FleetResourceMetadata = {
  __typename?: 'FleetResourceMetadata';
  dispatchingTlcBase?: Maybe<ResourceMetadataTLCBase>;
  fhvLicenseNumber: Scalars['String']['output'];
  fleetResourceId: Scalars['ID']['output'];
  insuranceProvider?: Maybe<InsuranceProvider>;
  isControllable?: Maybe<Scalars['Boolean']['output']>;
  tlcBase?: Maybe<ResourceMetadataTLCBase>;
  vehicle?: Maybe<FleetResource>;
};

export type FleetResourceMetadataResponse = {
  __typename?: 'FleetResourceMetadataResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<Maybe<FleetResourceMetadata>>>;
};

export enum FleetResourceReservationStatus {
  AVAILABLE = 'AVAILABLE',
  TAKEN_IN_ELECTRICFEEL = 'TAKEN_IN_ELECTRICFEEL',
  WITH_CURRENT_OPERATOR_IN_RESERVATION = 'WITH_CURRENT_OPERATOR_IN_RESERVATION',
  WITH_CURRENT_OPERATOR_IN_WORKORDER = 'WITH_CURRENT_OPERATOR_IN_WORKORDER',
  WITH_OPERATOR_IN_ELECTRICFEEL = 'WITH_OPERATOR_IN_ELECTRICFEEL',
  WITH_OPERATOR_IN_RESERVATION = 'WITH_OPERATOR_IN_RESERVATION',
  WITH_OPERATOR_IN_WORKORDER = 'WITH_OPERATOR_IN_WORKORDER',
  WITH_RENTER = 'WITH_RENTER',
}

export type FleetResourceSession = {
  __typename?: 'FleetResourceSession';
  efOperatorId?: Maybe<Scalars['Int']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  fleetResourceId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  identity?: Maybe<Identity>;
  identityExternalId?: Maybe<Scalars['String']['output']>;
  identityType?: Maybe<Scalars['String']['output']>;
  serviceContext?: Maybe<ServiceContext>;
  serviceContextId?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
};

export type FleetResourceSessionFilters = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  serviceContext?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export enum FleetResourceStatusValues {
  active = 'active',
  hibernation = 'hibernation',
  in_shop = 'in_shop',
  inactive = 'inactive',
  isolation = 'isolation',
  new_delivered_and_inspected = 'new_delivered_and_inspected',
  out_of_service = 'out_of_service',
  ready_for_pick_up = 'ready_for_pick_up',
}

export type FleetResourcesStats = {
  __typename?: 'FleetResourcesStats';
  availableFleetResourcesCount: Scalars['Int']['output'];
  batteryDischargedFleetResourcesCount: Scalars['Int']['output'];
  blockedCount: Scalars['Int']['output'];
  dischargedSwappableCount: Scalars['Int']['output'];
  dischargedTotalCount: Scalars['Int']['output'];
  inAssignmentSessionFleetResourcesCount: Scalars['Int']['output'];
  inConsumerSessionFleetResourcesCount: Scalars['Int']['output'];
  inReservationSessionFleetResourcesCount: Scalars['Int']['output'];
  rentalsTodayCount: Scalars['Int']['output'];
  selectedResourcesAverageBatteryLevel: Scalars['Float']['output'];
  specialOpsCount: Scalars['Int']['output'];
  taskBlockedFleetResourcesCount: Scalars['Int']['output'];
  towCount: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
};

export type GetDepotsFilters = {
  id__in?: InputMaybe<Array<Scalars['ID']['input']>>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetDriverOpsDriverAndDetailsFilters = {
  driverFilters?: InputMaybe<DriverOpsDriversFilters>;
  paginationFilters?: InputMaybe<PaginationFilter>;
  shiftFilters?: InputMaybe<DriverOpsShiftFilters>;
};

export type GetDriverOpsDriverAndShiftFilters = {
  evaluatedState?: InputMaybe<DriverOpsEvaluatedState>;
  ordering?: InputMaybe<Array<DriverOpsShiftOrderingField>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  scheduledStartAtEnd?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAtStart?: InputMaybe<Scalars['String']['input']>;
  state__in?: InputMaybe<Array<DriverOpsShiftStates>>;
};

export type GetDriverSystemZonesFilters = {
  zoneType?: InputMaybe<OpsSystemZoneType>;
};

export type GoogleDirections = {
  __typename?: 'GoogleDirections';
  distance?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
};

export type GoogleLoginCXAgentInput = {
  googleIdToken: Scalars['String']['input'];
};

export type GoogleLoginOperatorInput = {
  googleIdToken: Scalars['String']['input'];
};

export type HireDepotConfig = {
  __typename?: 'HireDepotConfig';
  id: Scalars['ID']['output'];
};

export type Hub = {
  __typename?: 'Hub';
  addressDisplayKey: Scalars['String']['output'];
  availableResourceCount: Scalars['Int']['output'];
  capacity: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  descriptionDisplayKey: Scalars['String']['output'];
  entrance: Point;
  id: Scalars['ID']['output'];
  images: Array<Scalars['String']['output']>;
  internalName?: Maybe<Scalars['String']['output']>;
  isLive: Scalars['Boolean']['output'];
  nameDisplayKey: Scalars['String']['output'];
  perimeter: Scalars['String']['output'];
  resourceCount: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
  unusedCapacity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type HubEvent = {
  __typename?: 'HubEvent';
  eventType?: Maybe<HubEventType>;
  hub?: Maybe<Hub>;
};

export enum HubEventType {
  CHANGE = 'CHANGE',
}

export type HubResource = {
  __typename?: 'HubResource';
  enteredAt: Scalars['DateTime']['output'];
  enteredByService: Scalars['String']['output'];
  enteredByServiceContext: Scalars['String']['output'];
  enteredByServiceContextId: Scalars['String']['output'];
  hub: Hub;
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  removedByService?: Maybe<Scalars['String']['output']>;
  removedByServiceContext?: Maybe<Scalars['String']['output']>;
  removedByServiceContextId?: Maybe<Scalars['String']['output']>;
  resource: FleetResource;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Identity = {
  __typename?: 'Identity';
  context: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
};

export type ImgSrc = {
  __typename?: 'ImgSrc';
  src?: Maybe<Scalars['String']['output']>;
  srcset?: Maybe<Scalars['String']['output']>;
};

export type Ineligibility = {
  __typename?: 'Ineligibility';
  end?: Maybe<Scalars['DateTime']['output']>;
  endDescription?: Maybe<Scalars['String']['output']>;
  endServiceContext?: Maybe<IneligibilityServiceContext>;
  endedByIdentity?: Maybe<Identity>;
  endedByIdentityId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  ineligibilityReason?: Maybe<CXIneligibilityReason>;
  renterViolation?: Maybe<RenterViolation>;
  start: Scalars['DateTime']['output'];
  startDescription?: Maybe<Scalars['String']['output']>;
  startServiceContext?: Maybe<IneligibilityServiceContext>;
  /** Resolver fetched fields */
  startedByIdentity?: Maybe<Identity>;
  startedByIdentityId?: Maybe<Scalars['ID']['output']>;
};

export type IneligibilityFilters = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum IneligibilityServiceContext {
  CONSUMER = 'CONSUMER',
  CX = 'CX',
  MIGRATION = 'MIGRATION',
}

export enum InsurancePremiumTiers {
  tier_one = 'tier_one',
  tier_three = 'tier_three',
  tier_two = 'tier_two',
}

export type InsuranceProvider = {
  __typename?: 'InsuranceProvider';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type InsuranceProviderResponse = {
  __typename?: 'InsuranceProviderResponse';
  count: Scalars['Int']['output'];
  results: Array<InsuranceProvider>;
};

export enum InvoiceFilterCategoryOptions {
  ebike = 'ebike',
  other = 'other',
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export type InvoiceFilters = {
  category?: InputMaybe<InvoiceFilterCategoryOptions>;
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  renterId: Scalars['ID']['input'];
};

export type InvoiceLineItemDeprecated = {
  __typename?: 'InvoiceLineItemDeprecated';
  category: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  itemValue: Scalars['String']['output'];
};

export type IsInCar = {
  __typename?: 'IsInCar';
  inCar: Scalars['Boolean']['output'];
};

export type Issue = {
  __typename?: 'Issue';
  assignedTo?: Maybe<CXAgent>;
  closeReason?: Maybe<Scalars['String']['output']>;
  closeReasonDescription?: Maybe<Scalars['String']['output']>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  closedBy?: Maybe<CXAgent>;
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fleetResourceId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  issueData?: Maybe<ProblemReport>;
  /** Resolver fetched fields */
  issueDetail?: Maybe<IssueDetail>;
  issueType: IssueType;
  objectId: Scalars['ID']['output'];
  objectTime: Scalars['DateTime']['output'];
  rentalRating?: Maybe<Scalars['Int']['output']>;
  renterId?: Maybe<Scalars['ID']['output']>;
  status: IssueStatus;
  system?: Maybe<System>;
  systemId: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
};

export type IssueDetail = {
  __typename?: 'IssueDetail';
  communityReport?: Maybe<CommunityReport>;
  idVerification?: Maybe<CXIdVerification>;
  problemReport?: Maybe<ProblemReport>;
};

export type IssueDriverCreditInput = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Int']['input'];
  driverId: Scalars['ID']['input'];
  reasonGranted: DriverCreditGrantingReasons;
};

export type IssueFilters = {
  assignmentState?: InputMaybe<Scalars['String']['input']>;
  cxAgentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  issueType?: InputMaybe<IssueType>;
  ordering?: InputMaybe<IssueOrdering>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rentalRatings?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  renterId?: InputMaybe<Scalars['ID']['input']>;
  showAutomated?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IssueStatus>;
  systemIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type IssueImageUploadTarget = {
  __typename?: 'IssueImageUploadTarget';
  s3Path: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type IssueList = {
  __typename?: 'IssueList';
  count: Scalars['Int']['output'];
  results: Array<Issue>;
};

export enum IssueOrdering {
  asc = 'asc',
  desc = 'desc',
}

export type IssueResponse = {
  __typename?: 'IssueResponse';
  id?: Maybe<Scalars['ID']['output']>;
};

export enum IssueStatus {
  closed = 'closed',
  open = 'open',
}

export enum IssueType {
  community_report = 'community_report',
  failed_idv = 'failed_idv',
  problem_report = 'problem_report',
}

export type LegalDocument = {
  __typename?: 'LegalDocument';
  createdAt: Scalars['DateTime']['output'];
  documentType: LegalDocumentTypes;
  documentUrl: Scalars['String']['output'];
  effectiveDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export enum LegalDocumentTypes {
  driver_terms_of_use = 'driver_terms_of_use',
  reservation_contract = 'reservation_contract',
}

export type License = {
  __typename?: 'License';
  expiresAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  licenseNumber: Scalars['String']['output'];
  licenseType: Scalars['String']['output'];
  photoBackUrl?: Maybe<Scalars['String']['output']>;
  photoFrontUrl?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LicenseFilters = {
  driverId: Scalars['ID']['input'];
  licenseType__in?: InputMaybe<Array<LicenseTypes>>;
  status__in?: InputMaybe<Array<LicenseStatuses>>;
};

export type LicenseResponse = {
  __typename?: 'LicenseResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<License>>;
};

export enum LicenseStatuses {
  approved = 'approved',
  pending_review = 'pending_review',
  rejected = 'rejected',
  replaced = 'replaced',
}

export enum LicenseTypes {
  dmv = 'dmv',
  tlc = 'tlc',
}

export type LineString = {
  __typename?: 'LineString';
  coordinates?: Maybe<Scalars['Coordinates']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type LinkAndGeneratePassengerPasswordInput = {
  licensePlate: Scalars['String']['input'];
  passengerAccountId: Scalars['ID']['input'];
};

export type LinkAndGeneratePassengerPasswordResponse = {
  __typename?: 'LinkAndGeneratePassengerPasswordResponse';
  expiresAt: Scalars['DateTime']['output'];
  password: Scalars['String']['output'];
};

export type ListOpenShiftFilters = {
  scheduledStartAt__gte?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAt__lt?: InputMaybe<Scalars['String']['input']>;
};

export type ListOpenShiftTemplateFilters = {
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  endWeekday?: InputMaybe<Scalars['Int']['input']>;
  openShiftTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  pendingScheduleOpenShiftTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startWeekday?: InputMaybe<Scalars['Int']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type ListRecurringShiftsFilters = {
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  endTimeLte?: InputMaybe<Scalars['String']['input']>;
  endWeekday?: InputMaybe<Scalars['Int']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  startTimeGte?: InputMaybe<Scalars['String']['input']>;
  startWeekday?: InputMaybe<Scalars['Int']['input']>;
};

export type ListTrafficViolationTicketResponse = {
  __typename?: 'ListTrafficViolationTicketResponse';
  count: Scalars['Int']['output'];
  results: Array<TrafficViolationTicket>;
};

export enum LocalizedItemTypeEnum {
  airport_fee = 'airport_fee',
  billable_duration_reduction = 'billable_duration_reduction',
  black_car_fee = 'black_car_fee',
  boost_incentive = 'boost_incentive',
  charity_donation = 'charity_donation',
  congestion_fee = 'congestion_fee',
  coupon = 'coupon',
  free_paused_discount = 'free_paused_discount',
  insurance_fee = 'insurance_fee',
  minimum_stripe_charge_reduction = 'minimum_stripe_charge_reduction',
  minutes = 'minutes',
  rental_pass_reduction = 'rental_pass_reduction',
  renter_unable_to_ride = 'renter_unable_to_ride',
  renter_unable_to_ride_reduction = 'renter_unable_to_ride_reduction',
  ride_credit = 'ride_credit',
  ride_discount = 'ride_discount',
  ride_experimental_discount = 'ride_experimental_discount',
  ride_fare = 'ride_fare',
  ride_locations = 'ride_locations',
  ride_paused_time = 'ride_paused_time',
  ride_rental_pass_discount = 'ride_rental_pass_discount',
  ride_subtotal = 'ride_subtotal',
  ride_tax = 'ride_tax',
  ride_time = 'ride_time',
  ride_tip = 'ride_tip',
  ride_total = 'ride_total',
  ride_unlock_fee = 'ride_unlock_fee',
  service_fee = 'service_fee',
  toll_fee = 'toll_fee',
  under_minimum_stripe_charge_threshold = 'under_minimum_stripe_charge_threshold',
}

export type Location = {
  __typename?: 'Location';
  centerPoint: Point;
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<LocationGroup>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resourceCount?: Maybe<Scalars['Int']['output']>;
  system?: Maybe<System>;
  systemId: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
};

export type LocationAction = {
  __typename?: 'LocationAction';
  actionByIdentity: Identity;
  actionByIdentityId: Scalars['String']['output'];
  actionTime: Scalars['DateTime']['output'];
  actionType: LocationActionType;
  locationGroup: LocationGroup;
  resourceId: Scalars['String']['output'];
  serviceContextId: Scalars['String']['output'];
};

export enum LocationActionType {
  added = 'added',
  removed = 'removed',
}

export type LocationFilters = {
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationGroup = {
  __typename?: 'LocationGroup';
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fleetResources?: Maybe<Array<FleetResource>>;
  groupType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location: Location;
  locationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  resourceCount?: Maybe<Scalars['Int']['output']>;
  system?: Maybe<System>;
  systemId: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
};

export type LocationGroupAction = {
  __typename?: 'LocationGroupAction';
  command: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locationGroup: LocationGroup;
  operator: Operator;
};

export type LocationGroupFilters = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  systemId: Scalars['ID']['input'];
};

export type LocationInput = {
  centerPoint: PointInput;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  systemId: Scalars['ID']['input'];
};

export type LoginCXAgentResponse = {
  __typename?: 'LoginCXAgentResponse';
  identity: Identity;
  operator: CXAgent;
  token: Token;
};

export type LoginDriverResponse = {
  __typename?: 'LoginDriverResponse';
  identity?: Maybe<Identity>;
  token?: Maybe<Token>;
};

export type LoginDriveropsResponse = {
  __typename?: 'LoginDriveropsResponse';
  identity?: Maybe<Identity>;
  token?: Maybe<Token>;
};

export type LoginOperatorInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginOperatorResponse = {
  __typename?: 'LoginOperatorResponse';
  identity?: Maybe<Identity>;
  operator?: Maybe<Operator>;
  token?: Maybe<Token>;
};

export type LoginPaxInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  identity: Identity;
  token: Token;
};

export enum LogisticsManagers {
  revel = 'revel',
}

export type ManualSystemClosure = {
  __typename?: 'ManualSystemClosure';
  closureReason?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  start: Scalars['DateTime']['output'];
};

export type MinimumAppVersions = {
  __typename?: 'MinimumAppVersions';
  android: Scalars['String']['output'];
  ios: Scalars['String']['output'];
  web: Scalars['String']['output'];
};

export type ModeratorRentalSelfie = {
  __typename?: 'ModeratorRentalSelfie';
  id: Scalars['ID']['output'];
  rider1ImageUrl: Scalars['String']['output'];
  rider2ImageUrl?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type ModeratorSelfieAssignmentsInput = {
  numRentals: Scalars['Int']['input'];
};

export type ModeratorSelfieQueueStats = {
  __typename?: 'ModeratorSelfieQueueStats';
  length: Scalars['Int']['output'];
};

export type ModeratorSelfieResultsInput = {
  rentalSelfieId: Scalars['ID']['input'];
  rider1valid: Scalars['Boolean']['input'];
  rider2valid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MostRecentNonDriverScheduledHours = {
  __typename?: 'MostRecentNonDriverScheduledHours';
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  shiftId: Scalars['ID']['output'];
};

export type MultiLineStringType = {
  __typename?: 'MultiLineStringType';
  coordinates: Array<Maybe<Scalars['Coordinates']['output']>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MultiPolygon = {
  __typename?: 'MultiPolygon';
  coordinates?: Maybe<Scalars['Coordinates']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptRentalTou?: Maybe<Scalars['Boolean']['output']>;
  activateSystemZoneDraft?: Maybe<OpsSystemZone>;
  actviateOpsServiceArea: ServiceArea;
  addDriverPaymentMethod: DriverPaymentMethod;
  addOpsHubResource: HubResource;
  addRentalIdToCommunityReport?: Maybe<CommunityReport>;
  addRenterSuspension?: Maybe<Ineligibility>;
  addRenterViolation?: Maybe<RenterViolation>;
  addRenterViolationImages?: Maybe<RenterViolationImagePresignedUrls>;
  agreeToReservationContract?: Maybe<Scalars['Boolean']['output']>;
  applyCXCredit?: Maybe<CreditMutationResponse>;
  assignIssue?: Maybe<IssueResponse>;
  assignVehicleToShift: DriverShift;
  cancelCXSubscription: CXOrderSubscriptionCancellation;
  cancelDriverOpsDriverShift: DriverShift;
  checkInShift: DriverShift;
  checkInShiftOverride: DriverShift;
  claimCXRentalAccountValidationAssignments?: Maybe<Array<RentalAccountValidation>>;
  claimCXRentalSelfieAssignments?: Maybe<Array<ModeratorRentalSelfie>>;
  claimOpenShiftTemplates: Array<RecurringShift>;
  clearLocationGroupResources?: Maybe<EmptyResponse>;
  clearRideAssignment?: Maybe<Scalars['Boolean']['output']>;
  closeIssue?: Maybe<IssueResponse>;
  closeTask?: Maybe<Task>;
  commandFleetResource?: Maybe<CommandResourceResponse>;
  commandLocationGroupResources?: Maybe<EmptyResponse>;
  commandResource: Scalars['Boolean']['output'];
  commandShiftFleetResource: Scalars['Boolean']['output'];
  completeWorkOrder: WorkOrder;
  createAndStartWorkOrder?: Maybe<WorkOrder>;
  createAssignment?: Maybe<Assignment>;
  createAssignmentOperatorLocation?: Maybe<AssignmentOperatorLocation>;
  createCXInvoice?: Maybe<CXInvoice>;
  createDriverContractConversionForm?: Maybe<DriverContractConversionForm>;
  createDriverPhoneMaskingSession?: Maybe<DriverPhoneMaskingSession>;
  createDriverSecurityDeposit: DriverInvoice;
  createDriverSetupIntent?: Maybe<DriverSetupIntent>;
  createDriverShift: DriverShift;
  createFlag: Flag;
  createFleetResourceServiceLog?: Maybe<ServiceLog>;
  createLicense: License;
  createLocation: Location;
  createLocationGroup: LocationGroup;
  createLocationGroupResource?: Maybe<EmptyResponse>;
  createManualInvoice: DriverInvoice;
  createOperator?: Maybe<Operator>;
  createOperatorLocation?: Maybe<OperatorLocation>;
  createOpsServiceArea: ServiceArea;
  createPhoneVerification: PhoneVerification;
  createRecurringShift?: Maybe<RecurringShift>;
  createRepair: Repair;
  createReservation?: Maybe<Reservation>;
  createShiftFromOpenShift: DriverShift;
  createSystemZoneDraft?: Maybe<OpsSystemZone>;
  createTask?: Maybe<Task>;
  createTaskComment?: Maybe<TaskComment>;
  createWorkOrder?: Maybe<WorkOrder>;
  cxApplyCredit?: Maybe<CreditMutationResponse>;
  deleteAssignment?: Maybe<Assignment>;
  deleteDriverContractConversionForm?: Maybe<EmptyResponse>;
  deleteDriverPaymentMethod?: Maybe<EmptyResponse>;
  deleteLocation?: Maybe<EmptyResponse>;
  deleteLocationGroup?: Maybe<EmptyResponse>;
  deleteLocationGroupResource?: Maybe<EmptyResponse>;
  deleteRecurringShift?: Maybe<EmptyResponse>;
  deleteRepair?: Maybe<EmptyResponse>;
  deleteWorkOrder?: Maybe<WorkOrder>;
  driverCallOutShift: DriverShift;
  driverCreateSharedNumberVerification?: Maybe<EmptyResponse>;
  driverLogin: LoginDriverResponse;
  driverRequestTwoFactorLogin: DriverRequestPhoneLoginResponse;
  driverSignUp: DriverSignUpResponse;
  driverStartShift?: Maybe<DriverShift>;
  driverSubmitSharedNumberVerification?: Maybe<EmptyResponse>;
  driverSubmitTwoFactorLogin: DriverSubmitPhoneLoginResponse;
  endAssignment?: Maybe<Assignment>;
  endRental?: Maybe<Scalars['Boolean']['output']>;
  endRenterSuspension?: Maybe<Ineligibility>;
  endReservation?: Maybe<Reservation>;
  endReservationShift: DriverShift;
  endReservationShiftOverride: DriverShift;
  expireCXCredit?: Maybe<CXCreditBalance>;
  extendDriverUnscheduledBreak: DriverStopPoint;
  extendShift: DriverShift;
  generateDriverAssetPresignedPost: DriverAssetPresignedPost;
  generateIssueImageUploadTarget: IssueImageUploadTarget;
  googleLogin: LoginResponse;
  googleLoginCXAgent?: Maybe<LoginCXAgentResponse>;
  googleLoginOperator?: Maybe<LoginOperatorResponse>;
  grantDriverPunchAuthURL: DriverPunchAuthURL;
  issueDriverCredit: DriverCredit;
  linkAndGeneratePassengerPassword?: Maybe<LinkAndGeneratePassengerPasswordResponse>;
  loginOperator?: Maybe<LoginOperatorResponse>;
  loginOtpPax?: Maybe<LoginResponse>;
  logoutPassengerDevice?: Maybe<EmptyResponse>;
  manipulateStopPoint: DriverStopPoint;
  openTask?: Maybe<Task>;
  outOfVehiclePunch: DriverShift;
  passengerCommandResource?: Maybe<CommandResourceResponse>;
  passengerSendSegmentEvent?: Maybe<PassengerSegmentDataResponse>;
  progressCurrentDriverStopPoint?: Maybe<DriverStopPoint>;
  publishAssignment?: Maybe<Assignment>;
  ratePassengerRideHail?: Maybe<Scalars['Boolean']['output']>;
  refundInvoice?: Maybe<CXInvoice>;
  removeAssignedVehicleFromShift: DriverShift;
  removeOpsHubResource: HubResource;
  removeRenterDiscountRate?: Maybe<Scalars['Boolean']['output']>;
  removeRenterPhoneNumber?: Maybe<CXRenter>;
  removeRenterViolation?: Maybe<RenterViolation>;
  reportDriverShiftIssue: DriverShift;
  requestDriverDelete?: Maybe<Scalars['Boolean']['output']>;
  requestDriverRecall?: Maybe<EmptyResponse>;
  requestUnscheduledBreak: DriverShift;
  resendBackgroundCheck?: Maybe<Scalars['Boolean']['output']>;
  resendDocusignDocuments?: Maybe<Scalars['Boolean']['output']>;
  resolveDriverShiftIssue: DriverShift;
  retryDriverInvoice?: Maybe<DriverInvoice>;
  retryInvoice?: Maybe<CXInvoice>;
  revealPhoneVerificationCode?: Maybe<VerificationCode>;
  sendRenterPasswordReset?: Maybe<Scalars['Boolean']['output']>;
  setDriverPickupStopPointNoShow?: Maybe<DriverStopPoint>;
  setDriverStopPointAcknowledged?: Maybe<DriverStopPoint>;
  setDriverStopPointArrived?: Maybe<DriverStopPoint>;
  setDriverStopPointComplete?: Maybe<DriverStopPoint>;
  setRenterDiscountRate?: Maybe<DiscountRate>;
  skipDriverScheduledBreak: DriverStopPoint;
  skipWorkOrder: WorkOrder;
  snoozeDriverScheduledBreak: DriverStopPoint;
  softDeleteRenter?: Maybe<Scalars['Boolean']['output']>;
  standbyReservation: RentalReservation;
  startAssignment?: Maybe<Assignment>;
  startWorkOrder: WorkOrder;
  submitCXRentalAccountValidationResults: Scalars['Boolean']['output'];
  submitCXRentalSelfieResults: Scalars['Boolean']['output'];
  submitPhoneVerification: PhoneVerification;
  submitUpdateLicenseReview: License;
  transferReservation?: Maybe<Reservation>;
  unlinkPassenger?: Maybe<EmptyResponse>;
  unlockHelmetCaseInRental?: Maybe<Scalars['Boolean']['output']>;
  unlockVehicleForWorkOrder: WorkOrder;
  unlockVehicleInRental?: Maybe<Scalars['Boolean']['output']>;
  updateAssignment?: Maybe<Assignment>;
  updateCXIdVerification?: Maybe<CXIdVerification>;
  updateCXOrder: CXOrder;
  updateCXSubscriptionCancellation: CXOrderSubscriptionCancellation;
  updateDriverInsuranceApprovals: Array<Maybe<DriverInsuranceApproval>>;
  updateDriverPaymentMethod: DriverPaymentMethod;
  updateDriverProfile: Driver;
  updateDriverScheduledShiftTime: DriverShift;
  updateDriverShift: DriverShift;
  updateDriverShiftDevice?: Maybe<DriverShiftDevice>;
  updateDriverShiftState: DriverShift;
  updateDriverVehicleExclusions: Array<Maybe<DriverVehicleExclusionMakeAndModel>>;
  updateDriverVehiclePreference: DriverVehiclePreferenceResponse;
  updateFlag: Flag;
  updateInternalDriverProfile: Driver;
  updateLocation: Location;
  updateLocationGroup: LocationGroup;
  updateMyVehiclePreference: DriverVehiclePreferenceResponse;
  updateOperator?: Maybe<Operator>;
  updatePushNotificationReceived: Scalars['Boolean']['output'];
  updateRecurringShift?: Maybe<RecurringShift>;
  updateRecurringShiftSettings?: Maybe<ShiftSettings>;
  updateRenterPhoneNumber?: Maybe<CXRenter>;
  updateRentersEmail?: Maybe<CXRenter>;
  updateRentersName?: Maybe<CXRenter>;
  updateRepair: Repair;
  updateShiftClockTimes: DriverShift;
  updateShiftExemptions?: Maybe<ShiftSettings>;
  updateShiftNote: ShiftNote;
  updateShiftSettings?: Maybe<ShiftSettings>;
  updateShiftVehiclesLocation?: Maybe<Scalars['Boolean']['output']>;
  updateWorkOrder?: Maybe<WorkOrder>;
  voidInvoice?: Maybe<CXInvoice>;
};

export type MutationacceptRentalTouArgs = {
  id: Scalars['ID']['input'];
};

export type MutationactivateSystemZoneDraftArgs = {
  draftId: Scalars['ID']['input'];
};

export type MutationactviateOpsServiceAreaArgs = {
  input?: InputMaybe<OpsActviateServiceAreaInput>;
};

export type MutationaddDriverPaymentMethodArgs = {
  setupIntentId: Scalars['ID']['input'];
};

export type MutationaddOpsHubResourceArgs = {
  input: OpsHubResourceActionInput;
};

export type MutationaddRentalIdToCommunityReportArgs = {
  input: AddRentalIdToCommunityReportInput;
};

export type MutationaddRenterSuspensionArgs = {
  input: RenterSuspensionAddInput;
};

export type MutationaddRenterViolationArgs = {
  input: AddRenterViolationInput;
};

export type MutationaddRenterViolationImagesArgs = {
  input: AddRenterViolationImagesInput;
};

export type MutationagreeToReservationContractArgs = {
  input: AgreeToReservationContractInput;
};

export type MutationapplyCXCreditArgs = {
  input?: InputMaybe<CreditInput>;
};

export type MutationassignIssueArgs = {
  id: Scalars['ID']['input'];
};

export type MutationassignVehicleToShiftArgs = {
  fleetResourceId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MutationcancelCXSubscriptionArgs = {
  id: Scalars['ID']['input'];
  input: CXOrderSubscriptionCancellationInput;
};

export type MutationcancelDriverOpsDriverShiftArgs = {
  input: CancelDriverOpsDriverShiftInput;
};

export type MutationcheckInShiftArgs = {
  input: CheckInShiftInput;
};

export type MutationcheckInShiftOverrideArgs = {
  input: CheckInShiftOverrideInput;
};

export type MutationclaimCXRentalAccountValidationAssignmentsArgs = {
  input: ModeratorSelfieAssignmentsInput;
};

export type MutationclaimCXRentalSelfieAssignmentsArgs = {
  input: ModeratorSelfieAssignmentsInput;
};

export type MutationclaimOpenShiftTemplatesArgs = {
  input: ClaimOpenShiftTemplatesInput;
};

export type MutationclearLocationGroupResourcesArgs = {
  locationGroupId: Scalars['ID']['input'];
};

export type MutationclearRideAssignmentArgs = {
  rideHailId: Scalars['ID']['input'];
};

export type MutationcloseIssueArgs = {
  input?: InputMaybe<CloseIssueInput>;
};

export type MutationcloseTaskArgs = {
  closeReason: TaskCloseReason;
  id: Scalars['ID']['input'];
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationcommandFleetResourceArgs = {
  id: Scalars['ID']['input'];
  input: commandFleetResourceInput;
};

export type MutationcommandLocationGroupResourcesArgs = {
  command: NiuCommand;
  locationGroupId: Scalars['ID']['input'];
};

export type MutationcommandResourceArgs = {
  id: Scalars['ID']['input'];
  input: CommandResourceInput;
};

export type MutationcommandShiftFleetResourceArgs = {
  input: CommandShiftFleetResourceInput;
  shiftId: Scalars['ID']['input'];
};

export type MutationcompleteWorkOrderArgs = {
  id: Scalars['ID']['input'];
  ignoreTelematicsErrors?: InputMaybe<Scalars['Boolean']['input']>;
  input?: InputMaybe<UpdateWorkOrderInput>;
};

export type MutationcreateAndStartWorkOrderArgs = {
  assignmentId: Scalars['ID']['input'];
  createdFor?: InputMaybe<WorkOrderCreationContext>;
  fleetResourceId: Scalars['ID']['input'];
};

export type MutationcreateAssignmentArgs = {
  input: CreateAssignmentInput;
};

export type MutationcreateAssignmentOperatorLocationArgs = {
  assignmentId: Scalars['ID']['input'];
  location: PointInput;
};

export type MutationcreateCXInvoiceArgs = {
  input: CreateInvoiceInput;
};

export type MutationcreateDriverContractConversionFormArgs = {
  driverId: Scalars['ID']['input'];
  input: CreateDriverContractConversionFormInput;
};

export type MutationcreateDriverPhoneMaskingSessionArgs = {
  stopPointId: Scalars['ID']['input'];
};

export type MutationcreateDriverShiftArgs = {
  input: CreateDriverShiftInput;
};

export type MutationcreateFlagArgs = {
  input: CreateFlagInput;
};

export type MutationcreateFleetResourceServiceLogArgs = {
  fleetResourceId: Scalars['String']['input'];
  input: CreateServiceLogInput;
};

export type MutationcreateLicenseArgs = {
  input?: InputMaybe<CreateLicenseInput>;
};

export type MutationcreateLocationArgs = {
  input: LocationInput;
};

export type MutationcreateLocationGroupArgs = {
  input: LocationGroupInput;
};

export type MutationcreateLocationGroupResourceArgs = {
  locationGroupId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};

export type MutationcreateManualInvoiceArgs = {
  input: CreateManualInvoiceInput;
};

export type MutationcreateOperatorArgs = {
  input: CreateOperatorInput;
};

export type MutationcreateOperatorLocationArgs = {
  location: PointInput;
};

export type MutationcreateOpsServiceAreaArgs = {
  input?: InputMaybe<OpsCreateServiceAreaInput>;
};

export type MutationcreatePhoneVerificationArgs = {
  input: PhoneVerificationInput;
};

export type MutationcreateRecurringShiftArgs = {
  input: CreateRecurringShiftInput;
};

export type MutationcreateRepairArgs = {
  input: CreateRepairInput;
};

export type MutationcreateReservationArgs = {
  fleetResourceId: Scalars['ID']['input'];
};

export type MutationcreateShiftFromOpenShiftArgs = {
  openShiftId: Scalars['ID']['input'];
};

export type MutationcreateSystemZoneDraftArgs = {
  input?: InputMaybe<OpsCreateSystemZoneDraftInput>;
};

export type MutationcreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationcreateTaskCommentArgs = {
  comment: Scalars['String']['input'];
  taskId: Scalars['ID']['input'];
};

export type MutationcreateWorkOrderArgs = {
  assignmentId: Scalars['ID']['input'];
  createdFor?: InputMaybe<WorkOrderCreationContext>;
  fleetResourceId: Scalars['ID']['input'];
};

export type MutationcxApplyCreditArgs = {
  input?: InputMaybe<CreditInput>;
};

export type MutationdeleteAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteDriverContractConversionFormArgs = {
  driverId: Scalars['ID']['input'];
};

export type MutationdeleteDriverPaymentMethodArgs = {
  paymentMethodId: Scalars['ID']['input'];
};

export type MutationdeleteLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteLocationGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteLocationGroupResourceArgs = {
  locationGroupId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};

export type MutationdeleteRecurringShiftArgs = {
  deleteFutureShifts?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationdeleteRepairArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteWorkOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdriverCallOutShiftArgs = {
  input: CancelDriverShiftInput;
};

export type MutationdriverCreateSharedNumberVerificationArgs = {
  phoneNumber: Scalars['String']['input'];
};

export type MutationdriverLoginArgs = {
  googleIdToken: Scalars['ID']['input'];
  platform: Scalars['String']['input'];
};

export type MutationdriverRequestTwoFactorLoginArgs = {
  phoneNumber: Scalars['String']['input'];
};

export type MutationdriverSignUpArgs = {
  input: DriverSignUpInput;
};

export type MutationdriverStartShiftArgs = {
  input: DriverStartShiftInput;
};

export type MutationdriverSubmitSharedNumberVerificationArgs = {
  phoneNumber: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationdriverSubmitTwoFactorLoginArgs = {
  input: DriverSubmitTwoFactorLoginInput;
};

export type MutationendAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationendRentalArgs = {
  billableDurationReductionMinutes: Scalars['Int']['input'];
  billableDurationReductionReason?: InputMaybe<Scalars['String']['input']>;
  rentalId: Scalars['ID']['input'];
};

export type MutationendRenterSuspensionArgs = {
  input: RenterSuspensionEndInput;
};

export type MutationendReservationArgs = {
  id: Scalars['ID']['input'];
  ignoreTelematicsErrors?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationendReservationShiftArgs = {
  input: CheckInShiftInput;
};

export type MutationendReservationShiftOverrideArgs = {
  input: CheckInShiftOverrideInput;
};

export type MutationexpireCXCreditArgs = {
  id: Scalars['ID']['input'];
};

export type MutationextendDriverUnscheduledBreakArgs = {
  stopPointId: Scalars['ID']['input'];
};

export type MutationextendShiftArgs = {
  shiftId: Scalars['ID']['input'];
};

export type MutationgoogleLoginArgs = {
  googleIdToken: Scalars['ID']['input'];
};

export type MutationgoogleLoginCXAgentArgs = {
  input?: InputMaybe<GoogleLoginCXAgentInput>;
};

export type MutationgoogleLoginOperatorArgs = {
  input?: InputMaybe<GoogleLoginOperatorInput>;
};

export type MutationissueDriverCreditArgs = {
  input: IssueDriverCreditInput;
};

export type MutationlinkAndGeneratePassengerPasswordArgs = {
  input: LinkAndGeneratePassengerPasswordInput;
};

export type MutationloginOperatorArgs = {
  input?: InputMaybe<LoginOperatorInput>;
};

export type MutationloginOtpPaxArgs = {
  input?: InputMaybe<LoginPaxInput>;
};

export type MutationmanipulateStopPointArgs = {
  manipulationType: StopPointManipulationTypes;
  stopPointId: Scalars['ID']['input'];
};

export type MutationopenTaskArgs = {
  id: Scalars['ID']['input'];
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationoutOfVehiclePunchArgs = {
  input: OutOfVehiclePunchInput;
};

export type MutationpassengerCommandResourceArgs = {
  input: PassengerCommandResourceInput;
};

export type MutationpassengerSendSegmentEventArgs = {
  input?: InputMaybe<PassengerSegmentInput>;
};

export type MutationprogressCurrentDriverStopPointArgs = {
  currentStopPoint: Scalars['ID']['input'];
  updateToState: DriverStopPointStates;
};

export type MutationpublishAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationratePassengerRideHailArgs = {
  input: RatePassengerRideHailInput;
  rideHailId: Scalars['ID']['input'];
};

export type MutationrefundInvoiceArgs = {
  input: RefundInvoiceInput;
};

export type MutationremoveAssignedVehicleFromShiftArgs = {
  id: Scalars['ID']['input'];
};

export type MutationremoveOpsHubResourceArgs = {
  input: OpsHubResourceActionInput;
};

export type MutationremoveRenterDiscountRateArgs = {
  input?: InputMaybe<RemoveRenterDiscountRateInput>;
};

export type MutationremoveRenterPhoneNumberArgs = {
  input: RemoveRenterPhoneNumberInput;
};

export type MutationremoveRenterViolationArgs = {
  input: RemoveRenterViolationInput;
};

export type MutationreportDriverShiftIssueArgs = {
  input: ReportDriverShiftIssueInput;
};

export type MutationrequestDriverDeleteArgs = {
  input?: InputMaybe<RequestDriverDeleteInput>;
};

export type MutationrequestDriverRecallArgs = {
  shiftId: Scalars['ID']['input'];
};

export type MutationrequestUnscheduledBreakArgs = {
  isUrgent?: InputMaybe<Scalars['Boolean']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type MutationresendBackgroundCheckArgs = {
  input: ResendBackgroundCheckInput;
};

export type MutationresendDocusignDocumentsArgs = {
  input: ResendDocusignDocumentsInput;
};

export type MutationresolveDriverShiftIssueArgs = {
  endLocation?: InputMaybe<PointInput>;
  shiftId: Scalars['ID']['input'];
};

export type MutationretryDriverInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type MutationretryInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type MutationrevealPhoneVerificationCodeArgs = {
  phoneVerificationId: Scalars['ID']['input'];
};

export type MutationsendRenterPasswordResetArgs = {
  renterId: Scalars['ID']['input'];
};

export type MutationsetDriverPickupStopPointNoShowArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsetDriverStopPointAcknowledgedArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsetDriverStopPointArrivedArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsetDriverStopPointCompleteArgs = {
  location?: InputMaybe<PointInput>;
  overrideGeofence?: InputMaybe<Scalars['Boolean']['input']>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsetRenterDiscountRateArgs = {
  input?: InputMaybe<SetRenterDiscountRateInput>;
};

export type MutationskipDriverScheduledBreakArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationskipWorkOrderArgs = {
  id: Scalars['ID']['input'];
  ignoreTelematicsErrors?: InputMaybe<Scalars['Boolean']['input']>;
  skipReason: WorkOrderSkipReason;
};

export type MutationsnoozeDriverScheduledBreakArgs = {
  location?: InputMaybe<PointInput>;
  stopPointId: Scalars['ID']['input'];
};

export type MutationsoftDeleteRenterArgs = {
  input: SoftDeleteRenterInput;
};

export type MutationstandbyReservationArgs = {
  reservationId: Scalars['ID']['input'];
};

export type MutationstartAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationstartWorkOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationsubmitCXRentalAccountValidationResultsArgs = {
  input: Array<RentalAccountValidationResultsInput>;
};

export type MutationsubmitCXRentalSelfieResultsArgs = {
  input: Array<ModeratorSelfieResultsInput>;
};

export type MutationsubmitPhoneVerificationArgs = {
  input: PhoneVerificationSubmitInput;
};

export type MutationsubmitUpdateLicenseReviewArgs = {
  id: Scalars['ID']['input'];
  review?: InputMaybe<ReviewLicenseInput>;
  updatedLicense?: InputMaybe<UpdateLicenseInput>;
};

export type MutationtransferReservationArgs = {
  id: Scalars['ID']['input'];
  newOperatorId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationunlinkPassengerArgs = {
  input: UnlinkPassengerInput;
};

export type MutationunlockHelmetCaseInRentalArgs = {
  rentalId: Scalars['ID']['input'];
};

export type MutationunlockVehicleForWorkOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationunlockVehicleInRentalArgs = {
  rentalId: Scalars['ID']['input'];
};

export type MutationupdateAssignmentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAssignmentInput;
};

export type MutationupdateCXIdVerificationArgs = {
  input: UpdateCXIdVerificationInput;
};

export type MutationupdateCXOrderArgs = {
  id: Scalars['ID']['input'];
  input: CXOrderUpdateInput;
};

export type MutationupdateCXSubscriptionCancellationArgs = {
  id: Scalars['ID']['input'];
  input: CXOrderSubscriptionCancellationInput;
};

export type MutationupdateDriverInsuranceApprovalsArgs = {
  input: UpdateDriverInsuranceApprovalInput;
};

export type MutationupdateDriverPaymentMethodArgs = {
  input: UpdateDriverPaymentMethodInput;
};

export type MutationupdateDriverProfileArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDriverProfileInput;
};

export type MutationupdateDriverScheduledShiftTimeArgs = {
  input: UpdateDriverScheduledShiftTimeInput;
};

export type MutationupdateDriverShiftArgs = {
  input: UpdateDriverShiftInput;
};

export type MutationupdateDriverShiftDeviceArgs = {
  input: UpdateDriverShiftDeviceInput;
  shiftId: Scalars['ID']['input'];
};

export type MutationupdateDriverShiftStateArgs = {
  input: UpdateDriverShiftStateInput;
};

export type MutationupdateDriverVehicleExclusionsArgs = {
  input?: InputMaybe<UpdateDriverVehicleExclusionsInput>;
};

export type MutationupdateDriverVehiclePreferenceArgs = {
  input: UpdateDriverVehiclePreferenceInput;
};

export type MutationupdateFlagArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFlagInput;
};

export type MutationupdateInternalDriverProfileArgs = {
  id: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
};

export type MutationupdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: LocationInput;
};

export type MutationupdateLocationGroupArgs = {
  id: Scalars['ID']['input'];
  input: LocationGroupInput;
};

export type MutationupdateMyVehiclePreferenceArgs = {
  input: UpdateDriverVehiclePreferenceInput;
};

export type MutationupdateOperatorArgs = {
  input: UpdateOperatorInput;
};

export type MutationupdatePushNotificationReceivedArgs = {
  input: UpdatePushNotificationReceivedInput;
  shiftId: Scalars['ID']['input'];
};

export type MutationupdateRecurringShiftArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRecurringShiftInput;
};

export type MutationupdateRecurringShiftSettingsArgs = {
  id: Scalars['ID']['input'];
  input: ShiftSettingsInput;
};

export type MutationupdateRenterPhoneNumberArgs = {
  input: UpdateRenterPhoneNumberInput;
  renterId: Scalars['ID']['input'];
};

export type MutationupdateRentersEmailArgs = {
  input: updateRentersEmailInput;
};

export type MutationupdateRentersNameArgs = {
  input: updateRentersNameInput;
};

export type MutationupdateRepairArgs = {
  input: UpdateRepairInput;
};

export type MutationupdateShiftClockTimesArgs = {
  input: UpdateShiftClockTimesInput;
};

export type MutationupdateShiftExemptionsArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<ShiftExemptionInput>;
};

export type MutationupdateShiftNoteArgs = {
  id: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};

export type MutationupdateShiftSettingsArgs = {
  id: Scalars['ID']['input'];
  input: ShiftSettingsInput;
};

export type MutationupdateShiftVehiclesLocationArgs = {
  input: UpdateShiftVehicleLocationInput;
};

export type MutationupdateWorkOrderArgs = {
  input: UpdateWorkOrderInput;
  workOrderId: Scalars['ID']['input'];
};

export type MutationvoidInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export enum NiuCommand {
  ALARM = 'ALARM',
  ALARM_DISABLE = 'ALARM_DISABLE',
  DISABLE_485CMD = 'DISABLE_485CMD',
  ENABLE_485CMD = 'ENABLE_485CMD',
  END = 'END',
  IGNITION_OFF = 'IGNITION_OFF',
  IGNITION_ON = 'IGNITION_ON',
  LOCATING = 'LOCATING',
  REBOOT = 'REBOOT',
  SADDLE_ON = 'SADDLE_ON',
  SECURE_OFF = 'SECURE_OFF',
  SECURE_ON = 'SECURE_ON',
  START = 'START',
  TAILBOX_OFF = 'TAILBOX_OFF',
  TAILBOX_ON = 'TAILBOX_ON',
}

export enum OSName {
  android = 'android',
  ios = 'ios',
}

export type OnFleetResourceEventInput = {
  resourceId?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type OnParkingLotEventInput = {
  systemId: Scalars['ID']['input'];
};

export type OpenShift = {
  __typename?: 'OpenShift';
  estimatedPay: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  totalProjectedEarnings: Scalars['Int']['output'];
};

export type OpenShiftTemplate = {
  __typename?: 'OpenShiftTemplate';
  depotId?: Maybe<Scalars['ID']['output']>;
  endTime: Scalars['String']['output'];
  endWeekday: Scalars['Int']['output'];
  estimatedPay: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  scheduleCapacity?: Maybe<Scalars['Int']['output']>;
  startTime: Scalars['String']['output'];
  startWeekday: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
};

export type Operator = {
  __typename?: 'Operator';
  active?: Maybe<Scalars['Boolean']['output']>;
  defaultSystem?: Maybe<System>;
  defaultSystemId?: Maybe<Scalars['ID']['output']>;
  dev?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  identity?: Maybe<Identity>;
  roles?: Maybe<Array<Maybe<RoleMember>>>;
};

export type OperatorFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OperatorLocation = {
  __typename?: 'OperatorLocation';
  id: Scalars['ID']['output'];
  location: Point;
  operator?: Maybe<Operator>;
  operatorId: Scalars['ID']['output'];
};

export type OperatorLocationFilters = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  operatorId?: InputMaybe<Scalars['ID']['input']>;
};

export enum OperatorReservationStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export type OperatorReservationsFilters = {
  operatorId: Scalars['ID']['input'];
  status: OperatorReservationStatus;
};

export type OpsActviateServiceAreaInput = {
  product: ServiceAreaProduct;
  serviceAreaId: Scalars['ID']['input'];
};

export type OpsCreateServiceAreaInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  product: ServiceAreaProduct;
  serviceArea: Scalars['Geojson']['input'];
  serviceAreaInverted?: InputMaybe<Scalars['Geojson']['input']>;
  systemId: Scalars['ID']['input'];
};

export type OpsCreateSystemZoneDraftInput = {
  areaGeojson: Scalars['Geojson']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  zoneId: Scalars['ID']['input'];
};

export type OpsHubResourceActionInput = {
  hubId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};

export type OpsHubResourceFilters = {
  excludeTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inSession?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsServiceAreaFilters = {
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  product?: InputMaybe<ServiceAreaProduct>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type OpsSystemServiceAreaHistoryFilters = {
  createdAtEnd?: InputMaybe<Scalars['String']['input']>;
  createdAtStart?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  product?: InputMaybe<ServiceAreaProduct>;
};

export type OpsSystemZone = {
  __typename?: 'OpsSystemZone';
  areaGeojson?: Maybe<Scalars['Geojson']['output']>;
  areaGeojsonString: Scalars['String']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  draft: OpsSystemZoneDraft;
  drafts: SystemZoneDraftsList;
  id: Scalars['ID']['output'];
  systemId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
  zoneType: OpsSystemZoneType;
};

export type OpsSystemZoneDraft = {
  __typename?: 'OpsSystemZoneDraft';
  areaGeojson: Scalars['Geojson']['output'];
  areaGeojsonString: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type OpsSystemZoneFilters = {
  systemId: Scalars['ID']['input'];
};

export enum OpsSystemZoneType {
  airport = 'airport',
  androidfix = 'androidfix',
  balancing = 'balancing',
  demand_pricing = 'demand_pricing',
  discounted_moped_zone = 'discounted_moped_zone',
  distributed_fleet_start_shift_zone = 'distributed_fleet_start_shift_zone',
  driver_ride_hail_area = 'driver_ride_hail_area',
  park_mobile = 'park_mobile',
  pricing = 'pricing',
  ride_hail_restriction = 'ride_hail_restriction',
  rideable_area = 'rideable_area',
  territory_fee = 'territory_fee',
  ticket_warning_zone = 'ticket_warning_zone',
}

export type OutOfVehiclePunchInput = {
  punchAuth: Scalars['String']['input'];
  punchField: Scalars['String']['input'];
  shiftId: Scalars['ID']['input'];
  userLocation?: InputMaybe<PointInput>;
};

export type Pagination = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginationFilter = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PassengerAppAccount = {
  __typename?: 'PassengerAppAccount';
  createdAt: Scalars['DateTime']['output'];
  deviceType?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  linkedAt?: Maybe<Scalars['DateTime']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PassengerAppAccountFilters = {
  resourceId?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PassengerCommandResourceInput = {
  command: TeslaCommand;
  heater?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
};

export type PassengerRenter = {
  __typename?: 'PassengerRenter';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
};

export type PassengerRideHail = {
  __typename?: 'PassengerRideHail';
  id: Scalars['ID']['output'];
  renter: PassengerRenter;
  rideHailOffer?: Maybe<PassengerRideHailOffer>;
  state?: Maybe<CXRideHailStates>;
  vehicle?: Maybe<PassengerVehicle>;
  vehicleBearing?: Maybe<Scalars['Float']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type PassengerRideHailEvent = {
  __typename?: 'PassengerRideHailEvent';
  eventType?: Maybe<PassengerRideHailEventType>;
  rideHail?: Maybe<PassengerRideHail>;
};

export enum PassengerRideHailEventType {
  ACTIVE = 'ACTIVE',
  ARRIVED = 'ARRIVED',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  CREATED = 'CREATED',
  DISPATCHED = 'DISPATCHED',
  DRIVER_LOCATION_UPDATE = 'DRIVER_LOCATION_UPDATE',
  DRIVER_REASSIGNED = 'DRIVER_REASSIGNED',
  FAILED = 'FAILED',
  MATCHING = 'MATCHING',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  RIDE_ISSUE = 'RIDE_ISSUE',
}

export type PassengerRideHailOffer = {
  __typename?: 'PassengerRideHailOffer';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  estimatedArrivalTime: Scalars['DateTime']['output'];
  estimatedRemainingTripDurationMinutes?: Maybe<Scalars['Int']['output']>;
  estimatedTripDurationMinutes: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
};

export type PassengerSegmentDataResponse = {
  __typename?: 'PassengerSegmentDataResponse';
  data?: Maybe<Scalars['SegmentData']['output']>;
  segmentEventName?: Maybe<Scalars['String']['output']>;
};

export type PassengerSegmentInput = {
  data?: InputMaybe<Scalars['SegmentData']['input']>;
  segmentEventName?: InputMaybe<Scalars['String']['input']>;
};

export type PassengerVehicle = {
  __typename?: 'PassengerVehicle';
  id: Scalars['ID']['output'];
  licensePlate: Scalars['String']['output'];
  make: Scalars['String']['output'];
  model: Scalars['String']['output'];
  passengerCapacity: Scalars['Int']['output'];
  wheelchairAccessible: Scalars['Boolean']['output'];
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  amount: Scalars['Int']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  declineReason?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lastFour: Scalars['String']['output'];
  paymentMethodId: Scalars['ID']['output'];
  refund?: Maybe<Refund>;
  status: Scalars['String']['output'];
  wallet?: Maybe<PaymentMethodWalletType>;
};

export enum PaymentMethodWalletType {
  apple_pay = 'apple_pay',
  google_pay = 'google_pay',
}

export type PhoneValidations = {
  __typename?: 'PhoneValidations';
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type PhoneVerification = {
  __typename?: 'PhoneVerification';
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type PhoneVerificationInput = {
  phoneNumber: Scalars['String']['input'];
};

export type PhoneVerificationSubmitInput = {
  key: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type PhoneVerificationsFilters = {
  context?: InputMaybe<SMSAuthAttemptUserContext>;
  renterId?: InputMaybe<Scalars['ID']['input']>;
};

export type PickupTimer = {
  __typename?: 'PickupTimer';
  noShowErrorDurationSeconds: Scalars['Int']['output'];
  noShowTriggerDurationSeconds: Scalars['Int']['output'];
  noShowWarnDurationSeconds: Scalars['Int']['output'];
  timeToAcknowledgeSeconds: Scalars['Int']['output'];
};

export type Point = {
  __typename?: 'Point';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type PointInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type PriceModification = {
  __typename?: 'PriceModification';
  amount: Scalars['Int']['output'];
  coupon?: Maybe<Coupon>;
  couponId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind?: Maybe<PriceModificationKind>;
  offerId: Scalars['String']['output'];
  renterDiscountRate?: Maybe<RenterDiscountRate>;
  renterDiscountRateId?: Maybe<Scalars['String']['output']>;
};

export enum PriceModificationKind {
  boost_incentive = 'boost_incentive',
  incentive = 'incentive',
  min_fare = 'min_fare',
  non_pax_pickup_time = 'non_pax_pickup_time',
  price_experiment = 'price_experiment',
  price_multiplier = 'price_multiplier',
  scheduled_ride_fee = 'scheduled_ride_fee',
  service_fee = 'service_fee',
  zone_pricing = 'zone_pricing',
}

export type ProblemReport = {
  __typename?: 'ProblemReport';
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Resolver fetched fields */
  fleetResource?: Maybe<FleetResource>;
  fleetResourceId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  photos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  rental?: Maybe<CXRental>;
  rentalId: Scalars['ID']['output'];
  renter?: Maybe<CXRenter>;
  renterId: Scalars['ID']['output'];
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Maybe<TagCX>>>;
  tasks: Array<Task>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type ProblemReporttasksArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type ProblemReportsFilters = {
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum PushTokenTypes {
  apn = 'apn',
  expo = 'expo',
  fcm = 'fcm',
}

export type Query = {
  __typename?: 'Query';
  calculateDriverVehicleCreditAmount: DriverCreditAmountResponse;
  driverPayment?: Maybe<DriverPayment>;
  driverPayments?: Maybe<Array<DriverPayment>>;
  getAboundToken?: Maybe<AboundToken>;
  getActiveRentals?: Maybe<ActiveCXRentalsList>;
  getAssignment: Assignment;
  getAssignmentOperatorLocation?: Maybe<AssignmentOperatorLocation>;
  getAssignmentOperatorLocations?: Maybe<Array<Maybe<AssignmentOperatorLocation>>>;
  getAssignmentResources: Array<FleetResource>;
  /** @deprecated temp deprecation for deployment */
  getAssignments: Array<Assignment>;
  getAssignmentsV2: AssignmentList;
  getBalancerMap: Scalars['JSON']['output'];
  getCXAgent?: Maybe<CXAgent>;
  getCXAgents: Array<CXAgent>;
  getCXCreditHistory?: Maybe<Array<CXCreditBalance>>;
  getCXDriverShifts?: Maybe<Array<CXShift>>;
  getCXFleetResource?: Maybe<FleetResource>;
  getCXFleetResources?: Maybe<Array<Maybe<FleetResource>>>;
  getCXHub: Hub;
  getCXHubs?: Maybe<Array<Hub>>;
  getCXInvoicePDF: Scalars['String']['output'];
  getCXInvoiceReasons: Array<CXInvoiceReason>;
  getCXMobileVersions?: Maybe<CXMobileVersions>;
  getCXPricingBySystem?: Maybe<SystemPricing>;
  getCXProblemReport?: Maybe<ProblemReport>;
  getCXProblemReports?: Maybe<Array<Maybe<ProblemReport>>>;
  getCXRental?: Maybe<CXRental>;
  getCXRentalAccountValidationQueueStats: ModeratorSelfieQueueStats;
  getCXRentalSelfie?: Maybe<ModeratorRentalSelfie>;
  getCXRentalSelfieQueueStats?: Maybe<ModeratorSelfieQueueStats>;
  getCXRentals?: Maybe<RentalsList>;
  getCXRenter?: Maybe<CXRenter>;
  getCXRenters?: Maybe<RentersList>;
  getCXRentersDiscountRateHistory?: Maybe<Array<CXRenterDiscountRate>>;
  getCXRentersEmailHistory?: Maybe<Array<CXRentersEmail>>;
  getCXRentersRentalPassHistory?: Maybe<Array<CXRenterRentalPass>>;
  getCXRideHail: CXRideHail;
  getCXRideHails: CXRideHailList;
  getCXSystem: System;
  getCXSystems: Array<System>;
  getCurrentDriver?: Maybe<Driver>;
  getCurrentDriverStopPoints?: Maybe<DriverStopPointsResponse>;
  getCurrentOperator?: Maybe<Operator>;
  getCurrentStopPoint: DriverStopPoint;
  getDepot?: Maybe<Depot>;
  getDepots: Array<Depot>;
  getDiscountRates?: Maybe<Array<DiscountRate>>;
  getDriver?: Maybe<Driver>;
  getDriverAppConfig: DriverAppConfig;
  getDriverAvailableCredit: Scalars['Int']['output'];
  getDriverContractConversionForm?: Maybe<DriverContractConversionForm>;
  getDriverFleetResource: DriverFleetResource;
  getDriverFleetResourceCount: Scalars['Int']['output'];
  getDriverFleetResourceDetails: DriverFleetResourceDetailsResponse;
  getDriverFleetResources: DriverFleetResourcesResponse;
  getDriverInsuranceApprovals: DriverInsuranceApprovalResponse;
  getDriverInvoice?: Maybe<DriverInvoice>;
  getDriverInvoices: DriverInvoicesResponse;
  getDriverOTPVerificationCode?: Maybe<DriverOTP>;
  /** @deprecated Use getDepot */
  getDriverOpsDepot?: Maybe<Depot>;
  /** @deprecated Use getDepots */
  getDriverOpsDepots?: Maybe<Array<Depot>>;
  getDriverOpsDriver?: Maybe<DriverOpsDriverResponse>;
  getDriverOpsDriverAndDetails: DriverOpsDriverListResponse;
  getDriverOpsDriverTimeAway: DriverOpsDriverTimeAwayResponse;
  /** @deprecated Use getDrivers */
  getDriverOpsDrivers?: Maybe<DriverOpsDriverListResponse>;
  /** @deprecated Use getDriverShifts */
  getDriverOpsShifts: DriverOpsDriverShiftsResponse;
  getDriverPaymentMethod?: Maybe<DriverPaymentMethod>;
  getDriverPaymentMethods: Array<DriverPaymentMethod>;
  getDriverRestrictionStatusContent?: Maybe<DriverRestrictionsStatusContent>;
  getDriverShiftDevice?: Maybe<DriverShiftDevice>;
  /** @deprecated Use getMyShifts instead */
  getDriverShifts: DriverShiftsResponse;
  getDriverSystemZones?: Maybe<Array<DriverSystemZone>>;
  getDriverTimeAway?: Maybe<Array<Maybe<DriverTimeAway>>>;
  getDriverVehicleExclusions: Array<Maybe<DriverVehicleExclusionMakeAndModel>>;
  getDriverVehiclePreference?: Maybe<DriverVehiclePreferenceResponse>;
  getDriverW9Form: Scalars['String']['output'];
  getDrivers: DriversResponse;
  getFlag: Flag;
  getFlagTypes?: Maybe<Array<FlagType>>;
  getFlags?: Maybe<Array<Flag>>;
  getFleetResource?: Maybe<FleetResource>;
  getFleetResourceMetadata?: Maybe<FleetResourceMetadata>;
  getFleetResourceMetadataFromFleetioId?: Maybe<FleetResourceMetadataResponse>;
  getFleetResourceOpenWorkOrder?: Maybe<WorkOrder>;
  getFleetResourceServiceLog?: Maybe<Array<ServiceLog>>;
  getFleetResourceSessions?: Maybe<Array<FleetResourceSession>>;
  getFleetResources: Array<FleetResource>;
  getFleetResourcesStats: FleetResourcesStats;
  getIdentityRoles: Array<Maybe<RoleMember>>;
  getInsuranceProviders?: Maybe<InsuranceProviderResponse>;
  getIssue?: Maybe<Issue>;
  getIssues?: Maybe<IssueList>;
  getLatestFleetResourceServiceLog?: Maybe<ServiceLog>;
  getLatestRentalTou: LegalDocument;
  getLatestReservationContract: LegalDocument;
  getLocation: Location;
  getLocationGroup?: Maybe<LocationGroup>;
  getLocationGroupAction: LocationGroupAction;
  getLocationGroupResources?: Maybe<Array<Maybe<FleetResource>>>;
  getLocationGroups?: Maybe<Array<Maybe<LocationGroup>>>;
  getLocations?: Maybe<Array<Maybe<Location>>>;
  getMostRecentNonDriverScheduledHours: MostRecentNonDriverScheduledHours;
  getMyShift?: Maybe<DriverShift>;
  getMyShifts: DriverShiftsResponse;
  getMyVehiclePreference?: Maybe<DriverVehiclePreferenceResponse>;
  getOperator?: Maybe<Operator>;
  getOperatorLocation?: Maybe<OperatorLocation>;
  getOperatorLocations?: Maybe<Array<Maybe<OperatorLocation>>>;
  getOperatorReservations: Array<Reservation>;
  getOperators: Array<Maybe<Operator>>;
  getOpsHub: Hub;
  getOpsHubResources?: Maybe<Array<HubResource>>;
  getOpsHubs?: Maybe<Array<Hub>>;
  getOpsServiceArea: ServiceArea;
  getOpsServiceAreas: ServiceAreaList;
  getOpsSystem: System;
  getOpsSystemServiceAreaHistory: SystemServiceAreaHistoryList;
  getOpsSystemZones: Array<OpsSystemZone>;
  getOpsSystems: Array<System>;
  getPassengerAppAccounts: Array<PassengerAppAccount>;
  getPhoneVerifications?: Maybe<Array<PhoneVerification>>;
  getRecurringShift?: Maybe<RecurringShift>;
  getRemainingUnscheduledBreaks: RemainingUnscheduledBreaks;
  getRentalReservation?: Maybe<RentalReservation>;
  getRenterIdVerificationLink?: Maybe<CXIdVerificationLink>;
  getRenterIdVerifications?: Maybe<Array<CXIdVerification>>;
  getRenterInvoices?: Maybe<CXInvoicesList>;
  getRentersFieldsToValidate?: Maybe<RentersFieldsToValidate>;
  getRentersIneligibilities?: Maybe<Array<Ineligibility>>;
  getRentersViolations?: Maybe<Array<RenterViolation>>;
  getRepairGroups: Array<RepairGroup>;
  getRepairs: Array<Repair>;
  getReservation?: Maybe<Reservation>;
  getReservationBillingSettings?: Maybe<ReservationBillingSettingsResponse>;
  getReservationRentalContract?: Maybe<ReservationContract>;
  getReservations?: Maybe<Array<Maybe<Reservation>>>;
  getResourceRidehailVehicle?: Maybe<CXVehicle>;
  getResourceTimeline?: Maybe<ResourceTimelineResponse>;
  getRole?: Maybe<Role>;
  getShift?: Maybe<DriverShift>;
  getShiftCancellationReasons?: Maybe<Array<ShiftCancellationReason>>;
  getShiftIssue?: Maybe<ShiftIssue>;
  getShiftResourceMetadata?: Maybe<ShiftResourceMetadataResponse>;
  getShiftStopPoints: ShiftStopPointResponse;
  getShifts: DriverShiftsResponse;
  getSignedDocuments: SignedDocuments;
  getSystem?: Maybe<System>;
  getSystemMapResources: SystemMapResourcesList;
  getSystemMapStats: SystemMapStats;
  getSystemServiceAreaGeojson?: Maybe<MultiPolygon>;
  getSystemServiceAreaGeojsonString?: Maybe<Scalars['String']['output']>;
  getSystemServiceAreaInverseGeojson?: Maybe<MultiPolygon>;
  getSystemServiceAreaInverseGeojsonString?: Maybe<Scalars['String']['output']>;
  getSystems: Array<System>;
  getTask?: Maybe<Task>;
  getTaskCategories?: Maybe<Array<TaskCategory>>;
  getTaskComment?: Maybe<TaskComment>;
  getTaskComments?: Maybe<Array<TaskComment>>;
  getTaskFilter: TaskFilter;
  getTaskFilteredFleetResources: Array<FleetResource>;
  getTaskFilters?: Maybe<Array<TaskFilter>>;
  getTaskType?: Maybe<TaskType>;
  getTaskTypes?: Maybe<Array<Maybe<TaskType>>>;
  getTasks?: Maybe<Array<Task>>;
  getTrafficViolationTicket?: Maybe<TrafficViolationTicket>;
  getUsersCXOrderSubscriptions: Array<CXOrderSubscriptions>;
  getVehicleFacilities: VehicleFacilityResponse;
  getVehicleFacility: VehicleFacility;
  getViolations?: Maybe<Array<Violation>>;
  getWorkOrder?: Maybe<WorkOrder>;
  getWorkOrderTasks: Array<WorkOrderTask>;
  getWorkOrders: Array<WorkOrder>;
  getZone?: Maybe<Zone>;
  getZones?: Maybe<ZoneList>;
  isDeviceInRevelCar: IsInCar;
  listAmenities: AmenityResponse;
  listDriverAddresses: DriverAddressResponse;
  listDriverRestrictionReasons?: Maybe<DriverRestrictionReasonResponse>;
  listLicenses: LicenseResponse;
  listOpenShiftTemplates?: Maybe<Array<OpenShiftTemplate>>;
  listOpenShifts?: Maybe<Array<OpenShift>>;
  listRecurringShifts?: Maybe<Array<RecurringShift>>;
  listRentalReservations: RentalReservationsResponse;
  listTrafficViolationTickets: ListTrafficViolationTicketResponse;
  passengerGetResource?: Maybe<FleetResource>;
  rideHail: DriverRideHail;
  rideHails?: Maybe<Array<DriverRideHail>>;
  validateSummonsNumber?: Maybe<ValidatedSummons>;
  verifyRentersIdentity?: Maybe<Scalars['Boolean']['output']>;
};

export type QuerycalculateDriverVehicleCreditAmountArgs = {
  minutes: Scalars['Int']['input'];
  vehicleType: DriverVehiclePreferenceType;
};

export type QuerydriverPaymentArgs = {
  paymentId: Scalars['ID']['input'];
};

export type QuerydriverPaymentsArgs = {
  filters: DriverPaymentFilters;
};

export type QuerygetAboundTokenArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetActiveRentalsArgs = {
  filters?: InputMaybe<ActiveRentalsFilters>;
};

export type QuerygetAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetAssignmentOperatorLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetAssignmentOperatorLocationsArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type QuerygetAssignmentResourcesArgs = {
  assignmentId: Scalars['ID']['input'];
  operatorLocation?: InputMaybe<PointInput>;
  withBlockedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  withLastServicedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetAssignmentsArgs = {
  filters?: InputMaybe<AssignmentFilters>;
};

export type QuerygetAssignmentsV2Args = {
  filters?: InputMaybe<AssignmentFilters>;
};

export type QuerygetCXAgentArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXAgentsArgs = {
  filters?: InputMaybe<CXAgentFilters>;
};

export type QuerygetCXCreditHistoryArgs = {
  renterId: Scalars['String']['input'];
};

export type QuerygetCXDriverShiftsArgs = {
  filters: CXDriverShiftFilters;
};

export type QuerygetCXFleetResourceArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXFleetResourcesArgs = {
  filters?: InputMaybe<ResourceFilters>;
};

export type QuerygetCXHubArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXHubsArgs = {
  systemId: Scalars['ID']['input'];
};

export type QuerygetCXInvoicePDFArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type QuerygetCXInvoiceReasonsArgs = {
  filters?: InputMaybe<CXInvoiceReasonsFilter>;
};

export type QuerygetCXPricingBySystemArgs = {
  systemId: Scalars['ID']['input'];
};

export type QuerygetCXProblemReportArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXProblemReportsArgs = {
  filters?: InputMaybe<ProblemReportsFilters>;
};

export type QuerygetCXRentalArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXRentalSelfieArgs = {
  rentalSelfieId: Scalars['ID']['input'];
};

export type QuerygetCXRentalsArgs = {
  filters?: InputMaybe<CXRentalsFilters>;
};

export type QuerygetCXRenterArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXRentersArgs = {
  filters?: InputMaybe<CXRentersFilters>;
};

export type QuerygetCXRentersDiscountRateHistoryArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetCXRentersEmailHistoryArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXRentersRentalPassHistoryArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetCXRideHailArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCXRideHailsArgs = {
  filters: CXRideHailFilters;
};

export type QuerygetCXSystemArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetCurrentStopPointArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetDepotArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetDepotsArgs = {
  filters?: InputMaybe<GetDepotsFilters>;
};

export type QuerygetDiscountRatesArgs = {
  discountRateProduct?: InputMaybe<DiscountRateProductType>;
};

export type QuerygetDriverArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetDriverAvailableCreditArgs = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetDriverContractConversionFormArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetDriverFleetResourceArgs = {
  fleetResourceId: Scalars['ID']['input'];
};

export type QuerygetDriverFleetResourceCountArgs = {
  filters?: InputMaybe<DriverFleetResourceCountFilters>;
};

export type QuerygetDriverFleetResourceDetailsArgs = {
  filters?: InputMaybe<DriverFleetResourceDetailFilters>;
};

export type QuerygetDriverFleetResourcesArgs = {
  filters: DriverFleetResourceFilters;
};

export type QuerygetDriverInsuranceApprovalsArgs = {
  filters?: InputMaybe<DriverInsuranceApprovalFilters>;
};

export type QuerygetDriverInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type QuerygetDriverInvoicesArgs = {
  filters?: InputMaybe<DriverInvoiceFilters>;
};

export type QuerygetDriverOTPVerificationCodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetDriverOpsDepotArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetDriverOpsDriverArgs = {
  driverId: Scalars['String']['input'];
};

export type QuerygetDriverOpsDriverAndDetailsArgs = {
  filters?: InputMaybe<GetDriverOpsDriverAndDetailsFilters>;
};

export type QuerygetDriverOpsDriverTimeAwayArgs = {
  filters: DriverOpsDriverTimeAwayFilters;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetDriverOpsDriversArgs = {
  filters?: InputMaybe<DriverOpsDriversFilters>;
};

export type QuerygetDriverOpsShiftsArgs = {
  filters?: InputMaybe<DriverOpsShiftFilters>;
};

export type QuerygetDriverPaymentMethodArgs = {
  paymentMethodId: Scalars['ID']['input'];
};

export type QuerygetDriverPaymentMethodsArgs = {
  filters?: InputMaybe<DriverPaymentMethodFilters>;
};

export type QuerygetDriverRestrictionStatusContentArgs = {
  filters?: InputMaybe<DriverRestrictionStatusContentFilters>;
};

export type QuerygetDriverShiftDeviceArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetDriverShiftsArgs = {
  filters?: InputMaybe<DriverShiftFilters>;
};

export type QuerygetDriverSystemZonesArgs = {
  filters?: InputMaybe<GetDriverSystemZonesFilters>;
  systemId: Scalars['ID']['input'];
};

export type QuerygetDriverTimeAwayArgs = {
  filters?: InputMaybe<DriverTimeAwayFilters>;
  id: Scalars['ID']['input'];
};

export type QuerygetDriverVehicleExclusionsArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetDriverVehiclePreferenceArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetDriverW9FormArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetDriversArgs = {
  filters?: InputMaybe<DriverFilters>;
};

export type QuerygetFlagArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetFlagsArgs = {
  filters: FlagFilters;
};

export type QuerygetFleetResourceArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetFleetResourceMetadataArgs = {
  resourceId: Scalars['String']['input'];
};

export type QuerygetFleetResourceMetadataFromFleetioIdArgs = {
  fleetioId: Scalars['Int']['input'];
};

export type QuerygetFleetResourceOpenWorkOrderArgs = {
  fleetResourceId: Scalars['ID']['input'];
};

export type QuerygetFleetResourceServiceLogArgs = {
  fleetResourceId: Scalars['String']['input'];
};

export type QuerygetFleetResourceSessionsArgs = {
  filters: FleetResourceSessionFilters;
};

export type QuerygetFleetResourcesArgs = {
  cacheBuster: Scalars['String']['input'];
  filters?: InputMaybe<ResourceFilters>;
};

export type QuerygetFleetResourcesStatsArgs = {
  filters?: InputMaybe<ResourceFilters>;
};

export type QuerygetIdentityRolesArgs = {
  identityId: Scalars['ID']['input'];
};

export type QuerygetIssueArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetIssuesArgs = {
  filters?: InputMaybe<IssueFilters>;
};

export type QuerygetLatestFleetResourceServiceLogArgs = {
  fleetResourceId: Scalars['String']['input'];
};

export type QuerygetLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetLocationGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetLocationGroupActionArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetLocationGroupResourcesArgs = {
  locationGroupId: Scalars['ID']['input'];
};

export type QuerygetLocationGroupsArgs = {
  filters?: InputMaybe<LocationGroupFilters>;
};

export type QuerygetLocationsArgs = {
  filters?: InputMaybe<LocationFilters>;
};

export type QuerygetMostRecentNonDriverScheduledHoursArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetMyShiftArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetMyShiftsArgs = {
  filters?: InputMaybe<DriverShiftFilters>;
};

export type QuerygetMyVehiclePreferenceArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetOperatorArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOperatorLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOperatorLocationsArgs = {
  filters?: InputMaybe<OperatorLocationFilters>;
};

export type QuerygetOperatorReservationsArgs = {
  filters: OperatorReservationsFilters;
};

export type QuerygetOperatorsArgs = {
  filters?: InputMaybe<OperatorFilters>;
};

export type QuerygetOpsHubArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOpsHubResourcesArgs = {
  filters?: InputMaybe<OpsHubResourceFilters>;
  hubId: Scalars['ID']['input'];
};

export type QuerygetOpsHubsArgs = {
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetOpsServiceAreaArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOpsServiceAreasArgs = {
  filters?: InputMaybe<OpsServiceAreaFilters>;
};

export type QuerygetOpsSystemArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetOpsSystemServiceAreaHistoryArgs = {
  filters?: InputMaybe<OpsSystemServiceAreaHistoryFilters>;
  id: Scalars['ID']['input'];
};

export type QuerygetOpsSystemZonesArgs = {
  input?: InputMaybe<OpsSystemZoneFilters>;
};

export type QuerygetPassengerAppAccountsArgs = {
  filters?: InputMaybe<PassengerAppAccountFilters>;
};

export type QuerygetPhoneVerificationsArgs = {
  filters?: InputMaybe<PhoneVerificationsFilters>;
};

export type QuerygetRecurringShiftArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetRemainingUnscheduledBreaksArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetRentalReservationArgs = {
  reservationId: Scalars['ID']['input'];
};

export type QuerygetRenterIdVerificationLinkArgs = {
  renterId: Scalars['String']['input'];
};

export type QuerygetRenterIdVerificationsArgs = {
  renterId: Scalars['String']['input'];
};

export type QuerygetRenterInvoicesArgs = {
  filters?: InputMaybe<InvoiceFilters>;
};

export type QuerygetRentersFieldsToValidateArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetRentersIneligibilitiesArgs = {
  input: RenterIneligibilitesInput;
};

export type QuerygetRentersViolationsArgs = {
  input: RentersViolationsInput;
};

export type QuerygetRepairsArgs = {
  filters?: InputMaybe<RepairFilters>;
};

export type QuerygetReservationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetReservationBillingSettingsArgs = {
  driverId: Scalars['ID']['input'];
};

export type QuerygetReservationRentalContractArgs = {
  reservationId: Scalars['ID']['input'];
};

export type QuerygetReservationsArgs = {
  fleetResourceId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetResourceRidehailVehicleArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetResourceTimelineArgs = {
  filters: ResourceTimelineFilters;
};

export type QuerygetRoleArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetShiftArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetShiftIssueArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetShiftResourceMetadataArgs = {
  shiftId: Scalars['ID']['input'];
};

export type QuerygetShiftStopPointsArgs = {
  filters?: InputMaybe<ShiftStopPointsFilter>;
  shiftId: Scalars['ID']['input'];
};

export type QuerygetShiftsArgs = {
  filters?: InputMaybe<DriverShiftFilters>;
};

export type QuerygetSignedDocumentsArgs = {
  filters: SignedDocumentsFilters;
};

export type QuerygetSystemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  systemIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetSystemMapResourcesArgs = {
  filters?: InputMaybe<SystemMapResourceFilters>;
  id: Scalars['ID']['input'];
};

export type QuerygetSystemMapStatsArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemServiceAreaGeojsonArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemServiceAreaGeojsonStringArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemServiceAreaInverseGeojsonArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemServiceAreaInverseGeojsonStringArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetSystemsArgs = {
  systemIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetTaskArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetTaskCommentArgs = {
  taskCommentId: Scalars['ID']['input'];
};

export type QuerygetTaskCommentsArgs = {
  taskId: Scalars['ID']['input'];
};

export type QuerygetTaskFilterArgs = {
  taskFilterId: Scalars['ID']['input'];
};

export type QuerygetTaskFilteredFleetResourcesArgs = {
  system?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taskTypeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unblock?: InputMaybe<Scalars['Boolean']['input']>;
  withBlockedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  withLastServicedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetTaskTypeArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetTaskTypesArgs = {
  parentTaskCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetTasksArgs = {
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetTrafficViolationTicketArgs = {
  ticketId: Scalars['ID']['input'];
};

export type QuerygetUsersCXOrderSubscriptionsArgs = {
  renterId: Scalars['ID']['input'];
};

export type QuerygetVehicleFacilityArgs = {
  vehicleFacilityId: Scalars['ID']['input'];
};

export type QuerygetWorkOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetWorkOrderTasksArgs = {
  workOrderId: Scalars['ID']['input'];
};

export type QuerygetWorkOrdersArgs = {
  assignmentId: Scalars['ID']['input'];
  filters?: InputMaybe<WorkOrderFilters>;
};

export type QuerygetZoneArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetZonesArgs = {
  filters?: InputMaybe<ZoneFilters>;
};

export type QueryisDeviceInRevelCarArgs = {
  deviceName: Scalars['String']['input'];
};

export type QuerylistAmenitiesArgs = {
  filters?: InputMaybe<AmenityFilters>;
};

export type QuerylistDriverAddressesArgs = {
  filters?: InputMaybe<DriverAddressFilters>;
};

export type QuerylistDriverRestrictionReasonsArgs = {
  filters?: InputMaybe<DriverRestrictionReasonFilters>;
};

export type QuerylistLicensesArgs = {
  filters?: InputMaybe<LicenseFilters>;
};

export type QuerylistOpenShiftTemplatesArgs = {
  filters?: InputMaybe<ListOpenShiftTemplateFilters>;
};

export type QuerylistOpenShiftsArgs = {
  filters?: InputMaybe<ListOpenShiftFilters>;
};

export type QuerylistRecurringShiftsArgs = {
  filters?: InputMaybe<ListRecurringShiftsFilters>;
};

export type QuerylistRentalReservationsArgs = {
  filters?: InputMaybe<RentalReservationFilters>;
};

export type QuerylistTrafficViolationTicketsArgs = {
  filters?: InputMaybe<TrafficViolationTicketFilters>;
};

export type QueryrideHailArgs = {
  id: Scalars['ID']['input'];
};

export type QueryrideHailsArgs = {
  filters?: InputMaybe<DriverRideHailFilters>;
};

export type QueryvalidateSummonsNumberArgs = {
  input: ValidateSummonsInput;
};

export type QueryverifyRentersIdentityArgs = {
  input?: InputMaybe<RenterValidationFields>;
};

export type RatePassengerRideHailInput = {
  rating: RatingTypes;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export enum RatingTypes {
  negative = 'negative',
  no_rating = 'no_rating',
  positive = 'positive',
}

export type RawSensors = {
  __typename?: 'RawSensors';
  alarm?: Maybe<Scalars['Boolean']['output']>;
  battery2Id?: Maybe<Scalars['String']['output']>;
  battery2Temp1?: Maybe<Scalars['Int']['output']>;
  battery2Temp2?: Maybe<Scalars['Int']['output']>;
  battery2Temp3?: Maybe<Scalars['Int']['output']>;
  battery2Temp4?: Maybe<Scalars['Int']['output']>;
  batteryId?: Maybe<Scalars['String']['output']>;
  batteryLevel: Scalars['Float']['output'];
  batteryLevel1?: Maybe<Scalars['Int']['output']>;
  batteryLevel2?: Maybe<Scalars['Int']['output']>;
  batteryOn?: Maybe<Scalars['Boolean']['output']>;
  batteryTemp1?: Maybe<Scalars['Int']['output']>;
  batteryTemp2?: Maybe<Scalars['Int']['output']>;
  batteryTemp3?: Maybe<Scalars['Int']['output']>;
  batteryTemp4?: Maybe<Scalars['Int']['output']>;
  batteryVoltage?: Maybe<Scalars['Int']['output']>;
  climateState?: Maybe<ClimateSensors>;
  dashboardBatteryLevel?: Maybe<Scalars['Int']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  ecuBatteryLevel?: Maybe<Scalars['Int']['output']>;
  ecuSoftVer?: Maybe<Scalars['String']['output']>;
  eid?: Maybe<Scalars['String']['output']>;
  enable485cmd?: Maybe<Scalars['Boolean']['output']>;
  estimateMileage?: Maybe<Scalars['Int']['output']>;
  fallState?: Maybe<Scalars['Boolean']['output']>;
  fotaDownloadPercent?: Maybe<Scalars['Int']['output']>;
  gpsSignal?: Maybe<Scalars['String']['output']>;
  gsmSignal?: Maybe<Scalars['Int']['output']>;
  handlebarOn?: Maybe<Scalars['Boolean']['output']>;
  iccid?: Maybe<Scalars['String']['output']>;
  ignitionOn?: Maybe<Scalars['Boolean']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  locating?: Maybe<Scalars['Boolean']['output']>;
  lockcontrolBatteryLevel?: Maybe<Scalars['Int']['output']>;
  lockcontrolSoftVer?: Maybe<Scalars['String']['output']>;
  onlineState?: Maybe<Scalars['Boolean']['output']>;
  positionAccuracy?: Maybe<Scalars['Float']['output']>;
  readyOn?: Maybe<Scalars['Boolean']['output']>;
  saddleOn?: Maybe<Scalars['Boolean']['output']>;
  secureOn?: Maybe<Scalars['Boolean']['output']>;
  serverUpdateTime?: Maybe<Scalars['String']['output']>;
  speed?: Maybe<Scalars['Float']['output']>;
  tailboxLidOn?: Maybe<Scalars['Boolean']['output']>;
  tailboxOn?: Maybe<Scalars['Boolean']['output']>;
  totalMileage?: Maybe<Scalars['Int']['output']>;
  updateTime?: Maybe<Scalars['String']['output']>;
  vehicleState?: Maybe<VehicleStateSensors>;
  vibrationState?: Maybe<Scalars['Boolean']['output']>;
};

export type RecurringShift = {
  __typename?: 'RecurringShift';
  beginGeneratingAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  depot?: Maybe<Depot>;
  depotId?: Maybe<Scalars['ID']['output']>;
  driver: Driver;
  endTime: Scalars['String']['output'];
  endWeekday: Scalars['Int']['output'];
  estimatedPay: Scalars['Int']['output'];
  generatedUntil?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  settings?: Maybe<ShiftSettings>;
  startTime: Scalars['String']['output'];
  startWeekday: Scalars['Int']['output'];
  systemId: Scalars['ID']['output'];
};

export type Refund = {
  __typename?: 'Refund';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<RefundReasons>;
  status?: Maybe<RefundStatus>;
};

export type RefundInvoiceInput = {
  amount: Scalars['Int']['input'];
  invoiceId: Scalars['ID']['input'];
  reason?: InputMaybe<RefundReasons>;
};

export enum RefundReasons {
  /** @deprecated no longer used by cx */
  driver_issue = 'driver_issue',
  employee_discount_didnt_apply = 'employee_discount_didnt_apply',
  forgot_to_end_ride = 'forgot_to_end_ride',
  fraudulent_charge = 'fraudulent_charge',
  misplaced_ticket_or_tow_charge = 'misplaced_ticket_or_tow_charge',
  moped_gps_issue = 'moped_gps_issue',
  no_longer_needed_ridepass = 'no_longer_needed_ridepass',
  payment_processing_issue = 'payment_processing_issue',
  rideshare_accident = 'rideshare_accident',
  rideshare_driver_complaint = 'rideshare_driver_complaint',
  rideshare_driver_no_show = 'rideshare_driver_no_show',
  /** @deprecated no longer used by cx */
  rideshare_issue = 'rideshare_issue',
  rideshare_lost_item_issue = 'rideshare_lost_item_issue',
  rideshare_navigation_issue = 'rideshare_navigation_issue',
  rideshare_user_did_not_ride = 'rideshare_user_did_not_ride',
  rideshare_vehicle_issue = 'rideshare_vehicle_issue',
  rideshare_wait_time_fee = 'rideshare_wait_time_fee',
  thought_they_had_a_ride_pass = 'thought_they_had_a_ride_pass',
  throttle_not_responsive = 'throttle_not_responsive',
  trouble_starting_ride = 'trouble_starting_ride',
  updated_payment_method = 'updated_payment_method',
}

export enum RefundStatus {
  failed = 'failed',
  pending = 'pending',
  succeeded = 'succeeded',
}

export type RemainingUnscheduledBreaks = {
  __typename?: 'RemainingUnscheduledBreaks';
  availableBreaks?: Maybe<Scalars['Int']['output']>;
};

export type RemoveRenterDiscountRateInput = {
  renterDiscountRateId: Scalars['ID']['input'];
  renterId: Scalars['ID']['input'];
};

export type RemoveRenterPhoneNumberInput = {
  renterId: Scalars['ID']['input'];
};

export type RemoveRenterViolationInput = {
  cxAgentId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  renterId: Scalars['ID']['input'];
  renterViolationId: Scalars['ID']['input'];
};

export type RentalAccountValidation = {
  __typename?: 'RentalAccountValidation';
  faceImages?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  rider1ImageUrl: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
};

export type RentalAccountValidationResultsInput = {
  rentalAccountValidationId: Scalars['ID']['input'];
  riderAndFaceImageMatch?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RentalReservation = {
  __typename?: 'RentalReservation';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  billingTimelineHead?: Maybe<RentalReservationBillingTimeline>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentIssueId?: Maybe<Scalars['ID']['output']>;
  currentResource?: Maybe<FleetResource>;
  currentResourceId?: Maybe<Scalars['ID']['output']>;
  depot?: Maybe<Depot>;
  driverId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<DriverInvoice>>;
  reservationType: RentalReservationTypes;
  scheduledEndAt: Scalars['DateTime']['output'];
  scheduledStartAt: Scalars['DateTime']['output'];
  settings?: Maybe<ReservationSettings>;
  state: RentalReservationStates;
  systemId: Scalars['ID']['output'];
  timeline?: Maybe<Array<RentalReservationBillingTimeline>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RentalReservationinvoicesArgs = {
  filters?: InputMaybe<DriverInvoiceFilters>;
};

export type RentalReservationBillingTimeline = {
  __typename?: 'RentalReservationBillingTimeline';
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isBillable?: Maybe<Scalars['Boolean']['output']>;
  issueId?: Maybe<Scalars['ID']['output']>;
  reservationId: Scalars['ID']['output'];
  resource?: Maybe<FleetResource>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  startedAt: Scalars['DateTime']['output'];
  state: RentalReservationStates;
  updatedAt: Scalars['DateTime']['output'];
};

export type RentalReservationFilters = {
  actualEndAt__gte?: InputMaybe<Scalars['String']['input']>;
  actualEndAt__lte?: InputMaybe<Scalars['String']['input']>;
  actualStartAt__gte?: InputMaybe<Scalars['String']['input']>;
  actualStartAt__lte?: InputMaybe<Scalars['String']['input']>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  driverId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  reservationType?: InputMaybe<Array<RentalReservationTypes>>;
  scheduledEndAt__gte?: InputMaybe<Scalars['String']['input']>;
  scheduledEndAt__lte?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAt__gte?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAt__lte?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Array<RentalReservationStates>>;
};

export enum RentalReservationStates {
  active = 'active',
  canceled = 'canceled',
  complete = 'complete',
  scheduled = 'scheduled',
  stand_by = 'stand_by',
}

export enum RentalReservationTypes {
  revel_rideshare = 'revel_rideshare',
  revel_w2 = 'revel_w2',
  uber_rideshare = 'uber_rideshare',
}

export type RentalReservationsResponse = {
  __typename?: 'RentalReservationsResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<RentalReservation>>;
};

export enum RentalSelfieState {
  awaiting_review = 'awaiting_review',
  classified = 'classified',
  pending = 'pending',
  processed = 'processed',
  reviewed = 'reviewed',
  reviewing = 'reviewing',
  skipped_review_due_to_distance = 'skipped_review_due_to_distance',
  uploaded = 'uploaded',
}

export enum RentalStatusEnum {
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
  ERROR = 'ERROR',
  PAUSED = 'PAUSED',
  PRE_RESERVED = 'PRE_RESERVED',
  RESERVED = 'RESERVED',
  RIDER_SAFETY = 'RIDER_SAFETY',
  active = 'active',
  ended = 'ended',
  error = 'error',
  paused = 'paused',
  pre_reserved = 'pre_reserved',
  reserved = 'reserved',
  rider_safety = 'rider_safety',
}

export enum RentalStatusErrorActionEnum {
  END = 'END',
  END_PAUSE = 'END_PAUSE',
  PAUSE = 'PAUSE',
  RIDER_SAFETY = 'RIDER_SAFETY',
  START = 'START',
}

export type RentalTimelineStatus = {
  __typename?: 'RentalTimelineStatus';
  actor?: Maybe<StatusActors>;
  description?: Maybe<Scalars['String']['output']>;
  /** Resolver fetched fields */
  endedByIdentity?: Maybe<Identity>;
  endedByIdentityId?: Maybe<Scalars['ID']['output']>;
  errorAction?: Maybe<RentalStatusErrorActionEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RentalStatusEnum>;
  time?: Maybe<Scalars['DateTime']['output']>;
  transitionStatus?: Maybe<TransitionRentalStatusEnum>;
  userLocation?: Maybe<Point>;
  vehicleAddress?: Maybe<Scalars['String']['output']>;
  vehicleLocation?: Maybe<Point>;
};

export type RentalsList = {
  __typename?: 'RentalsList';
  count: Scalars['Int']['output'];
  results: Array<CXRental>;
};

export type Renter = {
  __typename?: 'Renter';
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identity?: Maybe<Identity>;
  updated: Scalars['DateTime']['output'];
};

export enum RenterDeletionTypes {
  full_delete = 'full_delete',
  soft_delete = 'soft_delete',
}

export type RenterDiscountRate = {
  __typename?: 'RenterDiscountRate';
  discountRate: DiscountRate;
  id: Scalars['ID']['output'];
};

export type RenterIneligibilitesInput = {
  filters?: InputMaybe<IneligibilityFilters>;
  renterId: Scalars['ID']['input'];
};

export type RenterSuspensionAddInput = {
  description: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
};

export type RenterSuspensionEndInput = {
  description: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
  renterIneligibilityId: Scalars['ID']['input'];
};

export type RenterValidationFields = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  idNumber?: InputMaybe<Scalars['ID']['input']>;
  paymentmethodLastFour?: InputMaybe<Scalars['String']['input']>;
  renterId: Scalars['ID']['input'];
};

export type RenterViolation = {
  __typename?: 'RenterViolation';
  /** Resolver fetched fields */
  addedBy?: Maybe<Identity>;
  created?: Maybe<Scalars['DateTime']['output']>;
  cxAgentId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<RenterViolationImage>>;
  rentalId?: Maybe<Scalars['ID']['output']>;
  rideHailId?: Maybe<Scalars['ID']['output']>;
  summonsImageUrl?: Maybe<Scalars['String']['output']>;
  summonsNumber?: Maybe<Scalars['String']['output']>;
  suspensionRenterIneligibilityId?: Maybe<Scalars['ID']['output']>;
  violation: Violation;
};

export type RenterViolationImage = {
  __typename?: 'RenterViolationImage';
  imageS3Path?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<Scalars['String']['output']>;
};

export type RenterViolationImagePresignedUrls = {
  __typename?: 'RenterViolationImagePresignedUrls';
  parkingCameraViolationImageUrl?: Maybe<Scalars['String']['output']>;
  parkingTicketImageUrl?: Maybe<Scalars['String']['output']>;
  towTicketImageUrl?: Maybe<Scalars['String']['output']>;
};

export type Renters2FACheckResponse = {
  __typename?: 'Renters2FACheckResponse';
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Renters2FAVerificationCode = {
  __typename?: 'Renters2FAVerificationCode';
  verificationCode?: Maybe<Scalars['String']['output']>;
};

export type RentersFieldsToValidate = {
  __typename?: 'RentersFieldsToValidate';
  dateOfBirth?: Maybe<Scalars['Boolean']['output']>;
  idNumber?: Maybe<Scalars['Boolean']['output']>;
  paymentmethodLastFour?: Maybe<Scalars['Boolean']['output']>;
};

export type RentersList = {
  __typename?: 'RentersList';
  count: Scalars['Int']['output'];
  results: Array<CXRenter>;
};

export type RentersViolationsInput = {
  rentalId?: InputMaybe<Scalars['ID']['input']>;
  renterId: Scalars['ID']['input'];
  renterViolationId?: InputMaybe<Scalars['ID']['input']>;
  rideHailId?: InputMaybe<Scalars['ID']['input']>;
};

export type Repair = {
  __typename?: 'Repair';
  comment?: Maybe<Scalars['String']['output']>;
  completedBy: Operator;
  createdAt: Scalars['DateTime']['output'];
  fleetResource: FleetResource;
  fleetResourceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  repairType: RepairType;
  reservation?: Maybe<Reservation>;
  task?: Maybe<Task>;
  workOrder?: Maybe<WorkOrder>;
};

export type RepairFilters = {
  completedById?: InputMaybe<Scalars['ID']['input']>;
  fleetResourceId?: InputMaybe<Scalars['ID']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  repairTypeId?: InputMaybe<Scalars['ID']['input']>;
  reservationId?: InputMaybe<Scalars['ID']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type RepairGroup = {
  __typename?: 'RepairGroup';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  repairTypes: Array<RepairType>;
};

export type RepairType = {
  __typename?: 'RepairType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  performanceValue: Scalars['Float']['output'];
};

export type ReportDriverShiftIssueInput = {
  imageS3Paths?: InputMaybe<Array<Scalars['String']['input']>>;
  issueType: DriverShiftIssueTypes;
  reportComment?: InputMaybe<Scalars['String']['input']>;
  shiftId: Scalars['ID']['input'];
  startLocation?: InputMaybe<PointInput>;
};

export type RequestDriverDeleteInput = {
  deletedReasons?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResendBackgroundCheckInput = {
  driverId: Scalars['ID']['input'];
};

export type ResendDocusignDocumentsInput = {
  driverId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
};

export type Reservation = {
  __typename?: 'Reservation';
  end?: Maybe<Scalars['DateTime']['output']>;
  fleetResource: FleetResource;
  fleetResourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  operator: Operator;
  reason: Scalars['String']['output'];
  repairs: Array<Repair>;
  start: Scalars['DateTime']['output'];
};

export type ReservationBillingSettingsResponse = {
  __typename?: 'ReservationBillingSettingsResponse';
  ezPassRental: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  insurancePremiumTier: InsurancePremiumTiers;
};

export type ReservationContract = {
  __typename?: 'ReservationContract';
  acceptedAt: Scalars['DateTime']['output'];
  legalDocument: LegalDocument;
  reservationId: Scalars['ID']['output'];
};

export type ReservationSettings = {
  __typename?: 'ReservationSettings';
  preferredVehicleType?: Maybe<Scalars['String']['output']>;
};

export enum ReservationTypes {
  revel_rideshare = 'revel_rideshare',
  revel_w2 = 'revel_w2',
}

export enum ResourceCommand {
  generic_actuate_frunk = 'generic_actuate_frunk',
  generic_actuate_trunk = 'generic_actuate_trunk',
  generic_charge_port_open = 'generic_charge_port_open',
  generic_flash_lights = 'generic_flash_lights',
  generic_honk = 'generic_honk',
  generic_lock = 'generic_lock',
  generic_start = 'generic_start',
  generic_unlock = 'generic_unlock',
}

export type ResourceFilters = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  deployed?: InputMaybe<Scalars['Boolean']['input']>;
  division?: InputMaybe<Divisions>;
  driveOriginPoint?: InputMaybe<PointInput>;
  excludeResourceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  excludeTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hasBlockingTask?: InputMaybe<Scalars['Boolean']['input']>;
  inMaintenance?: InputMaybe<Scalars['Boolean']['input']>;
  inSession?: InputMaybe<Scalars['Boolean']['input']>;
  inUse?: InputMaybe<Scalars['Boolean']['input']>;
  isInHub?: InputMaybe<Scalars['Boolean']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxBatteryLevel?: InputMaybe<Scalars['Int']['input']>;
  nearPoint?: InputMaybe<PointInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unblock?: InputMaybe<Scalars['Boolean']['input']>;
  withBlockedStatus?: InputMaybe<Scalars['Boolean']['input']>;
  withGoogleDirections?: InputMaybe<Scalars['Boolean']['input']>;
  withLastServicedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ResourceMetadataTLCBase = {
  __typename?: 'ResourceMetadataTLCBase';
  name: Scalars['String']['output'];
  tlcBaseId: Scalars['String']['output'];
};

export type ResourceTimelineDetail = {
  __typename?: 'ResourceTimelineDetail';
  fleetResourceSession?: Maybe<FleetResourceSession>;
  locationAction?: Maybe<LocationAction>;
  reservation?: Maybe<Reservation>;
  workOrder?: Maybe<WorkOrder>;
};

export enum ResourceTimelineEventType {
  assignment = 'assignment',
  location = 'location',
  location_group_bulk_command = 'location_group_bulk_command',
  rental = 'rental',
  reservation = 'reservation',
}

export type ResourceTimelineFilters = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<ResourceTimelineEventType>;
  fleetResourceId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type ResourceTimelineItem = {
  __typename?: 'ResourceTimelineItem';
  detail?: Maybe<ResourceTimelineDetail>;
  eventTime: Scalars['DateTime']['output'];
  eventType: Scalars['String']['output'];
};

export type ResourceTimelineResponse = {
  __typename?: 'ResourceTimelineResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<ResourceTimelineItem>>;
};

export type RestrictionReason = {
  __typename?: 'RestrictionReason';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  platform: RestrictionReasonPlatforms;
  reason: RestrictionReasonNameKeys;
  resolutionPriority: Scalars['Int']['output'];
};

export enum RestrictionReasonNameKeys {
  background_check_required = 'background_check_required',
  branch_wallet_activation_required = 'branch_wallet_activation_required',
  dmv_license_approval_required = 'dmv_license_approval_required',
  driver_address_required = 'driver_address_required',
  driver_revoked = 'driver_revoked',
  insurance_approval_required = 'insurance_approval_required',
  outstanding_invoice_balance_above_threshold = 'outstanding_invoice_balance_above_threshold',
  payment_method_required = 'payment_method_required',
  rental_terms_of_use_required = 'rental_terms_of_use_required',
  ridehail_agreement_document_signatures_required = 'ridehail_agreement_document_signatures_required',
  tax_verification_required = 'tax_verification_required',
  terms_of_use_signature_required = 'terms_of_use_signature_required',
  tlc_compliance_required = 'tlc_compliance_required',
  vehicle_security_deposit_required = 'vehicle_security_deposit_required',
}

export enum RestrictionReasonPlatforms {
  rental = 'rental',
  rideshare = 'rideshare',
}

export enum RevelViolationCodes {
  moving = 'moving',
  operations = 'operations',
  parking = 'parking',
}

export type ReviewLicenseInput = {
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  status: ReviewLicenseStatuses;
};

export enum ReviewLicenseStatuses {
  approved = 'approved',
  rejected = 'rejected',
}

export type RideHailOfferTollFee = {
  __typename?: 'RideHailOfferTollFee';
  amount: Scalars['Int']['output'];
  tollFee: TollFee;
};

export type RideHailTimeline = {
  __typename?: 'RideHailTimeline';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Point>;
  metadata?: Maybe<RideHailTimelineMetadata>;
  previousAddress?: Maybe<Scalars['String']['output']>;
  state?: Maybe<CXRideHailStates>;
  stopPoint?: Maybe<CXStopPoint>;
};

export type RideHailTimelineMetadata = {
  __typename?: 'RideHailTimelineMetadata';
  rideHailOfferId?: Maybe<Scalars['ID']['output']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RoleMember = {
  __typename?: 'RoleMember';
  id: Scalars['ID']['output'];
  identity: Identity;
  name: Scalars['String']['output'];
  role: Role;
  system?: Maybe<System>;
  systemId?: Maybe<Scalars['ID']['output']>;
};

export enum SMSAuthAttemptUserContext {
  login = 'login',
  signup = 'signup',
}

export type ScheduledBreakTimer = {
  __typename?: 'ScheduledBreakTimer';
  deferredBreakTimeAllowedSeconds: Scalars['Int']['output'];
  totalDurationSeconds: Scalars['Int']['output'];
  warnDurationSeconds: Scalars['Int']['output'];
};

export type ScheduledPickupTimer = {
  __typename?: 'ScheduledPickupTimer';
  noShowErrorDurationSeconds: Scalars['Int']['output'];
  noShowTriggerDurationSeconds: Scalars['Int']['output'];
  noShowWarnDurationSeconds: Scalars['Int']['output'];
};

export type Sensors = {
  __typename?: 'Sensors';
  data: RawSensors;
  location: Point;
  updateTime?: Maybe<Scalars['DateTime']['output']>;
  updated: Scalars['DateTime']['output'];
};

export type ServiceArea = {
  __typename?: 'ServiceArea';
  created: Scalars['DateTime']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  product: ServiceAreaProduct;
  serviceAreaGeojson?: Maybe<MultiPolygon>;
  serviceAreaInvertedGeojson?: Maybe<MultiPolygon>;
  system: System;
};

export type ServiceAreaList = {
  __typename?: 'ServiceAreaList';
  count: Scalars['Int']['output'];
  results: Array<ServiceArea>;
};

export enum ServiceAreaProduct {
  rental = 'rental',
  ride_hail = 'ride_hail',
}

export enum ServiceContext {
  assignment = 'assignment',
  location_group_bulk_command = 'location_group_bulk_command',
  rental = 'rental',
  reservation = 'reservation',
}

export type ServiceLog = {
  __typename?: 'ServiceLog';
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  fleetResourceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  odometerAtService: Scalars['Int']['output'];
  servicedAt: Scalars['DateTime']['output'];
  servicedBy: Identity;
  servicedByIdentityId: Scalars['String']['output'];
};

export type SetRenterDiscountRateInput = {
  discountRateId: Scalars['ID']['input'];
  renterId: Scalars['ID']['input'];
};

export type ShiftBreak = {
  __typename?: 'ShiftBreak';
  actualEndAt?: Maybe<Scalars['DateTime']['output']>;
  actualStartAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  scheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<ShiftBreakTypes>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ShiftBreakTypes {
  scheduled = 'scheduled',
  unscheduled = 'unscheduled',
}

export type ShiftCancellation = {
  __typename?: 'ShiftCancellation';
  canceledByContext?: Maybe<Scalars['String']['output']>;
  canceledById?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dayforceCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  dayforceManuallyEnteredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  shiftCancellationReason: ShiftCancellationReason;
  shortNote?: Maybe<Scalars['String']['output']>;
  xrefCode?: Maybe<Scalars['String']['output']>;
};

export type ShiftCancellationReason = {
  __typename?: 'ShiftCancellationReason';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
  warningText?: Maybe<Scalars['String']['output']>;
};

export type ShiftExemptionInput = {
  attendancePercentage?: InputMaybe<Scalars['Boolean']['input']>;
  avgPickupDelaySeconds?: InputMaybe<Scalars['Boolean']['input']>;
  driverAvailabilityPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  endOfShiftRecallDelaySeconds?: InputMaybe<Scalars['Boolean']['input']>;
  missedRideCount?: InputMaybe<Scalars['Boolean']['input']>;
  negativeRatingCount?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShiftIssue = {
  __typename?: 'ShiftIssue';
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']['output']>;
  endLocation?: Maybe<Point>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  fleetResourceId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  imageUrls?: Maybe<Array<Scalars['String']['output']>>;
  issueType: DriverShiftIssueTypes;
  reportComment?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<Scalars['String']['output']>;
  shift?: Maybe<DriverShift>;
  shiftId?: Maybe<Scalars['ID']['output']>;
  startLocation?: Maybe<Point>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ShiftNote = {
  __typename?: 'ShiftNote';
  createdAt: Scalars['DateTime']['output'];
  createdByIdentityId: Scalars['ID']['output'];
  note: Scalars['String']['output'];
};

export type ShiftResourceMetadata = {
  __typename?: 'ShiftResourceMetadata';
  batteryEnd?: Maybe<Scalars['Float']['output']>;
  batteryStart?: Maybe<Scalars['Float']['output']>;
  driverReachedVehicleAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  licensePlate?: Maybe<Scalars['String']['output']>;
  locationEnd?: Maybe<Point>;
  locationStart?: Maybe<Point>;
  netradyneEndSentAt?: Maybe<Scalars['DateTime']['output']>;
  netradyneStartSentAt?: Maybe<Scalars['DateTime']['output']>;
  odometerMetersEnd?: Maybe<Scalars['Int']['output']>;
  odometerMetersStart?: Maybe<Scalars['Int']['output']>;
  resourceAssignedAt?: Maybe<Scalars['DateTime']['output']>;
  resourceId: Scalars['ID']['output'];
  resourceRemovedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ShiftResourceMetadataResponse = {
  __typename?: 'ShiftResourceMetadataResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<ShiftResourceMetadata>>;
};

export type ShiftSettings = {
  __typename?: 'ShiftSettings';
  appVersion?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<DeviceTypes>;
  dynamicRecallExtensionEnabled?: Maybe<Scalars['Boolean']['output']>;
  dynamicRecallReductionEnabled?: Maybe<Scalars['Boolean']['output']>;
  exemptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneType?: Maybe<Scalars['String']['output']>;
};

export type ShiftSettingsInput = {
  dynamicRecallExtensionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dynamicRecallReductionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  optionalRecallExtensionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShiftStats = {
  __typename?: 'ShiftStats';
  attendancePercentage?: Maybe<Scalars['Float']['output']>;
  attendancePercentageThreshold?: Maybe<Scalars['Float']['output']>;
  averageDriverRating?: Maybe<Scalars['Float']['output']>;
  averageNumberOfSecondsToAckRides?: Maybe<Scalars['Int']['output']>;
  avgPickupDelaySeconds?: Maybe<Scalars['Int']['output']>;
  avgPickupDelaySecondsThreshold?: Maybe<Scalars['Int']['output']>;
  driverAvailabilityPercentage?: Maybe<Scalars['Float']['output']>;
  driverAvailabilityPercentageThreshold?: Maybe<Scalars['Float']['output']>;
  driverPerformanceStatus?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endOfShiftRecallDelaySeconds?: Maybe<Scalars['Int']['output']>;
  endOfShiftRecallDelaySecondsThreshold?: Maybe<Scalars['Int']['output']>;
  exemptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  missedRideCount?: Maybe<Scalars['Int']['output']>;
  missedRideCountThreshold?: Maybe<Scalars['Int']['output']>;
  negativeRatingCount?: Maybe<Scalars['Int']['output']>;
  negativeRatingCountThreshold?: Maybe<Scalars['Int']['output']>;
  numberOfCompletedRides?: Maybe<Scalars['Int']['output']>;
  numberOfFailedToAckRides?: Maybe<Scalars['Int']['output']>;
  paidDuration?: Maybe<Scalars['Int']['output']>;
  percentageOfAvailability?: Maybe<Scalars['Float']['output']>;
  performancePayMultiplier?: Maybe<Scalars['Float']['output']>;
  performanceStatusThresholds?: Maybe<Scalars['JSON']['output']>;
  secondsInBreak?: Maybe<Scalars['Int']['output']>;
  secondsInIssue?: Maybe<Scalars['Int']['output']>;
  shiftPerformanceStatus?: Maybe<Scalars['Float']['output']>;
};

export type ShiftStopPointResponse = {
  __typename?: 'ShiftStopPointResponse';
  count: Scalars['Int']['output'];
  results?: Maybe<Array<DriverStopPoint>>;
};

export type ShiftStopPointsFilter = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ShiftUpdatedEvent = {
  __typename?: 'ShiftUpdatedEvent';
  eventType: ShiftUpdatedEventType;
  shift: DriverShift;
};

export type ShiftUpdatedEventInput = {
  eventTypes?: InputMaybe<Array<ShiftUpdatedEventType>>;
  shiftId?: InputMaybe<Scalars['ID']['input']>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ShiftUpdatedEventType {
  ISSUE_CREATED = 'ISSUE_CREATED',
  ISSUE_RESOLVED = 'ISSUE_RESOLVED',
  ISSUE_UPDATED = 'ISSUE_UPDATED',
  ROUTE_UPDATED = 'ROUTE_UPDATED',
  STATE_CHANGED = 'STATE_CHANGED',
  STOP_POINT_ADDED = 'STOP_POINT_ADDED',
  STOP_POINT_UPDATED = 'STOP_POINT_UPDATED',
}

export type SignedDocuments = {
  __typename?: 'SignedDocuments';
  signedUrls?: Maybe<Scalars['SignedUrls']['output']>;
};

export type SignedDocumentsFilters = {
  bucketName: Scalars['String']['input'];
  fileNames: Array<Scalars['String']['input']>;
};

export type SoftDeleteRenterInput = {
  reason: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
};

export type StaleVehicleLocationTimer = {
  __typename?: 'StaleVehicleLocationTimer';
  errorDurationSeconds: Scalars['Int']['output'];
  warnDurationSeconds: Scalars['Int']['output'];
};

export enum StatusActors {
  CRON = 'CRON',
  CX = 'CX',
  EXPIRED_FIRST_RIDE_CRON = 'EXPIRED_FIRST_RIDE_CRON',
  EXPIRED_PRE_RESERVATION_CRON = 'EXPIRED_PRE_RESERVATION_CRON',
  EXPIRED_RIDER_SAFETY_CRON = 'EXPIRED_RIDER_SAFETY_CRON',
  INACTIVE_RENTAL_CRON = 'INACTIVE_RENTAL_CRON',
  RENTER = 'RENTER',
}

export enum StopPointManipulationTypes {
  ARRIVE = 'ARRIVE',
  COMPLETE = 'COMPLETE',
  NO_SHOW = 'NO_SHOW',
  REMATCH = 'REMATCH',
}

export type StoppointMeta = {
  __typename?: 'StoppointMeta';
  canBeCanceled?: Maybe<Scalars['Boolean']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  displayNameShort?: Maybe<Scalars['String']['output']>;
  doneText?: Maybe<Scalars['String']['output']>;
  genericTypeId?: Maybe<Scalars['ID']['output']>;
  issueId?: Maybe<Scalars['ID']['output']>;
  issueType?: Maybe<DriverShiftIssueTypes>;
  remainingSnoozes?: Maybe<Scalars['Int']['output']>;
  scheduledEndAt?: Maybe<Scalars['DateTime']['output']>;
  shiftEnd?: Maybe<StoppointMetaShiftEnd>;
};

export type StoppointMetaShiftEnd = {
  __typename?: 'StoppointMetaShiftEnd';
  gracePeriodSecondsAfterStartedAt: Scalars['Int']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  identityLogin?: Maybe<Identity>;
  onFleetResourceEvent?: Maybe<FleetResourceEvent>;
  onParkingLotEvent?: Maybe<HubEvent>;
  onPassengerRidehailEvent: PassengerRideHailEvent;
  shiftUpdated?: Maybe<ShiftUpdatedEvent>;
};

export type SubscriptiononFleetResourceEventArgs = {
  input: OnFleetResourceEventInput;
};

export type SubscriptiononParkingLotEventArgs = {
  input: OnParkingLotEventInput;
};

export type SubscriptionshiftUpdatedArgs = {
  input?: InputMaybe<ShiftUpdatedEventInput>;
};

export type System = {
  __typename?: 'System';
  activeServiceAreaDetails?: Maybe<ServiceArea>;
  centerPoint: Point;
  currentServiceAreaId?: Maybe<Scalars['ID']['output']>;
  depots?: Maybe<Array<Depot>>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manualSystemClosures?: Maybe<Array<ManualSystemClosure>>;
  rentalProduct?: Maybe<SystemRentalProduct>;
  rideHailProduct?: Maybe<SystemRideHailProduct>;
  serviceArea?: Maybe<MultiPolygon>;
  serviceAreaDrafts: ServiceAreaList;
  serviceAreaHistory: SystemServiceAreaHistoryList;
  serviceAreaInverted?: Maybe<MultiPolygon>;
  serviceAreaInvertedString?: Maybe<Scalars['String']['output']>;
  serviceAreaLastUpdated?: Maybe<Scalars['DateTime']['output']>;
  serviceAreaString?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<SystemSettings>;
  status?: Maybe<Scalars['String']['output']>;
  systemIdentifier?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  zones: Array<Zone>;
};

export type SystemMapResource = {
  __typename?: 'SystemMapResource';
  batteryLevel: Scalars['Int']['output'];
  filters: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastSessionByOps?: Maybe<Scalars['DateTime']['output']>;
  lastUse?: Maybe<Scalars['DateTime']['output']>;
  lastUseByConsumer?: Maybe<Scalars['DateTime']['output']>;
  licensePlate: Scalars['String']['output'];
  location: Point;
};

export type SystemMapResourceFilters = {
  assignmentSession?: InputMaybe<Scalars['Boolean']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  consumerSession?: InputMaybe<Scalars['Boolean']['input']>;
  discharged?: InputMaybe<Scalars['Boolean']['input']>;
  dischargedSwappable?: InputMaybe<Scalars['Boolean']['input']>;
  inHub?: InputMaybe<Scalars['Boolean']['input']>;
  inLocationGroup?: InputMaybe<Scalars['Boolean']['input']>;
  reservationSession?: InputMaybe<Scalars['Boolean']['input']>;
  specialOps?: InputMaybe<Scalars['Boolean']['input']>;
  towToShop?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SystemMapResourcesList = {
  __typename?: 'SystemMapResourcesList';
  count: Scalars['Int']['output'];
  results: Array<SystemMapResource>;
};

export type SystemMapStats = {
  __typename?: 'SystemMapStats';
  assignmentSession: Scalars['Int']['output'];
  available: Scalars['Int']['output'];
  averageBatteryLevel: Scalars['Float']['output'];
  blocked: Scalars['Int']['output'];
  consumerSession: Scalars['Int']['output'];
  currentDailyRentalsCount: Scalars['Int']['output'];
  discharged: Scalars['Int']['output'];
  dischargedSwappable: Scalars['Int']['output'];
  rentalsTodayCount: Scalars['Int']['output'];
  reservationSession: Scalars['Int']['output'];
  specialOps: Scalars['Int']['output'];
  towToShop: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
};

export type SystemPricing = {
  __typename?: 'SystemPricing';
  currencyCode?: Maybe<Scalars['String']['output']>;
  firstPassengerFee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  rentalActiveRate?: Maybe<Scalars['Int']['output']>;
  rentalPausedRate?: Maybe<Scalars['Int']['output']>;
  secondPassengerFee?: Maybe<Scalars['Int']['output']>;
  signUpFee?: Maybe<Scalars['Int']['output']>;
  systemId: Scalars['String']['output'];
  taxPercentage?: Maybe<Scalars['String']['output']>;
};

export type SystemRentalProduct = {
  __typename?: 'SystemRentalProduct';
  closeTime?: Maybe<Scalars['Time']['output']>;
  manualClosureReason?: Maybe<Scalars['String']['output']>;
  openTime?: Maybe<Scalars['Time']['output']>;
  serviceArea?: Maybe<ServiceArea>;
  serviceAreaDrafts?: Maybe<ServiceAreaList>;
  serviceAreaHistory?: Maybe<SystemServiceAreaHistoryList>;
  systemId: Scalars['ID']['output'];
};

export type SystemRideHailProduct = {
  __typename?: 'SystemRideHailProduct';
  closeTime?: Maybe<Scalars['Time']['output']>;
  manualClosureReason?: Maybe<Scalars['String']['output']>;
  openTime?: Maybe<Scalars['Time']['output']>;
  serviceArea?: Maybe<ServiceArea>;
  serviceAreaDrafts?: Maybe<ServiceAreaList>;
  serviceAreaHistory?: Maybe<SystemServiceAreaHistoryList>;
  systemId: Scalars['ID']['output'];
};

export type SystemServiceAreaHistory = {
  __typename?: 'SystemServiceAreaHistory';
  createdAt: Scalars['DateTime']['output'];
  createdByIdentityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  serviceArea: ServiceArea;
};

export type SystemServiceAreaHistoryList = {
  __typename?: 'SystemServiceAreaHistoryList';
  count: Scalars['Int']['output'];
  results: Array<SystemServiceAreaHistory>;
};

export type SystemSettings = {
  __typename?: 'SystemSettings';
  lowBatteryTagPercentage?: Maybe<Scalars['Int']['output']>;
};

export type SystemZoneDraftsList = {
  __typename?: 'SystemZoneDraftsList';
  count?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<OpsSystemZoneDraft>>;
};

export type TagCX = {
  __typename?: 'TagCX';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Task = {
  __typename?: 'Task';
  assigned: Operator;
  closeReason?: Maybe<Scalars['String']['output']>;
  closedBy?: Maybe<Identity>;
  closedByIdentityId?: Maybe<Scalars['ID']['output']>;
  comments?: Maybe<Array<TaskComment>>;
  created?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Identity>;
  createdByIdentityId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fleetResourceId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  taskType: TaskType;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskCategory = {
  __typename?: 'TaskCategory';
  created?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  taskTypes?: Maybe<Array<TaskType>>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export enum TaskCloseReason {
  ISSUE_NOT_PRESENT = 'ISSUE_NOT_PRESENT',
  NEW_TASK_CREATED = 'NEW_TASK_CREATED',
  REPAIRED = 'REPAIRED',
  REPLACED = 'REPLACED',
}

export type TaskComment = {
  __typename?: 'TaskComment';
  comment: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identity: Identity;
  identityId: Scalars['ID']['output'];
  operator?: Maybe<Operator>;
  operatorId?: Maybe<Scalars['ID']['output']>;
  taskId: Scalars['ID']['output'];
};

export type TaskFilter = {
  __typename?: 'TaskFilter';
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  taskTypes?: Maybe<Array<TaskType>>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskType = {
  __typename?: 'TaskType';
  blocking: Scalars['Boolean']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentTaskCategoryId: Scalars['ID']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type TerritoryFee = {
  __typename?: 'TerritoryFee';
  amount?: Maybe<Scalars['Int']['output']>;
  feeType: TerritoryFeeInvoiceType;
  id: Scalars['ID']['output'];
  nameKey?: Maybe<Scalars['String']['output']>;
  percentageRate?: Maybe<Scalars['Float']['output']>;
  userDescription?: Maybe<Scalars['String']['output']>;
};

export enum TerritoryFeeInvoiceType {
  airport = 'airport',
  black_car = 'black_car',
  congestion = 'congestion',
  nj_surcharge = 'nj_surcharge',
  toll = 'toll',
}

export enum TeslaCommand {
  SET_AUTO_CONDITIONING_START = 'SET_AUTO_CONDITIONING_START',
  SET_AUTO_CONDITIONING_STOP = 'SET_AUTO_CONDITIONING_STOP',
  SET_MEDIA_TOGGLE_PLAYBACK = 'SET_MEDIA_TOGGLE_PLAYBACK',
  SET_MEDIA_VOLUME_DOWN = 'SET_MEDIA_VOLUME_DOWN',
  SET_MEDIA_VOLUME_UP = 'SET_MEDIA_VOLUME_UP',
  SET_SEAT_HEATER = 'SET_SEAT_HEATER',
  SET_TEMPERATURE = 'SET_TEMPERATURE',
}

export enum TeslaSensorOnOff {
  off = 'off',
  on = 'on',
}

export type Timers = {
  __typename?: 'Timers';
  pickup: PickupTimer;
  scheduledBreak: ScheduledBreakTimer;
  scheduledPickup: ScheduledPickupTimer;
  staleVehicleLocation: StaleVehicleLocationTimer;
};

export type Tip = {
  __typename?: 'Tip';
  amount: Scalars['Int']['output'];
  category?: Maybe<Scalars['String']['output']>;
  curencyCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['String']['output']>;
};

export type Token = {
  __typename?: 'Token';
  created: Scalars['DateTime']['output'];
  expires: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type TollFee = {
  __typename?: 'TollFee';
  startTollGuruRoad: Scalars['String']['output'];
  userDescription?: Maybe<Scalars['String']['output']>;
};

export type TrafficViolationTicket = {
  __typename?: 'TrafficViolationTicket';
  amountDue?: Maybe<Scalars['Int']['output']>;
  cityViolationCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  driver?: Maybe<Driver>;
  fineAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  interestAmount?: Maybe<Scalars['Int']['output']>;
  issuedAt: Scalars['DateTime']['output'];
  licensePlate: Scalars['String']['output'];
  paymentAmount?: Maybe<Scalars['Int']['output']>;
  penaltyAmount?: Maybe<Scalars['Int']['output']>;
  reductionAmount?: Maybe<Scalars['Int']['output']>;
  resourceId?: Maybe<Scalars['String']['output']>;
  revelViolationCode?: Maybe<RevelViolationCodes>;
  shift?: Maybe<DriverShift>;
  stopPoint?: Maybe<DriverStopPoint>;
  summonsNumber: Scalars['String']['output'];
  ticketIssuingAgency?: Maybe<Scalars['String']['output']>;
  ticketUrl?: Maybe<Scalars['String']['output']>;
};

export type TrafficViolationTicketFilters = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  issued_at__gte?: InputMaybe<Scalars['DateTime']['input']>;
  issued_at__lte?: InputMaybe<Scalars['DateTime']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  shiftId?: InputMaybe<Scalars['ID']['input']>;
};

export enum TransitionRentalStatusEnum {
  FIRST_RIDE_EDUCATION = 'FIRST_RIDE_EDUCATION',
  HELMET_CASE_UNLOCKED = 'HELMET_CASE_UNLOCKED',
  HELMET_CASE_UNLOCKING = 'HELMET_CASE_UNLOCKING',
  PASSENGER_SELECTION = 'PASSENGER_SELECTION',
  VEHICLE_STARTED = 'VEHICLE_STARTED',
}

export type UnlinkPassengerInput = {
  passengerAccountId: Scalars['ID']['input'];
};

export type UpdateAssignmentInput = {
  assignmentType?: InputMaybe<Scalars['String']['input']>;
  batteryLevelThreshold?: InputMaybe<Scalars['Int']['input']>;
  dispatchNotes?: InputMaybe<Scalars['String']['input']>;
  operatorId?: InputMaybe<Scalars['ID']['input']>;
  startLocation?: InputMaybe<PointInput>;
  taskTypeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unblock?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCXIdVerificationInput = {
  idCountry: Scalars['String']['input'];
  idDob: Scalars['String']['input'];
  idExpiry: Scalars['String']['input'];
  idFirstName: Scalars['String']['input'];
  idIssueDate: Scalars['String']['input'];
  idLastName: Scalars['String']['input'];
  idNumber: Scalars['ID']['input'];
  idUsState: Scalars['String']['input'];
  idVerificationId: Scalars['ID']['input'];
};

export type UpdateDriverAddressInput = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  addressType: DriverAddressTypes;
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type UpdateDriverInsuranceApprovalInput = {
  driverId: Scalars['ID']['input'];
  insuranceProviderIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type UpdateDriverPaymentMethodInput = {
  isSelected?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethodId: Scalars['ID']['input'];
};

export type UpdateDriverProfileInfoInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  insuranceProviderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateDriverProfileInput = {
  addresses?: InputMaybe<Array<InputMaybe<UpdateDriverAddressInput>>>;
};

export type UpdateDriverScheduledShiftTimeInput = {
  scheduledEndAt?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAt?: InputMaybe<Scalars['String']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type UpdateDriverShiftDeviceInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  deviceId: Scalars['String']['input'];
  deviceType?: InputMaybe<DeviceTypes>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  tokenType: PushTokenTypes;
};

export type UpdateDriverShiftInput = {
  actualEndAt?: InputMaybe<Scalars['String']['input']>;
  actualStartAt?: InputMaybe<Scalars['String']['input']>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  scheduledEndAt?: InputMaybe<Scalars['String']['input']>;
  scheduledRecallAt?: InputMaybe<Scalars['String']['input']>;
  scheduledStartAt?: InputMaybe<Scalars['String']['input']>;
  shiftId: Scalars['String']['input'];
};

export type UpdateDriverShiftStateInput = {
  shiftId: Scalars['ID']['input'];
  state: DriverShiftStates;
};

export type UpdateDriverVehicleExclusionMakeAndModelInput = {
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
};

export type UpdateDriverVehicleExclusionsInput = {
  driverId: Scalars['ID']['input'];
  makesAndModels: Array<InputMaybe<UpdateDriverVehicleExclusionMakeAndModelInput>>;
};

export type UpdateDriverVehiclePreferenceInput = {
  driverId: Scalars['ID']['input'];
  vehicleType: DriverVehiclePreferenceType;
};

export type UpdateFlagInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  expirationTime?: InputMaybe<Scalars['DateTime']['input']>;
  flagTypeId?: InputMaybe<Scalars['ID']['input']>;
  internalTitle?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<PointInput>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateInternalDriverProfileInput = {
  addresses?: InputMaybe<Array<InputMaybe<UpdateDriverAddressInput>>>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  driverLicenseIssuer?: InputMaybe<Scalars['String']['input']>;
  driverLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employmentStatus?: InputMaybe<DriverUpdateEmploymentStatus>;
  ezPassRental?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fleetResourceId?: InputMaybe<Scalars['String']['input']>;
  insurancePremiumTier?: InputMaybe<InsurancePremiumTiers>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  tlcLicenseExpires?: InputMaybe<Scalars['String']['input']>;
  tlcLicenseNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLicenseInput = {
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  photoBackUri?: InputMaybe<Scalars['String']['input']>;
  photoFrontUri?: InputMaybe<Scalars['String']['input']>;
  wheelchairAccessibleTrained?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOperatorInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdatePushNotificationReceivedInput = {
  messageId: Scalars['ID']['input'];
  receivedAt: Scalars['DateTime']['input'];
};

export type UpdateRecurringShiftInput = {
  deleteFutureScheduledShifts?: InputMaybe<Scalars['Boolean']['input']>;
  depotId?: InputMaybe<Scalars['ID']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  endWeekday?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  startWeekday?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRenterPhoneNumberInput = {
  phoneNumber: Scalars['String']['input'];
};

export type UpdateRepairInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  completedById?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  repairTypeId?: InputMaybe<Scalars['ID']['input']>;
  reservationId?: InputMaybe<Scalars['ID']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
  workOrderId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateShiftClockTimesInput = {
  actualEndAt?: InputMaybe<Scalars['DateTime']['input']>;
  actualStartAt?: InputMaybe<Scalars['DateTime']['input']>;
  shiftId: Scalars['ID']['input'];
};

export type UpdateShiftVehicleLocationInput = {
  accuracy?: InputMaybe<Scalars['Float']['input']>;
  bearing?: InputMaybe<Scalars['Float']['input']>;
  location: PointInput;
  shiftId: Scalars['ID']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type UpdateWorkOrderInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateSummonsInput = {
  summonsNumber: Scalars['String']['input'];
};

export type ValidatedSummons = {
  __typename?: 'ValidatedSummons';
  summonsImageUrl: Scalars['String']['output'];
  summonsNumber: Scalars['ID']['output'];
};

export type VehicleFacility = {
  __typename?: 'VehicleFacility';
  address: Scalars['String']['output'];
  centerPoint: Point;
  description: Scalars['String']['output'];
  geofenceRadiusMeters: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type VehicleFacilityResponse = {
  __typename?: 'VehicleFacilityResponse';
  count: Scalars['Int']['output'];
  results: Array<VehicleFacility>;
};

export type VehicleStateSensors = {
  __typename?: 'VehicleStateSensors';
  locked?: Maybe<Scalars['Boolean']['output']>;
  odometer?: Maybe<Scalars['Float']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
};

export type VerificationCode = {
  __typename?: 'VerificationCode';
  id: Scalars['ID']['output'];
  verificationCode: Scalars['String']['output'];
};

export type Violation = {
  __typename?: 'Violation';
  automated: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ViolationActions {
  deletion_requested_permanent_suspension = 'deletion_requested_permanent_suspension',
  permanent_ebike_suspension = 'permanent_ebike_suspension',
  permanent_ridehail_suspension = 'permanent_ridehail_suspension',
  permanent_suspension = 'permanent_suspension',
  temporary_ridehail_suspension = 'temporary_ridehail_suspension',
  temporary_suspension = 'temporary_suspension',
  warning = 'warning',
}

export type WaitlistProduct = {
  __typename?: 'WaitlistProduct';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enqueueMethod: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  waitlistClose?: Maybe<Scalars['DateTime']['output']>;
  waitlistOpen?: Maybe<Scalars['DateTime']['output']>;
};

export type WorkOrder = {
  __typename?: 'WorkOrder';
  atVehicleAt?: Maybe<Scalars['DateTime']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  created: Scalars['DateTime']['output'];
  createdFor?: Maybe<WorkOrderCreationContext>;
  driveTime?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endBatteryLevel?: Maybe<Scalars['Int']['output']>;
  fleetResource: FleetResource;
  fleetResourceId?: Maybe<Scalars['ID']['output']>;
  fleetResourceLocationCompleted?: Maybe<Point>;
  fleetResourceLocationCreated: Point;
  fleetResourceLocationUnlocked?: Maybe<Point>;
  id: Scalars['ID']['output'];
  repairs: Array<Repair>;
  resourceTasks?: Maybe<Array<Task>>;
  skipReason?: Maybe<WorkOrderSkipReason>;
  startBatteryLevel?: Maybe<Scalars['Int']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<WorkOrderStatus>;
  systemId?: Maybe<Scalars['ID']['output']>;
  workOrderTasks?: Maybe<Array<WorkOrderTask>>;
};

export type WorkOrderresourceTasksArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum WorkOrderCreationContext {
  BATTERY_SWAP = 'BATTERY_SWAP',
  FLEX = 'FLEX',
  TASK = 'TASK',
}

export type WorkOrderFilters = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum WorkOrderSkipReason {
  CANT_PARK = 'CANT_PARK',
  EMERGENCY = 'EMERGENCY',
  END_ASSIGNMENT = 'END_ASSIGNMENT',
  LOW_FUEL = 'LOW_FUEL',
  MOPED_DAMAGE = 'MOPED_DAMAGE',
  OTHER = 'OTHER',
  SKIPPED_IN_DISPATCH = 'SKIPPED_IN_DISPATCH',
  SYSTEM_UNABLE_TO_RESERVE = 'SYSTEM_UNABLE_TO_RESERVE',
  TELEMATICS = 'TELEMATICS',
  TRAFFIC = 'TRAFFIC',
  UNABLE_TO_LOCATE = 'UNABLE_TO_LOCATE',
  UNVERIFIED_SWAP = 'UNVERIFIED_SWAP',
}

export enum WorkOrderStatus {
  AT_VEHICLE = 'AT_VEHICLE',
  COMPLETE = 'COMPLETE',
  DRAFT = 'DRAFT',
  STARTED = 'STARTED',
  UPCOMING = 'UPCOMING',
}

export type WorkOrderTask = {
  __typename?: 'WorkOrderTask';
  comments?: Maybe<Array<TaskComment>>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  newTaskStatus?: Maybe<Scalars['String']['output']>;
  originalTaskStatus?: Maybe<Scalars['String']['output']>;
  task: Task;
  taskCloseReason?: Maybe<Scalars['String']['output']>;
  taskId: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
  workOrderId: Scalars['ID']['output'];
};

export enum WorkOrderType {
  BATTERY_SWAP = 'BATTERY_SWAP',
  FLEX = 'FLEX',
  TASK = 'TASK',
}

export type Zone = {
  __typename?: 'Zone';
  areaGeojsonString: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nameKey: Scalars['String']['output'];
  product: ServiceAreaProduct;
  system: System;
  systemId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
  zoneType: Scalars['String']['output'];
};

export type ZoneFilters = {
  product?: InputMaybe<ServiceAreaProduct>;
  systemId?: InputMaybe<Scalars['ID']['input']>;
  zoneTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ZoneList = {
  __typename?: 'ZoneList';
  count: Scalars['Int']['output'];
  results: Array<Zone>;
};

export type commandFleetResourceInput = {
  command: NiuCommand;
};

export type updateRentersEmailInput = {
  email: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
};

export type updateRentersNameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  renterId: Scalars['ID']['input'];
};

export type GetDriverVehicleSelectorVehiclesQueryVariables = Exact<{
  filters: DriverFleetResourceFilters;
}>;

export type GetDriverVehicleSelectorVehiclesQuery = {
  __typename?: 'Query';
  vehicles: {
    __typename?: 'DriverFleetResourcesResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverFleetResource';
      id: string;
      batteryLevel?: number | null;
      licensePlate: string;
      status?: string | null;
      location?: { __typename?: 'Point'; lat: number; lng: number } | null;
      activeShift?: { __typename?: 'DriverShift'; state: DriverShiftStates; id: string } | null;
      assignedDriver?: { __typename?: 'Driver'; firstName: string; lastName: string } | null;
      detail: { __typename?: 'DriverFleetResourceDetail'; make: string; model: string };
      fleetResourceMetadata?: {
        __typename?: 'FleetResourceMetadata';
        insuranceProvider?: {
          __typename?: 'InsuranceProvider';
          displayName: string;
          id: string;
        } | null;
      } | null;
    } | null>;
  };
};

export type AdvancedVehicleSelector_vehicleFacilityFragment = {
  __typename?: 'VehicleFacility';
  name: string;
  id: string;
  geofenceRadiusMeters: number;
  centerPoint: { __typename?: 'Point'; lat: number; lng: number };
};

export type GetDepotSelectorDepotsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepotSelectorDepotsQuery = {
  __typename?: 'Query';
  systems: Array<{
    __typename?: 'System';
    depots?: Array<{ __typename?: 'Depot'; id: string; name: string }> | null;
  }>;
};

export type GetDriverSelectorDriversQueryVariables = Exact<{
  filters?: InputMaybe<DriverOpsDriversFilters>;
}>;

export type GetDriverSelectorDriversQuery = {
  __typename?: 'Query';
  drivers?: {
    __typename?: 'DriverOpsDriverListResponse';
    results?: Array<{
      __typename?: 'DriverOpsDriverAndDetails';
      id: string;
      avatar?: string | null;
      firstName: string;
      lastName: string;
      dayforceXrefCode?: string | null;
    }> | null;
  } | null;
};

export type DriverShiftArrival_shiftFragment = {
  __typename?: 'DriverShift';
  driverArrivedForShiftAt?: any | null;
  shiftCancellation?: {
    __typename?: 'ShiftCancellation';
    shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
  } | null;
};

export type GetSystemsDepotsQueryVariables = Exact<{
  systemIdentifier: Scalars['String']['input'];
}>;

export type GetSystemsDepotsQuery = {
  __typename?: 'Query';
  systems: Array<{
    __typename?: 'System';
    depots?: Array<{
      __typename?: 'Depot';
      id: string;
      name: string;
      address: string;
      location: { __typename?: 'Point'; lat: number; lng: number };
    }> | null;
  }>;
};

export type GetDriverSystemZonesQueryVariables = Exact<{
  systemId: Scalars['ID']['input'];
}>;

export type GetDriverSystemZonesQuery = {
  __typename?: 'Query';
  zones?: Array<{
    __typename?: 'DriverSystemZone';
    id: string;
    areaInvertedGeojsonString?: string | null;
  }> | null;
};

export type ShiftActionDropdown_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  clockIn?: any | null;
  scheduledStartAt: any;
  scheduledEndAt: any;
  reservationType?: ReservationTypes | null;
  note?: { __typename?: 'ShiftNote'; note: string } | null;
  driver: { __typename?: 'Driver'; id: string };
  depot?: { __typename?: 'Depot'; name: string } | null;
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    vehicle?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ShiftActionShortcuts_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  note?: { __typename?: 'ShiftNote'; note: string } | null;
  driver: { __typename?: 'Driver'; id: string };
};

export type ShiftClockOut_shiftFragment = {
  __typename?: 'DriverShift';
  clockIn?: any | null;
  clockOut?: any | null;
};

export type GetShiftInfoQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetShiftInfoQuery = {
  __typename?: 'Query';
  shift?: {
    __typename?: 'DriverShift';
    id: string;
    vehicleLocationUpdatedAt?: any | null;
    reservationType?: ReservationTypes | null;
    state: DriverShiftStates;
    issueType?: DriverShiftIssueTypes | null;
    currentIssueId?: string | null;
    clockIn?: any | null;
    scheduledStartAt: any;
    scheduledEndAt: any;
    scheduledRecallAt?: any | null;
    clockOut?: any | null;
    driverArrivedForShiftAt?: any | null;
    note?: { __typename?: 'ShiftNote'; note: string } | null;
    driver: {
      __typename?: 'Driver';
      id: string;
      dayforceXrefCode?: string | null;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      firstShiftCompleted?: boolean | null;
    };
    depot?: { __typename?: 'Depot'; name: string; id: string } | null;
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      vehicle?: {
        __typename?: 'FleetResource';
        id: string;
        licensePlate: string;
        batteryLevel: number;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
      } | null;
    } | null;
    settings?: {
      __typename?: 'ShiftSettings';
      phoneNumber?: string | null;
      phoneType?: string | null;
      deviceType?: DeviceTypes | null;
      appVersion?: string | null;
    } | null;
    breaks?: Array<{
      __typename?: 'DriverShiftBreak';
      id: string;
      type?: DriverShiftBreakTypes | null;
      scheduledStartAt?: any | null;
      scheduledEndAt?: any | null;
      actualStartAt?: any | null;
      actualEndAt?: any | null;
    }> | null;
    stats?: {
      __typename?: 'ShiftStats';
      negativeRatingCount?: number | null;
      negativeRatingCountThreshold?: number | null;
      missedRideCount?: number | null;
      missedRideCountThreshold?: number | null;
      avgPickupDelaySeconds?: number | null;
      avgPickupDelaySecondsThreshold?: number | null;
      driverAvailabilityPercentage?: number | null;
      driverAvailabilityPercentageThreshold?: number | null;
      attendancePercentage?: number | null;
      attendancePercentageThreshold?: number | null;
      endOfShiftRecallDelaySeconds?: number | null;
      endOfShiftRecallDelaySecondsThreshold?: number | null;
      exemptions?: Array<string | null> | null;
      performancePayMultiplier?: number | null;
      driverPerformanceStatus?: number | null;
      performanceStatusThresholds?: any | null;
    } | null;
    shiftCancellation?: {
      __typename?: 'ShiftCancellation';
      shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
    } | null;
    currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
  } | null;
};

export type ShiftDriverInfoField_shiftFragment = {
  __typename?: 'DriverShift';
  state: DriverShiftStates;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  reservationType?: ReservationTypes | null;
  driver: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    firstShiftCompleted?: boolean | null;
  };
  currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
};

export type ShiftInfoCard_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  scheduledStartAt: any;
  scheduledEndAt: any;
  scheduledRecallAt?: any | null;
  clockIn?: any | null;
  clockOut?: any | null;
  reservationType?: ReservationTypes | null;
  driverArrivedForShiftAt?: any | null;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  driver: {
    __typename?: 'Driver';
    id: string;
    dayforceXrefCode?: string | null;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    firstShiftCompleted?: boolean | null;
  };
  settings?: {
    __typename?: 'ShiftSettings';
    phoneNumber?: string | null;
    phoneType?: string | null;
    deviceType?: DeviceTypes | null;
    appVersion?: string | null;
  } | null;
  depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  breaks?: Array<{
    __typename?: 'DriverShiftBreak';
    id: string;
    type?: DriverShiftBreakTypes | null;
    scheduledStartAt?: any | null;
    scheduledEndAt?: any | null;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
  }> | null;
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    vehicle?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
    } | null;
  } | null;
  shiftCancellation?: {
    __typename?: 'ShiftCancellation';
    shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
  } | null;
  currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
};

export type ShiftInfoPanel_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  vehicleLocationUpdatedAt?: any | null;
  reservationType?: ReservationTypes | null;
  state: DriverShiftStates;
  scheduledStartAt: any;
  scheduledEndAt: any;
  scheduledRecallAt?: any | null;
  clockIn?: any | null;
  clockOut?: any | null;
  driverArrivedForShiftAt?: any | null;
  issueType?: DriverShiftIssueTypes | null;
  currentIssueId?: string | null;
  note?: { __typename?: 'ShiftNote'; note: string } | null;
  driver: {
    __typename?: 'Driver';
    id: string;
    dayforceXrefCode?: string | null;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    firstShiftCompleted?: boolean | null;
  };
  settings?: {
    __typename?: 'ShiftSettings';
    phoneNumber?: string | null;
    phoneType?: string | null;
    deviceType?: DeviceTypes | null;
    appVersion?: string | null;
  } | null;
  depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  breaks?: Array<{
    __typename?: 'DriverShiftBreak';
    id: string;
    type?: DriverShiftBreakTypes | null;
    scheduledStartAt?: any | null;
    scheduledEndAt?: any | null;
    actualStartAt?: any | null;
    actualEndAt?: any | null;
  }> | null;
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    vehicle?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      batteryLevel: number;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
    } | null;
  } | null;
  stats?: {
    __typename?: 'ShiftStats';
    negativeRatingCount?: number | null;
    negativeRatingCountThreshold?: number | null;
    missedRideCount?: number | null;
    missedRideCountThreshold?: number | null;
    avgPickupDelaySeconds?: number | null;
    avgPickupDelaySecondsThreshold?: number | null;
    driverAvailabilityPercentage?: number | null;
    driverAvailabilityPercentageThreshold?: number | null;
    attendancePercentage?: number | null;
    attendancePercentageThreshold?: number | null;
    endOfShiftRecallDelaySeconds?: number | null;
    endOfShiftRecallDelaySecondsThreshold?: number | null;
    exemptions?: Array<string | null> | null;
    performancePayMultiplier?: number | null;
    driverPerformanceStatus?: number | null;
    performanceStatusThresholds?: any | null;
  } | null;
  shiftCancellation?: {
    __typename?: 'ShiftCancellation';
    shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
  } | null;
  currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
};

export type ShiftMapMarker_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  issueType?: DriverShiftIssueTypes | null;
  vehicleLocationUpdatedAt?: any | null;
  driver: { __typename?: 'Driver'; firstName: string; lastName: string };
  vehicleLocation?: { __typename?: 'Point'; lat: number; lng: number } | null;
  currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
};

export type GetShiftMapMarkerInfoQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetShiftMapMarkerInfoQuery = {
  __typename?: 'Query';
  shift?: {
    __typename?: 'DriverShift';
    id: string;
    state: DriverShiftStates;
    issueType?: DriverShiftIssueTypes | null;
    vehicleLocationUpdatedAt?: any | null;
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      vehicle?: { __typename?: 'FleetResource'; licensePlate: string; batteryLevel: number } | null;
    } | null;
    driver: { __typename?: 'Driver'; firstName: string; lastName: string };
    vehicleLocation?: { __typename?: 'Point'; lat: number; lng: number } | null;
    currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
  } | null;
};

export type ShiftScheduledEnd_shiftFragment = {
  __typename?: 'DriverShift';
  scheduledStartAt: any;
  scheduledEndAt: any;
};

export type ShiftStatCard_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  stats?: {
    __typename?: 'ShiftStats';
    negativeRatingCount?: number | null;
    negativeRatingCountThreshold?: number | null;
    missedRideCount?: number | null;
    missedRideCountThreshold?: number | null;
    avgPickupDelaySeconds?: number | null;
    avgPickupDelaySecondsThreshold?: number | null;
    driverAvailabilityPercentage?: number | null;
    driverAvailabilityPercentageThreshold?: number | null;
    attendancePercentage?: number | null;
    attendancePercentageThreshold?: number | null;
    endOfShiftRecallDelaySeconds?: number | null;
    endOfShiftRecallDelaySecondsThreshold?: number | null;
    exemptions?: Array<string | null> | null;
    performancePayMultiplier?: number | null;
    driverPerformanceStatus?: number | null;
    performanceStatusThresholds?: any | null;
  } | null;
};

export type ShiftVehicle_shiftFragment = {
  __typename?: 'DriverShift';
  id: string;
  state: DriverShiftStates;
  resourceId?: string | null;
  depot?: { __typename?: 'Depot'; name: string } | null;
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    vehicle?: {
      __typename?: 'FleetResource';
      id: string;
      licensePlate: string;
      detail?: {
        __typename?: 'FleetResourceDetail';
        make?: string | null;
        model?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SpotlightDriverSearchQueryVariables = Exact<{
  filters?: InputMaybe<DriverOpsDriversFilters>;
}>;

export type SpotlightDriverSearchQuery = {
  __typename?: 'Query';
  drivers?: {
    __typename?: 'DriverOpsDriverListResponse';
    results?: Array<{
      __typename?: 'DriverOpsDriverAndDetails';
      id: string;
      avatar?: string | null;
      firstName: string;
      lastName: string;
    }> | null;
  } | null;
};

export type CommandResourceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: CommandResourceInput;
}>;

export type CommandResourceMutation = { __typename?: 'Mutation'; commandResource: boolean };

export type GetVehicleFacilitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetVehicleFacilitiesQuery = {
  __typename?: 'Query';
  vehicleFacilities: {
    __typename?: 'VehicleFacilityResponse';
    results: Array<{ __typename?: 'VehicleFacility'; id: string; name: string }>;
  };
};

export type VehicleInfoButton_fleetResourceFragment = {
  __typename?: 'FleetResource';
  id: string;
  licensePlate: string;
  detail?: {
    __typename?: 'FleetResourceDetail';
    make?: string | null;
    model?: string | null;
  } | null;
};

export type VehicleInfoPanel_vehicleFragment = {
  __typename?: 'DriverFleetResource';
  id: string;
  vin: string;
  licensePlate: string;
  batteryLevel?: number | null;
  telematicsProvider: string;
  status?: string | null;
  speedLimitPin?: string | null;
  location?: { __typename?: 'Point'; lat: number; lng: number } | null;
  detail: {
    __typename?: 'DriverFleetResourceDetail';
    make: string;
    model: string;
    year?: number | null;
  };
  fleetResourceMetadata?: {
    __typename?: 'FleetResourceMetadata';
    fhvLicenseNumber: string;
    isControllable?: boolean | null;
    tlcBase?: { __typename?: 'ResourceMetadataTLCBase'; name: string } | null;
    dispatchingTlcBase?: { __typename?: 'ResourceMetadataTLCBase'; name: string } | null;
    insuranceProvider?: { __typename?: 'InsuranceProvider'; displayName: string } | null;
    vehicle?: {
      __typename?: 'FleetResource';
      sensors?: { __typename?: 'Sensors'; updateTime?: any | null } | null;
    } | null;
  } | null;
  activeShift?: {
    __typename?: 'DriverShift';
    id: string;
    state: DriverShiftStates;
    scheduledStartAt: any;
    scheduledEndAt: any;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      firstShiftCompleted?: boolean | null;
    };
  } | null;
};

export type VehicleLocationUpdateBadge_fleetResourceFragment = {
  __typename?: 'FleetResource';
  sensors?: { __typename?: 'Sensors'; updateTime?: any | null } | null;
};

export type VehicleMakeModelFragment = {
  __typename?: 'DriverFleetResourceDetail';
  make: string;
  model: string;
};

export type GetVehicleSelectorVehiclesQueryVariables = Exact<{
  filters?: InputMaybe<ResourceFilters>;
}>;

export type GetVehicleSelectorVehiclesQuery = {
  __typename?: 'Query';
  vehicles: Array<{
    __typename?: 'FleetResource';
    id: string;
    batteryLevel: number;
    licensePlate: string;
  }>;
};

export type IssueRentalCreditMutationVariables = Exact<{
  input: IssueDriverCreditInput;
}>;

export type IssueRentalCreditMutation = {
  __typename?: 'Mutation';
  issueDriverCredit: { __typename?: 'DriverCredit'; amountIssued: number };
};

export type CalculateDriverVehicleCreditAmountQueryVariables = Exact<{
  minutes: Scalars['Int']['input'];
  vehicleType: DriverVehiclePreferenceType;
}>;

export type CalculateDriverVehicleCreditAmountQuery = {
  __typename?: 'Query';
  calculateDriverVehicleCreditAmount: {
    __typename?: 'DriverCreditAmountResponse';
    creditAmount: number;
  };
};

export type GetDriverAvailableCreditQueryVariables = Exact<{
  driverId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetDriverAvailableCreditQuery = {
  __typename?: 'Query';
  getDriverAvailableCredit: number;
};

export type GetAssignVehicleToShiftDriverDetailsQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetAssignVehicleToShiftDriverDetailsQuery = {
  __typename?: 'Query';
  approvals: {
    __typename?: 'DriverInsuranceApprovalResponse';
    results: Array<{
      __typename?: 'DriverInsuranceApproval';
      id: string;
      insuranceProvider: { __typename?: 'InsuranceProvider'; displayName: string; id: string };
    } | null>;
  };
  vehicleExlusions: Array<{
    __typename?: 'DriverVehicleExclusionMakeAndModel';
    make: string;
    model: string;
  } | null>;
  vehiclePreference?: {
    __typename?: 'DriverVehiclePreferenceResponse';
    preferredVehicleType: DriverVehiclePreferenceType;
  } | null;
};

export type GetShiftCancellationReasonsQueryVariables = Exact<{ [key: string]: never }>;

export type GetShiftCancellationReasonsQuery = {
  __typename?: 'Query';
  getShiftCancellationReasons?: Array<{
    __typename?: 'ShiftCancellationReason';
    id: string;
    displayName: string;
    warningText?: string | null;
  }> | null;
};

export type CancelDriverShiftMutationVariables = Exact<{
  input: CancelDriverOpsDriverShiftInput;
}>;

export type CancelDriverShiftMutation = {
  __typename?: 'Mutation';
  cancelDriverOpsDriverShift: { __typename?: 'DriverShift'; id: string };
};

export type CheckInShiftOverrideMutationVariables = Exact<{
  input: CheckInShiftOverrideInput;
}>;

export type CheckInShiftOverrideMutation = {
  __typename?: 'Mutation';
  checkInShiftOverride: { __typename?: 'DriverShift'; id: string };
};

export type ReportDriverShiftIssueMutationVariables = Exact<{
  input: ReportDriverShiftIssueInput;
}>;

export type ReportDriverShiftIssueMutation = {
  __typename?: 'Mutation';
  reportDriverShiftIssue: { __typename?: 'DriverShift'; id: string };
};

export type CreateManualInvoiceMutationVariables = Exact<{
  input: CreateManualInvoiceInput;
}>;

export type CreateManualInvoiceMutation = {
  __typename?: 'Mutation';
  invoice: {
    __typename?: 'DriverInvoice';
    id: string;
    createdAt: any;
    subtotal: number;
    tax: number;
    taxPercentage: number;
    total: number;
    status: DriverInvoiceStatuses;
    descriptor?: DriverInvoiceDescriptors | null;
    appliedCredit?: number | null;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      stripeCustomerId?: string | null;
    } | null;
    invoiceItems?: Array<{
      __typename?: 'DriverInvoiceItem';
      amount?: number | null;
      displayTitleText?: string | null;
      displayDetailText?: string | null;
      id?: string | null;
    }> | null;
    paymentIntents?: Array<{
      __typename?: 'DriverPaymentIntent';
      createdAt?: any | null;
      status?: DriverPaymentIntentStatuses | null;
      amount?: number | null;
      stripeExternalId?: string | null;
      paymentMethod?: {
        __typename?: 'DriverPaymentMethod';
        lastFour?: string | null;
        brand?: string | null;
        type?: DriverPaymentMethodTypes | null;
      } | null;
    }> | null;
  };
};

export type CreateRecurringShiftMutationVariables = Exact<{
  input: CreateRecurringShiftInput;
}>;

export type CreateRecurringShiftMutation = {
  __typename?: 'Mutation';
  createRecurringShift?: { __typename?: 'RecurringShift'; id: string } | null;
};

export type GetDriverEmploymentTypeQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverEmploymentTypeQuery = {
  __typename?: 'Query';
  driver?: { __typename?: 'Driver'; id: string; employmentType?: EmploymentTypes | null } | null;
};

export type CreateDriverShiftMutationVariables = Exact<{
  input: CreateDriverShiftInput;
}>;

export type CreateDriverShiftMutation = {
  __typename?: 'Mutation';
  shift: { __typename?: 'DriverShift'; scheduledStartAt: any; scheduledEndAt: any };
};

export type DeleteRecurringShiftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  deleteFutureShifts?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type DeleteRecurringShiftMutation = {
  __typename?: 'Mutation';
  deleteRecurringShift?: { __typename?: 'EmptyResponse'; id?: string | null } | null;
};

export type CreateDriverContractConversionFormMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: CreateDriverContractConversionFormInput;
}>;

export type CreateDriverContractConversionFormMutation = {
  __typename?: 'Mutation';
  createDriverContractConversionForm?: {
    __typename?: 'DriverContractConversionForm';
    email: string;
    convertAt?: any | null;
  } | null;
};

export type DeleteDriverContractConversionFormMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type DeleteDriverContractConversionFormMutation = {
  __typename?: 'Mutation';
  deleteDriverContractConversionForm?: { __typename?: 'EmptyResponse'; id?: string | null } | null;
};

export type GetDriverContractConversionFormQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverContractConversionFormQuery = {
  __typename?: 'Query';
  getDriverContractConversionForm?: {
    __typename?: 'DriverContractConversionForm';
    email: string;
    convertAt?: any | null;
    completedAt?: any | null;
  } | null;
};

export type UpdateDriverAddressMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverAddressMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: { __typename?: 'Driver'; updatedAt?: any | null };
};

export type UpdateDriverInsuranceApprovalsMutationVariables = Exact<{
  input: UpdateDriverInsuranceApprovalInput;
}>;

export type UpdateDriverInsuranceApprovalsMutation = {
  __typename?: 'Mutation';
  updateDriverInsuranceApprovals: Array<{
    __typename?: 'DriverInsuranceApproval';
    id: string;
  } | null>;
};

export type GetInsuranceProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type GetInsuranceProvidersQuery = {
  __typename?: 'Query';
  insuranceProviders?: {
    __typename?: 'InsuranceProviderResponse';
    results: Array<{ __typename?: 'InsuranceProvider'; displayName: string; id: string }>;
  } | null;
};

export type UpdateDriverVehicleExclusionsMutationVariables = Exact<{
  input: UpdateDriverVehicleExclusionsInput;
}>;

export type UpdateDriverVehicleExclusionsMutation = {
  __typename?: 'Mutation';
  updateDriverVehicleExclusions: Array<{
    __typename?: 'DriverVehicleExclusionMakeAndModel';
    make: string;
    model: string;
  } | null>;
};

export type GetDriverFleetResourceDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDriverFleetResourceDetailsQuery = {
  __typename?: 'Query';
  fleetResourceDetails: {
    __typename?: 'DriverFleetResourceDetailsResponse';
    results: Array<{
      __typename?: 'DriverFleetResourceDetail';
      make: string;
      model: string;
    } | null>;
  };
};

export type UpdateDriverVehicleMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverVehicleMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: { __typename?: 'Driver'; fleetResourceId?: string | null };
};

export type GetDriverVehiclePreferenceQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverVehiclePreferenceQuery = {
  __typename?: 'Query';
  getDriverVehiclePreference?: {
    __typename?: 'DriverVehiclePreferenceResponse';
    preferredVehicleType: DriverVehiclePreferenceType;
  } | null;
};

export type UpdateDriverVehiclePreferenceMutationVariables = Exact<{
  input: UpdateDriverVehiclePreferenceInput;
}>;

export type UpdateDriverVehiclePreferenceMutation = {
  __typename?: 'Mutation';
  updateDriverVehiclePreference: {
    __typename?: 'DriverVehiclePreferenceResponse';
    preferredVehicleType: DriverVehiclePreferenceType;
  };
};

export type UpdateDriverWeeklyRentalMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverWeeklyRentalMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: { __typename?: 'Driver'; fleetResourceId?: string | null };
};

export type GetReservationBillingSettingsQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetReservationBillingSettingsQuery = {
  __typename?: 'Query';
  getReservationBillingSettings?: {
    __typename?: 'ReservationBillingSettingsResponse';
    id: string;
    ezPassRental: boolean;
    insurancePremiumTier: InsurancePremiumTiers;
  } | null;
};

export type UpdateRecurringShiftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateRecurringShiftInput;
}>;

export type UpdateRecurringShiftMutation = {
  __typename?: 'Mutation';
  updateRecurringShift?: { __typename?: 'RecurringShift'; id: string } | null;
};

export type UpdateRecurringShiftSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ShiftSettingsInput;
}>;

export type UpdateRecurringShiftSettingsMutation = {
  __typename?: 'Mutation';
  updateRecurringShiftSettings?: {
    __typename?: 'ShiftSettings';
    dynamicRecallExtensionEnabled?: boolean | null;
    dynamicRecallReductionEnabled?: boolean | null;
  } | null;
};

export type GetIssueDetailsModalIssueQueryVariables = Exact<{
  issueId: Scalars['ID']['input'];
}>;

export type GetIssueDetailsModalIssueQuery = {
  __typename?: 'Query';
  issue?: {
    __typename?: 'ShiftIssue';
    id: string;
    issueType: DriverShiftIssueTypes;
    reportComment?: string | null;
    imageUrls?: Array<string> | null;
    startedAt?: any | null;
    shiftId?: string | null;
    shift?: {
      __typename?: 'DriverShift';
      id: string;
      state: DriverShiftStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      scheduledRecallAt?: any | null;
      clockIn?: any | null;
      clockOut?: any | null;
      reservationType?: ReservationTypes | null;
      driverArrivedForShiftAt?: any | null;
      issueType?: DriverShiftIssueTypes | null;
      currentIssueId?: string | null;
      driver: {
        __typename?: 'Driver';
        id: string;
        dayforceXrefCode?: string | null;
        phoneNumber: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        firstShiftCompleted?: boolean | null;
      };
      settings?: {
        __typename?: 'ShiftSettings';
        phoneNumber?: string | null;
        phoneType?: string | null;
        deviceType?: DeviceTypes | null;
        appVersion?: string | null;
      } | null;
      depot?: { __typename?: 'Depot'; id: string; name: string } | null;
      breaks?: Array<{
        __typename?: 'DriverShiftBreak';
        id: string;
        type?: DriverShiftBreakTypes | null;
        scheduledStartAt?: any | null;
        scheduledEndAt?: any | null;
        actualStartAt?: any | null;
        actualEndAt?: any | null;
      }> | null;
      fleetResourceMetadata?: {
        __typename?: 'FleetResourceMetadata';
        vehicle?: {
          __typename?: 'FleetResource';
          id: string;
          licensePlate: string;
          batteryLevel: number;
          detail?: {
            __typename?: 'FleetResourceDetail';
            make?: string | null;
            model?: string | null;
          } | null;
        } | null;
      } | null;
      shiftCancellation?: {
        __typename?: 'ShiftCancellation';
        shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
      } | null;
      currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
    } | null;
  } | null;
};

export type ResolveDriverShiftIssueMutationVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type ResolveDriverShiftIssueMutation = {
  __typename?: 'Mutation';
  resolveDriverShiftIssue: {
    __typename?: 'DriverShift';
    id: string;
    issueType?: DriverShiftIssueTypes | null;
  };
};

export type SubmitUpdateLicenseReviewMutationVariables = Exact<{
  licenseID: Scalars['ID']['input'];
  updateLicenseInput?: InputMaybe<UpdateLicenseInput>;
  reviewInput?: InputMaybe<ReviewLicenseInput>;
}>;

export type SubmitUpdateLicenseReviewMutation = {
  __typename?: 'Mutation';
  submitUpdateLicenseReview: { __typename?: 'License'; id: string };
};

export type UpdateDriverShiftMutationVariables = Exact<{
  input: UpdateDriverShiftInput;
}>;

export type UpdateDriverShiftMutation = {
  __typename?: 'Mutation';
  updateDriverShift: { __typename?: 'DriverShift'; id: string };
};

export type UpdateShiftClockTimesMutationVariables = Exact<{
  input: UpdateShiftClockTimesInput;
}>;

export type UpdateShiftClockTimesMutation = {
  __typename?: 'Mutation';
  updateShiftClockTimes: { __typename?: 'DriverShift'; id: string };
};

export type UpdateShiftSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ShiftSettingsInput;
}>;

export type UpdateShiftSettingsMutation = {
  __typename?: 'Mutation';
  updateShiftSettings?: {
    __typename?: 'ShiftSettings';
    dynamicRecallExtensionEnabled?: boolean | null;
    dynamicRecallReductionEnabled?: boolean | null;
  } | null;
};

export type AssignVehicleToShiftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  fleetResourceId: Scalars['ID']['input'];
}>;

export type AssignVehicleToShiftMutation = {
  __typename?: 'Mutation';
  assignVehicleToShift: { __typename?: 'DriverShift'; id: string; resourceId?: string | null };
};

export type RemoveAssignedVehicleFromShiftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveAssignedVehicleFromShiftMutation = {
  __typename?: 'Mutation';
  removeAssignedVehicleFromShift: {
    __typename?: 'DriverShift';
    id: string;
    resourceId?: string | null;
  };
};

export type EndReservationShiftOverrideMutationVariables = Exact<{
  input: CheckInShiftOverrideInput;
}>;

export type EndReservationShiftOverrideMutation = {
  __typename?: 'Mutation';
  endReservationShiftOverride: {
    __typename?: 'DriverShift';
    id: string;
    resourceId?: string | null;
  };
};

export type UpdateShiftNoteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  note: Scalars['String']['input'];
}>;

export type UpdateShiftNoteMutation = {
  __typename?: 'Mutation';
  updateShiftNote: {
    __typename?: 'ShiftNote';
    createdAt: any;
    createdByIdentityId: string;
    note: string;
  };
};

export type UpdateShiftExemptionsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ShiftExemptionInput;
}>;

export type UpdateShiftExemptionsMutation = {
  __typename?: 'Mutation';
  updateShiftExemptions?: {
    __typename?: 'ShiftSettings';
    exemptions?: Array<string | null> | null;
  } | null;
};

export type StandbyReservationMutationVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;

export type StandbyReservationMutation = {
  __typename?: 'Mutation';
  standbyReservation: { __typename?: 'RentalReservation'; id: string };
};

export type ResendDocusignDocumentsMutationVariables = Exact<{
  input: ResendDocusignDocumentsInput;
}>;

export type ResendDocusignDocumentsMutation = {
  __typename?: 'Mutation';
  resendDocusignDocuments?: boolean | null;
};

export type ResendBackgroundCheckMutationVariables = Exact<{
  input: ResendBackgroundCheckInput;
}>;

export type ResendBackgroundCheckMutation = {
  __typename?: 'Mutation';
  resendBackgroundCheck?: boolean | null;
};

export type GetDriverRestrictionsContentQueryVariables = Exact<{
  filters?: InputMaybe<DriverRestrictionStatusContentFilters>;
}>;

export type GetDriverRestrictionsContentQuery = {
  __typename?: 'Query';
  restrictionsContent?: {
    __typename?: 'DriverRestrictionsStatusContent';
    groups: Array<{
      __typename?: 'DriverRestrictionStatusGroup';
      id: string;
      title?: string | null;
      description?: string | null;
      items?: Array<{
        __typename?: 'DriverRestrictionItem';
        key: RestrictionReasonNameKeys;
        id: string;
        label: string;
        value?: boolean | null;
        description?: string | null;
        link?: { __typename?: 'DriverRestrictionItemLink'; text: string; href: string } | null;
      }> | null;
    }>;
  } | null;
};

export type GetInvoiceTabInvoicesQueryVariables = Exact<{
  filters?: InputMaybe<DriverInvoiceFilters>;
}>;

export type GetInvoiceTabInvoicesQuery = {
  __typename?: 'Query';
  invoices: {
    __typename?: 'DriverInvoicesResponse';
    count: number;
    results?: Array<{
      __typename?: 'DriverInvoice';
      id: string;
      createdAt: any;
      subtotal: number;
      tax: number;
      taxPercentage: number;
      total: number;
      status: DriverInvoiceStatuses;
      descriptor?: DriverInvoiceDescriptors | null;
      appliedCredit?: number | null;
      driver?: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        email?: string | null;
        stripeCustomerId?: string | null;
      } | null;
      invoiceItems?: Array<{
        __typename?: 'DriverInvoiceItem';
        amount?: number | null;
        displayTitleText?: string | null;
        displayDetailText?: string | null;
        id?: string | null;
      }> | null;
      paymentIntents?: Array<{
        __typename?: 'DriverPaymentIntent';
        createdAt?: any | null;
        status?: DriverPaymentIntentStatuses | null;
        amount?: number | null;
        stripeExternalId?: string | null;
        paymentMethod?: {
          __typename?: 'DriverPaymentMethod';
          lastFour?: string | null;
          brand?: string | null;
          type?: DriverPaymentMethodTypes | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type GetInvoiceTabInvoices_invoiceFragment = {
  __typename?: 'DriverInvoice';
  id: string;
  createdAt: any;
  subtotal: number;
  tax: number;
  taxPercentage: number;
  total: number;
  status: DriverInvoiceStatuses;
  descriptor?: DriverInvoiceDescriptors | null;
  appliedCredit?: number | null;
  driver?: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    email?: string | null;
    stripeCustomerId?: string | null;
  } | null;
  invoiceItems?: Array<{
    __typename?: 'DriverInvoiceItem';
    amount?: number | null;
    displayTitleText?: string | null;
    displayDetailText?: string | null;
    id?: string | null;
  }> | null;
  paymentIntents?: Array<{
    __typename?: 'DriverPaymentIntent';
    createdAt?: any | null;
    status?: DriverPaymentIntentStatuses | null;
    amount?: number | null;
    stripeExternalId?: string | null;
    paymentMethod?: {
      __typename?: 'DriverPaymentMethod';
      lastFour?: string | null;
      brand?: string | null;
      type?: DriverPaymentMethodTypes | null;
    } | null;
  }> | null;
};

export type ListRecurringShiftsQueryVariables = Exact<{
  filters?: InputMaybe<ListRecurringShiftsFilters>;
}>;

export type ListRecurringShiftsQuery = {
  __typename?: 'Query';
  listRecurringShifts?: Array<{
    __typename?: 'RecurringShift';
    id: string;
    startWeekday: number;
    endWeekday: number;
    startTime: string;
    endTime: string;
    depot?: { __typename?: 'Depot'; id: string; name: string } | null;
  }> | null;
};

export type GetRecurringShiftQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRecurringShiftQuery = {
  __typename?: 'Query';
  getRecurringShift?: {
    __typename?: 'RecurringShift';
    id: string;
    startWeekday: number;
    endWeekday: number;
    startTime: string;
    endTime: string;
    depotId?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    generatedUntil?: any | null;
    depot?: { __typename?: 'Depot'; id: string; name: string; systemId?: string | null } | null;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      dayforceXrefCode?: string | null;
      employmentType?: EmploymentTypes | null;
    };
    settings?: {
      __typename?: 'ShiftSettings';
      dynamicRecallExtensionEnabled?: boolean | null;
      dynamicRecallReductionEnabled?: boolean | null;
    } | null;
  } | null;
};

export type GetShiftsTabShiftsQueryVariables = Exact<{
  filters: DriverShiftFilters;
}>;

export type GetShiftsTabShiftsQuery = {
  __typename?: 'Query';
  shifts: {
    __typename?: 'DriverShiftsResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverShift';
      issueType?: DriverShiftIssueTypes | null;
      currentIssueId?: string | null;
      id: string;
      state: DriverShiftStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      actualStartAt?: any | null;
      scheduledRecallAt?: any | null;
      clockIn?: any | null;
      clockOut?: any | null;
      reservationType?: ReservationTypes | null;
      driverArrivedForShiftAt?: any | null;
      driver: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      };
      currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
      shiftCancellation?: {
        __typename?: 'ShiftCancellation';
        shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
      } | null;
      note?: { __typename?: 'ShiftNote'; note: string } | null;
      depot?: { __typename?: 'Depot'; name: string } | null;
      fleetResourceMetadata?: {
        __typename?: 'FleetResourceMetadata';
        vehicle?: {
          __typename?: 'FleetResource';
          id: string;
          licensePlate: string;
          detail?: {
            __typename?: 'FleetResourceDetail';
            make?: string | null;
            model?: string | null;
          } | null;
        } | null;
      } | null;
    }>;
  };
};

export type ListTrafficViolationTicketsQueryVariables = Exact<{
  filters?: InputMaybe<TrafficViolationTicketFilters>;
}>;

export type ListTrafficViolationTicketsQuery = {
  __typename?: 'Query';
  listTrafficViolationTickets: {
    __typename?: 'ListTrafficViolationTicketResponse';
    count: number;
    results: Array<{
      __typename?: 'TrafficViolationTicket';
      id: string;
      fineAmount?: number | null;
      issuedAt: any;
      createdAt: any;
      resourceId?: string | null;
      licensePlate: string;
      revelViolationCode?: RevelViolationCodes | null;
      summonsNumber: string;
      ticketIssuingAgency?: string | null;
      ticketUrl?: string | null;
      shift?: { __typename?: 'DriverShift'; id: string } | null;
    }>;
  };
};

export type GetDriversScreenQueryVariables = Exact<{
  filters: DriverFilters;
}>;

export type GetDriversScreenQuery = {
  __typename?: 'Query';
  drivers: {
    __typename?: 'DriversResponse';
    count: number;
    results: Array<{
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      employmentStatus?: DriverEmploymentStatus | null;
      dayforceXrefCode?: string | null;
      tlcLicenseNumber?: string | null;
      phoneNumber: string;
      firstShiftCompleted?: boolean | null;
      terminationDate?: any | null;
      employmentType?: EmploymentTypes | null;
      fleetResource?: { __typename?: 'FleetResource'; id: string; licensePlate: string } | null;
    }>;
  };
};

export type GetDriverQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverQuery = {
  __typename?: 'Query';
  getDriver?: {
    __typename?: 'Driver';
    id: string;
    avatar?: string | null;
    firstName: string;
    lastName: string;
    email?: string | null;
    phoneNumber: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    dayforceXrefCode?: string | null;
    tlcLicenseNumber?: string | null;
    tlcLicenseExpires?: Date | null;
    driverLicenseIssuer?: string | null;
    driverLicenseNumber?: string | null;
    terminationDate?: any | null;
    employmentStatus?: DriverEmploymentStatus | null;
    deletedAt?: any | null;
    employmentType?: EmploymentTypes | null;
    fleetResource?: { __typename?: 'FleetResource'; id: string; licensePlate: string } | null;
    otp?: { __typename?: 'DriverOTP'; expiresAt: any } | null;
    stats?: {
      __typename?: 'DriverStats';
      performanceStatus?: number | null;
      performanceStatusThresholds?: any | null;
    } | null;
  } | null;
  getDriverInsuranceApprovals: {
    __typename?: 'DriverInsuranceApprovalResponse';
    results: Array<{
      __typename?: 'DriverInsuranceApproval';
      id: string;
      insuranceProvider: { __typename?: 'InsuranceProvider'; id: string; displayName: string };
    } | null>;
  };
  getDriverVehicleExclusions: Array<{
    __typename?: 'DriverVehicleExclusionMakeAndModel';
    make: string;
    model: string;
  } | null>;
};

export type GetDriverAddressQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
  addressType?: InputMaybe<DriverAddressTypes>;
}>;

export type GetDriverAddressQuery = {
  __typename?: 'Query';
  listDriverAddresses: {
    __typename?: 'DriverAddressResponse';
    results?: Array<{
      __typename?: 'DriverAddress';
      address1: string;
      address2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      addressType: DriverAddressTypes;
    }> | null;
  };
};

export type GetDriverLicensesQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
  licenseType__in?: InputMaybe<Array<LicenseTypes> | LicenseTypes>;
}>;

export type GetDriverLicensesQuery = {
  __typename?: 'Query';
  listLicenses: {
    __typename?: 'LicenseResponse';
    results?: Array<{
      __typename?: 'License';
      id: string;
      updatedAt: any;
      licenseType: string;
      licenseNumber: string;
      expiresAt?: Date | null;
      status: string;
      photoFrontUrl?: string | null;
    }> | null;
  };
};

export type GetDriverShiftQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetDriverShiftQuery = {
  __typename?: 'Query';
  getShift?: {
    __typename?: 'DriverShift';
    id: string;
    state: DriverShiftStates;
    issueType?: DriverShiftIssueTypes | null;
    resourceId?: string | null;
    currentIssueId?: string | null;
    scheduledStartAt: any;
    scheduledEndAt: any;
    actualStartAt?: any | null;
    scheduledRecallAt?: any | null;
    clockIn?: any | null;
    clockOut?: any | null;
    reservationId?: string | null;
    depotId?: string | null;
    reservationType?: ReservationTypes | null;
    driverArrivedForShiftAt?: any | null;
    driver: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      dayforceXrefCode?: string | null;
      phoneNumber: string;
      firstShiftCompleted?: boolean | null;
    };
    depot?: {
      __typename?: 'Depot';
      id: string;
      name: string;
      vehicleFacility?: {
        __typename?: 'VehicleFacility';
        name: string;
        id: string;
        geofenceRadiusMeters: number;
        centerPoint: { __typename?: 'Point'; lat: number; lng: number };
      } | null;
    } | null;
    breaks?: Array<{
      __typename?: 'DriverShiftBreak';
      id: string;
      type?: DriverShiftBreakTypes | null;
      scheduledStartAt?: any | null;
      scheduledEndAt?: any | null;
      actualStartAt?: any | null;
      actualEndAt?: any | null;
    }> | null;
    note?: { __typename?: 'ShiftNote'; note: string } | null;
    settings?: {
      __typename?: 'ShiftSettings';
      dynamicRecallExtensionEnabled?: boolean | null;
      dynamicRecallReductionEnabled?: boolean | null;
      phoneNumber?: string | null;
      phoneType?: string | null;
      deviceType?: DeviceTypes | null;
      appVersion?: string | null;
    } | null;
    currentStopPoint?: {
      __typename?: 'DriverStopPoint';
      id: string;
      stopType: DriverStopPointTypes;
    } | null;
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      vehicle?: {
        __typename?: 'FleetResource';
        licensePlate: string;
        batteryLevel: number;
        id: string;
        detail?: {
          __typename?: 'FleetResourceDetail';
          make?: string | null;
          model?: string | null;
        } | null;
      } | null;
    } | null;
    stats?: {
      __typename?: 'ShiftStats';
      negativeRatingCount?: number | null;
      negativeRatingCountThreshold?: number | null;
      missedRideCount?: number | null;
      missedRideCountThreshold?: number | null;
      avgPickupDelaySeconds?: number | null;
      avgPickupDelaySecondsThreshold?: number | null;
      driverAvailabilityPercentage?: number | null;
      driverAvailabilityPercentageThreshold?: number | null;
      attendancePercentage?: number | null;
      attendancePercentageThreshold?: number | null;
      endOfShiftRecallDelaySeconds?: number | null;
      endOfShiftRecallDelaySecondsThreshold?: number | null;
      exemptions?: Array<string | null> | null;
      performancePayMultiplier?: number | null;
      driverPerformanceStatus?: number | null;
      performanceStatusThresholds?: any | null;
    } | null;
    shiftCancellation?: {
      __typename?: 'ShiftCancellation';
      shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
    } | null;
  } | null;
};

export type GetDriverOtpValidationCodeQueryVariables = Exact<{
  driverId: Scalars['ID']['input'];
}>;

export type GetDriverOtpValidationCodeQuery = {
  __typename?: 'Query';
  getDriverOTPVerificationCode?: {
    __typename?: 'DriverOTP';
    expiresAt: any;
    verificationCode?: string | null;
  } | null;
};

export type GenerateDriverAssetPresignedPostMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateDriverAssetPresignedPostMutation = {
  __typename?: 'Mutation';
  generateDriverAssetPresignedPost: {
    __typename?: 'DriverAssetPresignedPost';
    url: string;
    key: string;
    fields: any;
  };
};

export type UpdateDriverAvatarMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverAvatarMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: { __typename?: 'Driver'; avatar?: string | null };
};

export type UpdateDriverInfoMutationVariables = Exact<{
  driverId: Scalars['ID']['input'];
  input: UpdateInternalDriverProfileInput;
}>;

export type UpdateDriverInfoMutation = {
  __typename?: 'Mutation';
  updateInternalDriverProfile: {
    __typename?: 'Driver';
    firstName: string;
    lastName: string;
    email?: string | null;
    phoneNumber: string;
    tlcLicenseNumber?: string | null;
    tlcLicenseExpires?: Date | null;
    driverLicenseIssuer?: string | null;
    driverLicenseNumber?: string | null;
    employmentStatus?: DriverEmploymentStatus | null;
  };
};

export type InvoiceDriverInfoField_invoiceFragment = {
  __typename?: 'DriverInvoice';
  driver?: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    avatar?: string | null;
  } | null;
};

export type GetInvoicesScreenQueryVariables = Exact<{
  filters?: InputMaybe<DriverInvoiceFilters>;
}>;

export type GetInvoicesScreenQuery = {
  __typename?: 'Query';
  invoices: {
    __typename?: 'DriverInvoicesResponse';
    count: number;
    results?: Array<{
      __typename?: 'DriverInvoice';
      id: string;
      createdAt: any;
      subtotal: number;
      tax: number;
      taxPercentage: number;
      total: number;
      status: DriverInvoiceStatuses;
      descriptor?: DriverInvoiceDescriptors | null;
      appliedCredit?: number | null;
      driver?: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        email?: string | null;
        stripeCustomerId?: string | null;
      } | null;
      invoiceItems?: Array<{
        __typename?: 'DriverInvoiceItem';
        amount?: number | null;
        displayTitleText?: string | null;
        displayDetailText?: string | null;
        id?: string | null;
      }> | null;
      paymentIntents?: Array<{
        __typename?: 'DriverPaymentIntent';
        createdAt?: any | null;
        status?: DriverPaymentIntentStatuses | null;
        amount?: number | null;
        stripeExternalId?: string | null;
        paymentMethod?: {
          __typename?: 'DriverPaymentMethod';
          lastFour?: string | null;
          brand?: string | null;
          type?: DriverPaymentMethodTypes | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type GetInvoicesScreen_invoiceFragment = {
  __typename?: 'DriverInvoice';
  id: string;
  createdAt: any;
  subtotal: number;
  tax: number;
  taxPercentage: number;
  total: number;
  status: DriverInvoiceStatuses;
  descriptor?: DriverInvoiceDescriptors | null;
  appliedCredit?: number | null;
  driver?: {
    __typename?: 'Driver';
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    email?: string | null;
    stripeCustomerId?: string | null;
  } | null;
  invoiceItems?: Array<{
    __typename?: 'DriverInvoiceItem';
    amount?: number | null;
    displayTitleText?: string | null;
    displayDetailText?: string | null;
    id?: string | null;
  }> | null;
  paymentIntents?: Array<{
    __typename?: 'DriverPaymentIntent';
    createdAt?: any | null;
    status?: DriverPaymentIntentStatuses | null;
    amount?: number | null;
    stripeExternalId?: string | null;
    paymentMethod?: {
      __typename?: 'DriverPaymentMethod';
      lastFour?: string | null;
      brand?: string | null;
      type?: DriverPaymentMethodTypes | null;
    } | null;
  }> | null;
};

export type RetryInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID']['input'];
}>;

export type RetryInvoiceMutation = {
  __typename?: 'Mutation';
  retryDriverInvoice?: {
    __typename?: 'DriverInvoice';
    id: string;
    createdAt: any;
    subtotal: number;
    tax: number;
    taxPercentage: number;
    total: number;
    status: DriverInvoiceStatuses;
    descriptor?: DriverInvoiceDescriptors | null;
    appliedCredit?: number | null;
    driver?: {
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      email?: string | null;
      stripeCustomerId?: string | null;
    } | null;
    invoiceItems?: Array<{
      __typename?: 'DriverInvoiceItem';
      amount?: number | null;
      displayTitleText?: string | null;
      displayDetailText?: string | null;
      id?: string | null;
    }> | null;
    paymentIntents?: Array<{
      __typename?: 'DriverPaymentIntent';
      createdAt?: any | null;
      status?: DriverPaymentIntentStatuses | null;
      amount?: number | null;
      stripeExternalId?: string | null;
      paymentMethod?: {
        __typename?: 'DriverPaymentMethod';
        lastFour?: string | null;
        brand?: string | null;
        type?: DriverPaymentMethodTypes | null;
      } | null;
    }> | null;
  } | null;
};

export type GetSelectedShiftMapLocationQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetSelectedShiftMapLocationQuery = {
  __typename?: 'Query';
  shift?: {
    __typename?: 'DriverShift';
    vehicleLocation?: { __typename?: 'Point'; lat: number; lng: number } | null;
  } | null;
};

export type GetBalancerMapConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetBalancerMapConfigQuery = {
  __typename?: 'Query';
  config: {
    __typename?: 'DriverAppConfig';
    balancerMap: {
      __typename?: 'BalancerMapConfig';
      calculateRelativeBalance: boolean;
      showDeficit: boolean;
      minDeficit: number;
      maxDeficit: number;
      deficitColorLight: string;
      deficitColorDark: string;
      showSurplus: boolean;
      minSurplus: number;
      maxSurplus: number;
      surplusColorLight: string;
      surplusColorDark: string;
      minOpacity: number;
      maxOpacity: number;
    };
  };
};

export type GetBalancerMapQueryVariables = Exact<{ [key: string]: never }>;

export type GetBalancerMapQuery = { __typename?: 'Query'; getBalancerMap: any };

export type GetSelectedShiftMapRouteQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetSelectedShiftMapRouteQuery = {
  __typename?: 'Query';
  shift?: {
    __typename?: 'DriverShift';
    id: string;
    state: DriverShiftStates;
    issueType?: DriverShiftIssueTypes | null;
    liveRouteUrl?: string | null;
  } | null;
  stopPoints: {
    __typename?: 'ShiftStopPointResponse';
    count: number;
    results?: Array<{
      __typename?: 'DriverStopPoint';
      id: string;
      stopType: DriverStopPointTypes;
      assignedAt?: any | null;
      finalizedAt?: any | null;
      acknowledgedAt?: any | null;
      state: DriverStopPointStates;
      address: string;
      location: { __typename?: 'Point'; lat: number; lng: number };
    }> | null;
  };
};

export type GetDriverMapsScreenQueryVariables = Exact<{
  filters: DriverShiftFilters;
}>;

export type GetDriverMapsScreenQuery = {
  __typename?: 'Query';
  shifts: {
    __typename?: 'DriverShiftsResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverShift';
      id: string;
      state: DriverShiftStates;
      issueType?: DriverShiftIssueTypes | null;
      vehicleLocationUpdatedAt?: any | null;
      driver: { __typename?: 'Driver'; firstName: string; lastName: string };
      vehicleLocation?: { __typename?: 'Point'; lat: number; lng: number } | null;
      currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
    }>;
  };
};

export type GetDriversAndScheduleDetailsQueryVariables = Exact<{
  filters: DriverFilters;
  ptoFilters: DriverTimeAwayFilters;
  shiftFilters: DriverShiftFilters;
}>;

export type GetDriversAndScheduleDetailsQuery = {
  __typename?: 'Query';
  drivers: {
    __typename?: 'DriversResponse';
    count: number;
    results: Array<{
      __typename?: 'Driver';
      id: string;
      firstName: string;
      lastName: string;
      avatar?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      employmentType?: EmploymentTypes | null;
      shifts: Array<{
        __typename?: 'DriverShift';
        id: string;
        state: DriverShiftStates;
        issueType?: DriverShiftIssueTypes | null;
        scheduledStartAt: any;
        scheduledEndAt: any;
        actualStartAt?: any | null;
        actualEndAt?: any | null;
        scheduledRecallAt?: any | null;
        breaks?: Array<{
          __typename?: 'DriverShiftBreak';
          id: string;
          type?: DriverShiftBreakTypes | null;
          scheduledStartAt?: any | null;
          scheduledEndAt?: any | null;
        }> | null;
      }>;
      timeAway: Array<{
        __typename?: 'DriverTimeAway';
        id: string;
        scheduledStartAt: any;
        scheduledEndAt: any;
        status: DriverTimeAwayStatus;
      }>;
    }>;
  };
};

export type SearchForDriversQueryVariables = Exact<{
  filters?: InputMaybe<DriverOpsDriversFilters>;
}>;

export type SearchForDriversQuery = {
  __typename?: 'Query';
  getDriverOpsDrivers?: {
    __typename?: 'DriverOpsDriverListResponse';
    results?: Array<{
      __typename?: 'DriverOpsDriverAndDetails';
      id: string;
      avatar?: string | null;
      firstName: string;
      lastName: string;
    }> | null;
  } | null;
};

export type StopPointActionMenu_stopPointFragment = {
  __typename?: 'DriverStopPoint';
  id: string;
  stopType: DriverStopPointTypes;
  state: DriverStopPointStates;
};

export type ManipulateStopPointMutationVariables = Exact<{
  stopPointId: Scalars['ID']['input'];
  manipulationType: StopPointManipulationTypes;
}>;

export type ManipulateStopPointMutation = {
  __typename?: 'Mutation';
  stopPoint: { __typename?: 'DriverStopPoint'; id: string };
};

export type GetShiftStopPointsQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
  filters?: InputMaybe<ShiftStopPointsFilter>;
}>;

export type GetShiftStopPointsQuery = {
  __typename?: 'Query';
  stopPoints: {
    __typename?: 'ShiftStopPointResponse';
    count: number;
    results?: Array<{
      __typename?: 'DriverStopPoint';
      id: string;
      stopType: DriverStopPointTypes;
      assignedAt?: any | null;
      finalizedAt?: any | null;
      acknowledgedAt?: any | null;
      state: DriverStopPointStates;
      consumerRideHailId?: string | null;
      address: string;
      metadata?: { __typename?: 'StoppointMeta'; issueType?: DriverShiftIssueTypes | null } | null;
    }> | null;
  };
};

export type GetDriverShiftsScreenQueryVariables = Exact<{
  filters: DriverShiftFilters;
}>;

export type GetDriverShiftsScreenQuery = {
  __typename?: 'Query';
  shifts: {
    __typename?: 'DriverShiftsResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverShift';
      id: string;
      state: DriverShiftStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      scheduledRecallAt?: any | null;
      reservationType?: ReservationTypes | null;
      clockIn?: any | null;
      clockOut?: any | null;
      resourceId?: string | null;
      issueType?: DriverShiftIssueTypes | null;
      currentIssueId?: string | null;
      driverArrivedForShiftAt?: any | null;
      driver: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        firstShiftCompleted?: boolean | null;
      };
      fleetResourceMetadata?: {
        __typename?: 'FleetResourceMetadata';
        vehicle?: {
          __typename?: 'FleetResource';
          id: string;
          licensePlate: string;
          batteryLevel: number;
          detail?: {
            __typename?: 'FleetResourceDetail';
            make?: string | null;
            model?: string | null;
          } | null;
        } | null;
      } | null;
      currentStopPoint?: { __typename?: 'DriverStopPoint'; stopType: DriverStopPointTypes } | null;
      shiftCancellation?: {
        __typename?: 'ShiftCancellation';
        shiftCancellationReason: { __typename?: 'ShiftCancellationReason'; reason: string };
      } | null;
      note?: { __typename?: 'ShiftNote'; note: string } | null;
      depot?: { __typename?: 'Depot'; name: string } | null;
    }>;
  };
};

export type GetShiftResourceMetadataQueryVariables = Exact<{
  shiftId: Scalars['ID']['input'];
}>;

export type GetShiftResourceMetadataQuery = {
  __typename?: 'Query';
  getShiftResourceMetadata?: {
    __typename?: 'ShiftResourceMetadataResponse';
    results?: Array<{
      __typename?: 'ShiftResourceMetadata';
      id: string;
      resourceId: string;
      licensePlate?: string | null;
      driverReachedVehicleAt?: any | null;
      resourceAssignedAt?: any | null;
      resourceRemovedAt?: any | null;
    }> | null;
  } | null;
};

export type GetDriverFleetResourceQueryVariables = Exact<{
  fleetResourceId: Scalars['ID']['input'];
}>;

export type GetDriverFleetResourceQuery = {
  __typename?: 'Query';
  vehicle: {
    __typename?: 'DriverFleetResource';
    id: string;
    vin: string;
    licensePlate: string;
    batteryLevel?: number | null;
    telematicsProvider: string;
    status?: string | null;
    speedLimitPin?: string | null;
    location?: { __typename?: 'Point'; lat: number; lng: number } | null;
    detail: {
      __typename?: 'DriverFleetResourceDetail';
      make: string;
      model: string;
      year?: number | null;
    };
    fleetResourceMetadata?: {
      __typename?: 'FleetResourceMetadata';
      fhvLicenseNumber: string;
      isControllable?: boolean | null;
      tlcBase?: { __typename?: 'ResourceMetadataTLCBase'; name: string } | null;
      dispatchingTlcBase?: { __typename?: 'ResourceMetadataTLCBase'; name: string } | null;
      insuranceProvider?: { __typename?: 'InsuranceProvider'; displayName: string } | null;
      vehicle?: {
        __typename?: 'FleetResource';
        sensors?: { __typename?: 'Sensors'; updateTime?: any | null } | null;
      } | null;
    } | null;
    activeShift?: {
      __typename?: 'DriverShift';
      id: string;
      state: DriverShiftStates;
      scheduledStartAt: any;
      scheduledEndAt: any;
      driver: {
        __typename?: 'Driver';
        id: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
        firstShiftCompleted?: boolean | null;
      };
    } | null;
  };
};

export type GetVehiclesScreenQueryVariables = Exact<{
  filters: DriverFleetResourceFilters;
}>;

export type GetVehiclesScreenQuery = {
  __typename?: 'Query';
  vehicles: {
    __typename?: 'DriverFleetResourcesResponse';
    count: number;
    results: Array<{
      __typename?: 'DriverFleetResource';
      id: string;
      licensePlate: string;
      vin: string;
      batteryLevel?: number | null;
      status?: string | null;
      updated: any;
      detail: { __typename?: 'DriverFleetResourceDetail'; make: string; model: string };
      location?: { __typename?: 'Point'; lat: number; lng: number } | null;
    } | null>;
  };
};

export type GetVehicleCountsQueryVariables = Exact<{
  filters?: InputMaybe<DriverFleetResourceCountFilters>;
}>;

export type GetVehicleCountsQuery = { __typename?: 'Query'; getDriverFleetResourceCount: number };

export type ZoneGroup_zoneFragment = {
  __typename?: 'Zone';
  id: string;
  nameKey: string;
  zoneType: string;
  areaGeojsonString: string;
};

export type ZonePageZonesQueryVariables = Exact<{
  filters: ZoneFilters;
}>;

export type ZonePageZonesQuery = {
  __typename?: 'Query';
  zones?: {
    __typename?: 'ZoneList';
    results: Array<{
      __typename?: 'Zone';
      zoneType: string;
      id: string;
      nameKey: string;
      areaGeojsonString: string;
    }>;
  } | null;
};

export type GetNycSystemQueryQueryVariables = Exact<{ [key: string]: never }>;

export type GetNycSystemQueryQuery = {
  __typename?: 'Query';
  nycSystem?: {
    __typename?: 'System';
    id: string;
    settings?: { __typename?: 'SystemSettings'; lowBatteryTagPercentage?: number | null } | null;
  } | null;
};

export const AdvancedVehicleSelector_vehicleFacilityFragmentDoc = gql`
  fragment AdvancedVehicleSelector_vehicleFacility on VehicleFacility {
    name
    id
    geofenceRadiusMeters
    centerPoint {
      lat
      lng
    }
  }
`;
export const ShiftActionDropdown_shiftFragmentDoc = gql`
  fragment ShiftActionDropdown_shift on DriverShift {
    id
    state
    issueType
    currentIssueId
    clockIn
    scheduledStartAt
    scheduledEndAt
    reservationType
    note {
      note
    }
    driver {
      id
    }
    depot {
      name
    }
    fleetResourceMetadata {
      vehicle {
        id
        licensePlate
        detail {
          make
          model
        }
      }
    }
  }
`;
export const ShiftActionShortcuts_shiftFragmentDoc = gql`
  fragment ShiftActionShortcuts_shift on DriverShift {
    id
    state
    issueType
    currentIssueId
    note {
      note
    }
    driver {
      id
    }
  }
`;
export const ShiftClockOut_shiftFragmentDoc = gql`
  fragment ShiftClockOut_shift on DriverShift {
    clockIn
    clockOut
  }
`;
export const DriverShiftArrival_shiftFragmentDoc = gql`
  fragment DriverShiftArrival_shift on DriverShift {
    driverArrivedForShiftAt
    shiftCancellation {
      shiftCancellationReason {
        reason
      }
    }
  }
`;
export const ShiftDriverInfoField_shiftFragmentDoc = gql`
  fragment ShiftDriverInfoField_shift on DriverShift {
    state
    issueType
    currentIssueId
    reservationType
    driver {
      id
      firstName
      lastName
      avatar
      firstShiftCompleted
    }
    currentStopPoint {
      stopType
    }
  }
`;
export const ShiftInfoCard_shiftFragmentDoc = gql`
  fragment ShiftInfoCard_shift on DriverShift {
    ...DriverShiftArrival_shift
    ...ShiftDriverInfoField_shift
    driver {
      id
      dayforceXrefCode
      phoneNumber
    }
    settings {
      phoneNumber
      phoneType
      deviceType
      appVersion
    }
    depot {
      id
      name
    }
    id
    state
    scheduledStartAt
    scheduledEndAt
    scheduledRecallAt
    clockIn
    clockOut
    reservationType
    breaks {
      id
      type
      scheduledStartAt
      scheduledEndAt
      actualStartAt
      actualEndAt
    }
    fleetResourceMetadata {
      vehicle {
        id
        licensePlate
        batteryLevel
        detail {
          make
          model
        }
      }
    }
  }
  ${DriverShiftArrival_shiftFragmentDoc}
  ${ShiftDriverInfoField_shiftFragmentDoc}
`;
export const ShiftStatCard_shiftFragmentDoc = gql`
  fragment ShiftStatCard_shift on DriverShift {
    id
    stats {
      negativeRatingCount
      negativeRatingCountThreshold
      missedRideCount
      missedRideCountThreshold
      avgPickupDelaySeconds
      avgPickupDelaySecondsThreshold
      driverAvailabilityPercentage
      driverAvailabilityPercentageThreshold
      attendancePercentage
      attendancePercentageThreshold
      endOfShiftRecallDelaySeconds
      endOfShiftRecallDelaySecondsThreshold
      exemptions
      performancePayMultiplier
      driverPerformanceStatus
      performanceStatusThresholds
    }
  }
`;
export const ShiftInfoPanel_shiftFragmentDoc = gql`
  fragment ShiftInfoPanel_shift on DriverShift {
    id
    vehicleLocationUpdatedAt
    note {
      note
    }
    reservationType
    ...ShiftInfoCard_shift
    ...ShiftStatCard_shift
  }
  ${ShiftInfoCard_shiftFragmentDoc}
  ${ShiftStatCard_shiftFragmentDoc}
`;
export const ShiftMapMarker_shiftFragmentDoc = gql`
  fragment ShiftMapMarker_shift on DriverShift {
    id
    state
    issueType
    driver {
      firstName
      lastName
    }
    vehicleLocation {
      lat
      lng
    }
    vehicleLocationUpdatedAt
    currentStopPoint {
      stopType
    }
  }
`;
export const ShiftScheduledEnd_shiftFragmentDoc = gql`
  fragment ShiftScheduledEnd_shift on DriverShift {
    scheduledStartAt
    scheduledEndAt
  }
`;
export const ShiftVehicle_shiftFragmentDoc = gql`
  fragment ShiftVehicle_shift on DriverShift {
    id
    state
    resourceId
    depot {
      name
    }
    fleetResourceMetadata {
      vehicle {
        id
        licensePlate
        detail {
          make
          model
        }
      }
    }
  }
`;
export const VehicleInfoButton_fleetResourceFragmentDoc = gql`
  fragment VehicleInfoButton_fleetResource on FleetResource {
    id
    licensePlate
    detail {
      make
      model
    }
  }
`;
export const VehicleInfoPanel_vehicleFragmentDoc = gql`
  fragment VehicleInfoPanel_vehicle on DriverFleetResource {
    id
    vin
    licensePlate
    batteryLevel
    telematicsProvider
    status
    speedLimitPin
    location {
      lat
      lng
    }
    detail {
      make
      model
      year
    }
    fleetResourceMetadata {
      fhvLicenseNumber
      isControllable
      tlcBase {
        name
      }
      dispatchingTlcBase {
        name
      }
      insuranceProvider {
        displayName
      }
      vehicle {
        sensors {
          updateTime
        }
      }
    }
    activeShift {
      id
      state
      driver {
        id
        firstName
        lastName
        avatar
        firstShiftCompleted
      }
      scheduledStartAt
      scheduledEndAt
    }
  }
`;
export const VehicleLocationUpdateBadge_fleetResourceFragmentDoc = gql`
  fragment VehicleLocationUpdateBadge_fleetResource on FleetResource {
    sensors {
      updateTime
    }
  }
`;
export const VehicleMakeModelFragmentDoc = gql`
  fragment VehicleMakeModel on DriverFleetResourceDetail {
    make
    model
  }
`;
export const GetInvoiceTabInvoices_invoiceFragmentDoc = gql`
  fragment GetInvoiceTabInvoices_invoice on DriverInvoice {
    id
    createdAt
    subtotal
    tax
    taxPercentage
    total
    status
    descriptor
    appliedCredit
    driver {
      id
      firstName
      lastName
      avatar
      email
      stripeCustomerId
    }
    invoiceItems {
      amount
      displayTitleText
      displayDetailText
      id
    }
    paymentIntents {
      createdAt
      status
      amount
      stripeExternalId
      paymentMethod {
        lastFour
        brand
        type
      }
    }
  }
`;
export const InvoiceDriverInfoField_invoiceFragmentDoc = gql`
  fragment InvoiceDriverInfoField_invoice on DriverInvoice {
    driver {
      id
      firstName
      lastName
      email
      avatar
    }
  }
`;
export const GetInvoicesScreen_invoiceFragmentDoc = gql`
  fragment GetInvoicesScreen_invoice on DriverInvoice {
    id
    createdAt
    subtotal
    tax
    taxPercentage
    total
    status
    descriptor
    appliedCredit
    driver {
      id
      firstName
      lastName
      avatar
      email
      stripeCustomerId
    }
    invoiceItems {
      amount
      displayTitleText
      displayDetailText
      id
    }
    paymentIntents {
      createdAt
      status
      amount
      stripeExternalId
      paymentMethod {
        lastFour
        brand
        type
      }
    }
  }
`;
export const StopPointActionMenu_stopPointFragmentDoc = gql`
  fragment StopPointActionMenu_stopPoint on DriverStopPoint {
    id
    stopType
    state
  }
`;
export const ZoneGroup_zoneFragmentDoc = gql`
  fragment ZoneGroup_zone on Zone {
    id
    nameKey
    zoneType
    areaGeojsonString
  }
`;
export const GetDriverVehicleSelectorVehicles = gql`
  query GetDriverVehicleSelectorVehicles($filters: DriverFleetResourceFilters!) {
    vehicles: getDriverFleetResources(filters: $filters) {
      count
      results {
        id
        batteryLevel
        licensePlate
        location {
          lat
          lng
        }
        activeShift {
          state
          id
        }
        assignedDriver {
          firstName
          lastName
        }
        status
        detail {
          make
          model
        }
        fleetResourceMetadata {
          insuranceProvider {
            displayName
            id
          }
        }
      }
    }
  }
`;
export type GetDriverVehicleSelectorVehiclesQueryResult = Apollo.QueryResult<
  GetDriverVehicleSelectorVehiclesQuery,
  GetDriverVehicleSelectorVehiclesQueryVariables
>;
export const GetDepotSelectorDepots = gql`
  query GetDepotSelectorDepots {
    systems: getSystems(systemIdentifier: "newyork") {
      depots {
        id
        name
      }
    }
  }
`;
export type GetDepotSelectorDepotsQueryResult = Apollo.QueryResult<
  GetDepotSelectorDepotsQuery,
  GetDepotSelectorDepotsQueryVariables
>;
export const GetDriverSelectorDrivers = gql`
  query GetDriverSelectorDrivers($filters: DriverOpsDriversFilters) {
    drivers: getDriverOpsDrivers(filters: $filters) {
      results {
        id
        avatar
        firstName
        lastName
        dayforceXrefCode
      }
    }
  }
`;
export type GetDriverSelectorDriversQueryResult = Apollo.QueryResult<
  GetDriverSelectorDriversQuery,
  GetDriverSelectorDriversQueryVariables
>;
export const GetSystemsDepots = gql`
  query GetSystemsDepots($systemIdentifier: String!) {
    systems: getSystems(systemIdentifier: $systemIdentifier) {
      depots {
        id
        name
        address
        location {
          lat
          lng
        }
      }
    }
  }
`;
export type GetSystemsDepotsQueryResult = Apollo.QueryResult<
  GetSystemsDepotsQuery,
  GetSystemsDepotsQueryVariables
>;
export const GetDriverSystemZones = gql`
  query GetDriverSystemZones($systemId: ID!) {
    zones: getDriverSystemZones(systemId: $systemId) {
      id
      areaInvertedGeojsonString
    }
  }
`;
export type GetDriverSystemZonesQueryResult = Apollo.QueryResult<
  GetDriverSystemZonesQuery,
  GetDriverSystemZonesQueryVariables
>;
export const GetShiftInfo = gql`
  query GetShiftInfo($shiftId: ID!) {
    shift: getShift(shiftId: $shiftId) {
      ...ShiftInfoPanel_shift
      ...ShiftActionDropdown_shift
    }
  }
  ${ShiftInfoPanel_shiftFragmentDoc}
  ${ShiftActionDropdown_shiftFragmentDoc}
`;
export type GetShiftInfoQueryResult = Apollo.QueryResult<
  GetShiftInfoQuery,
  GetShiftInfoQueryVariables
>;
export const GetShiftMapMarkerInfo = gql`
  query GetShiftMapMarkerInfo($shiftId: ID!) {
    shift: getShift(shiftId: $shiftId) {
      ...ShiftMapMarker_shift
      fleetResourceMetadata {
        vehicle {
          licensePlate
          batteryLevel
        }
      }
    }
  }
  ${ShiftMapMarker_shiftFragmentDoc}
`;
export type GetShiftMapMarkerInfoQueryResult = Apollo.QueryResult<
  GetShiftMapMarkerInfoQuery,
  GetShiftMapMarkerInfoQueryVariables
>;
export const SpotlightDriverSearch = gql`
  query SpotlightDriverSearch($filters: DriverOpsDriversFilters) {
    drivers: getDriverOpsDrivers(filters: $filters) {
      results {
        id
        avatar
        firstName
        lastName
      }
    }
  }
`;
export type SpotlightDriverSearchQueryResult = Apollo.QueryResult<
  SpotlightDriverSearchQuery,
  SpotlightDriverSearchQueryVariables
>;
export const CommandResource = gql`
  mutation CommandResource($id: ID!, $input: CommandResourceInput!) {
    commandResource(id: $id, input: $input)
  }
`;
export type CommandResourceMutationFn = Apollo.MutationFunction<
  CommandResourceMutation,
  CommandResourceMutationVariables
>;
export type CommandResourceMutationResult = Apollo.MutationResult<CommandResourceMutation>;
export type CommandResourceMutationOptions = Apollo.BaseMutationOptions<
  CommandResourceMutation,
  CommandResourceMutationVariables
>;
export const GetVehicleFacilities = gql`
  query GetVehicleFacilities {
    vehicleFacilities: getVehicleFacilities {
      results {
        id
        name
      }
    }
  }
`;
export type GetVehicleFacilitiesQueryResult = Apollo.QueryResult<
  GetVehicleFacilitiesQuery,
  GetVehicleFacilitiesQueryVariables
>;
export const GetVehicleSelectorVehicles = gql`
  query GetVehicleSelectorVehicles($filters: ResourceFilters) {
    vehicles: getFleetResources(filters: $filters, cacheBuster: "deleteMe2") {
      id
      batteryLevel
      licensePlate
    }
  }
`;
export type GetVehicleSelectorVehiclesQueryResult = Apollo.QueryResult<
  GetVehicleSelectorVehiclesQuery,
  GetVehicleSelectorVehiclesQueryVariables
>;
export const IssueRentalCredit = gql`
  mutation IssueRentalCredit($input: IssueDriverCreditInput!) {
    issueDriverCredit(input: $input) {
      amountIssued
    }
  }
`;
export type IssueRentalCreditMutationFn = Apollo.MutationFunction<
  IssueRentalCreditMutation,
  IssueRentalCreditMutationVariables
>;
export type IssueRentalCreditMutationResult = Apollo.MutationResult<IssueRentalCreditMutation>;
export type IssueRentalCreditMutationOptions = Apollo.BaseMutationOptions<
  IssueRentalCreditMutation,
  IssueRentalCreditMutationVariables
>;
export const CalculateDriverVehicleCreditAmount = gql`
  query CalculateDriverVehicleCreditAmount(
    $minutes: Int!
    $vehicleType: DriverVehiclePreferenceType!
  ) {
    calculateDriverVehicleCreditAmount(minutes: $minutes, vehicleType: $vehicleType) {
      creditAmount
    }
  }
`;
export type CalculateDriverVehicleCreditAmountQueryResult = Apollo.QueryResult<
  CalculateDriverVehicleCreditAmountQuery,
  CalculateDriverVehicleCreditAmountQueryVariables
>;
export const GetDriverAvailableCredit = gql`
  query GetDriverAvailableCredit($driverId: ID) {
    getDriverAvailableCredit(driverId: $driverId)
  }
`;
export type GetDriverAvailableCreditQueryResult = Apollo.QueryResult<
  GetDriverAvailableCreditQuery,
  GetDriverAvailableCreditQueryVariables
>;
export const GetAssignVehicleToShiftDriverDetails = gql`
  query GetAssignVehicleToShiftDriverDetails($driverId: ID!) {
    approvals: getDriverInsuranceApprovals(filters: { driverId: $driverId }) {
      results {
        id
        insuranceProvider {
          displayName
          id
        }
      }
    }
    vehicleExlusions: getDriverVehicleExclusions(driverId: $driverId) {
      make
      model
    }
    vehiclePreference: getDriverVehiclePreference(driverId: $driverId) {
      preferredVehicleType
    }
  }
`;
export type GetAssignVehicleToShiftDriverDetailsQueryResult = Apollo.QueryResult<
  GetAssignVehicleToShiftDriverDetailsQuery,
  GetAssignVehicleToShiftDriverDetailsQueryVariables
>;
export const GetShiftCancellationReasons = gql`
  query GetShiftCancellationReasons {
    getShiftCancellationReasons {
      id
      displayName
      warningText
    }
  }
`;
export type GetShiftCancellationReasonsQueryResult = Apollo.QueryResult<
  GetShiftCancellationReasonsQuery,
  GetShiftCancellationReasonsQueryVariables
>;
export const CancelDriverShift = gql`
  mutation CancelDriverShift($input: CancelDriverOpsDriverShiftInput!) {
    cancelDriverOpsDriverShift(input: $input) {
      id
    }
  }
`;
export type CancelDriverShiftMutationFn = Apollo.MutationFunction<
  CancelDriverShiftMutation,
  CancelDriverShiftMutationVariables
>;
export type CancelDriverShiftMutationResult = Apollo.MutationResult<CancelDriverShiftMutation>;
export type CancelDriverShiftMutationOptions = Apollo.BaseMutationOptions<
  CancelDriverShiftMutation,
  CancelDriverShiftMutationVariables
>;
export const CheckInShiftOverride = gql`
  mutation CheckInShiftOverride($input: CheckInShiftOverrideInput!) {
    checkInShiftOverride(input: $input) {
      id
    }
  }
`;
export type CheckInShiftOverrideMutationFn = Apollo.MutationFunction<
  CheckInShiftOverrideMutation,
  CheckInShiftOverrideMutationVariables
>;
export type CheckInShiftOverrideMutationResult = Apollo.MutationResult<CheckInShiftOverrideMutation>;
export type CheckInShiftOverrideMutationOptions = Apollo.BaseMutationOptions<
  CheckInShiftOverrideMutation,
  CheckInShiftOverrideMutationVariables
>;
export const ReportDriverShiftIssue = gql`
  mutation ReportDriverShiftIssue($input: ReportDriverShiftIssueInput!) {
    reportDriverShiftIssue(input: $input) {
      id
    }
  }
`;
export type ReportDriverShiftIssueMutationFn = Apollo.MutationFunction<
  ReportDriverShiftIssueMutation,
  ReportDriverShiftIssueMutationVariables
>;
export type ReportDriverShiftIssueMutationResult = Apollo.MutationResult<ReportDriverShiftIssueMutation>;
export type ReportDriverShiftIssueMutationOptions = Apollo.BaseMutationOptions<
  ReportDriverShiftIssueMutation,
  ReportDriverShiftIssueMutationVariables
>;
export const CreateManualInvoice = gql`
  mutation CreateManualInvoice($input: CreateManualInvoiceInput!) {
    invoice: createManualInvoice(input: $input) {
      ...GetInvoiceTabInvoices_invoice
    }
  }
  ${GetInvoiceTabInvoices_invoiceFragmentDoc}
`;
export type CreateManualInvoiceMutationFn = Apollo.MutationFunction<
  CreateManualInvoiceMutation,
  CreateManualInvoiceMutationVariables
>;
export type CreateManualInvoiceMutationResult = Apollo.MutationResult<CreateManualInvoiceMutation>;
export type CreateManualInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateManualInvoiceMutation,
  CreateManualInvoiceMutationVariables
>;
export const CreateRecurringShift = gql`
  mutation CreateRecurringShift($input: CreateRecurringShiftInput!) {
    createRecurringShift(input: $input) {
      id
    }
  }
`;
export type CreateRecurringShiftMutationFn = Apollo.MutationFunction<
  CreateRecurringShiftMutation,
  CreateRecurringShiftMutationVariables
>;
export type CreateRecurringShiftMutationResult = Apollo.MutationResult<CreateRecurringShiftMutation>;
export type CreateRecurringShiftMutationOptions = Apollo.BaseMutationOptions<
  CreateRecurringShiftMutation,
  CreateRecurringShiftMutationVariables
>;
export const GetDriverEmploymentType = gql`
  query GetDriverEmploymentType($driverId: ID!) {
    driver: getDriver(id: $driverId) {
      id
      employmentType
    }
  }
`;
export type GetDriverEmploymentTypeQueryResult = Apollo.QueryResult<
  GetDriverEmploymentTypeQuery,
  GetDriverEmploymentTypeQueryVariables
>;
export const CreateDriverShift = gql`
  mutation CreateDriverShift($input: CreateDriverShiftInput!) {
    shift: createDriverShift(input: $input) {
      scheduledStartAt
      scheduledEndAt
    }
  }
`;
export type CreateDriverShiftMutationFn = Apollo.MutationFunction<
  CreateDriverShiftMutation,
  CreateDriverShiftMutationVariables
>;
export type CreateDriverShiftMutationResult = Apollo.MutationResult<CreateDriverShiftMutation>;
export type CreateDriverShiftMutationOptions = Apollo.BaseMutationOptions<
  CreateDriverShiftMutation,
  CreateDriverShiftMutationVariables
>;
export const DeleteRecurringShift = gql`
  mutation DeleteRecurringShift($id: ID!, $deleteFutureShifts: Boolean) {
    deleteRecurringShift(id: $id, deleteFutureShifts: $deleteFutureShifts) {
      id
    }
  }
`;
export type DeleteRecurringShiftMutationFn = Apollo.MutationFunction<
  DeleteRecurringShiftMutation,
  DeleteRecurringShiftMutationVariables
>;
export type DeleteRecurringShiftMutationResult = Apollo.MutationResult<DeleteRecurringShiftMutation>;
export type DeleteRecurringShiftMutationOptions = Apollo.BaseMutationOptions<
  DeleteRecurringShiftMutation,
  DeleteRecurringShiftMutationVariables
>;
export const CreateDriverContractConversionForm = gql`
  mutation CreateDriverContractConversionForm(
    $driverId: ID!
    $input: CreateDriverContractConversionFormInput!
  ) {
    createDriverContractConversionForm(driverId: $driverId, input: $input) {
      email
      convertAt
    }
  }
`;
export type CreateDriverContractConversionFormMutationFn = Apollo.MutationFunction<
  CreateDriverContractConversionFormMutation,
  CreateDriverContractConversionFormMutationVariables
>;
export type CreateDriverContractConversionFormMutationResult = Apollo.MutationResult<CreateDriverContractConversionFormMutation>;
export type CreateDriverContractConversionFormMutationOptions = Apollo.BaseMutationOptions<
  CreateDriverContractConversionFormMutation,
  CreateDriverContractConversionFormMutationVariables
>;
export const DeleteDriverContractConversionForm = gql`
  mutation DeleteDriverContractConversionForm($driverId: ID!) {
    deleteDriverContractConversionForm(driverId: $driverId) {
      id
    }
  }
`;
export type DeleteDriverContractConversionFormMutationFn = Apollo.MutationFunction<
  DeleteDriverContractConversionFormMutation,
  DeleteDriverContractConversionFormMutationVariables
>;
export type DeleteDriverContractConversionFormMutationResult = Apollo.MutationResult<DeleteDriverContractConversionFormMutation>;
export type DeleteDriverContractConversionFormMutationOptions = Apollo.BaseMutationOptions<
  DeleteDriverContractConversionFormMutation,
  DeleteDriverContractConversionFormMutationVariables
>;
export const GetDriverContractConversionForm = gql`
  query GetDriverContractConversionForm($driverId: ID!) {
    getDriverContractConversionForm(driverId: $driverId) {
      email
      convertAt
      completedAt
    }
  }
`;
export type GetDriverContractConversionFormQueryResult = Apollo.QueryResult<
  GetDriverContractConversionFormQuery,
  GetDriverContractConversionFormQueryVariables
>;
export const UpdateDriverAddress = gql`
  mutation UpdateDriverAddress($driverId: ID!, $input: UpdateInternalDriverProfileInput!) {
    updateInternalDriverProfile(id: $driverId, input: $input) {
      updatedAt
    }
  }
`;
export type UpdateDriverAddressMutationFn = Apollo.MutationFunction<
  UpdateDriverAddressMutation,
  UpdateDriverAddressMutationVariables
>;
export type UpdateDriverAddressMutationResult = Apollo.MutationResult<UpdateDriverAddressMutation>;
export type UpdateDriverAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverAddressMutation,
  UpdateDriverAddressMutationVariables
>;
export const UpdateDriverInsuranceApprovals = gql`
  mutation UpdateDriverInsuranceApprovals($input: UpdateDriverInsuranceApprovalInput!) {
    updateDriverInsuranceApprovals(input: $input) {
      id
    }
  }
`;
export type UpdateDriverInsuranceApprovalsMutationFn = Apollo.MutationFunction<
  UpdateDriverInsuranceApprovalsMutation,
  UpdateDriverInsuranceApprovalsMutationVariables
>;
export type UpdateDriverInsuranceApprovalsMutationResult = Apollo.MutationResult<UpdateDriverInsuranceApprovalsMutation>;
export type UpdateDriverInsuranceApprovalsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverInsuranceApprovalsMutation,
  UpdateDriverInsuranceApprovalsMutationVariables
>;
export const GetInsuranceProviders = gql`
  query GetInsuranceProviders {
    insuranceProviders: getInsuranceProviders {
      results {
        displayName
        id
      }
    }
  }
`;
export type GetInsuranceProvidersQueryResult = Apollo.QueryResult<
  GetInsuranceProvidersQuery,
  GetInsuranceProvidersQueryVariables
>;
export const UpdateDriverVehicleExclusions = gql`
  mutation UpdateDriverVehicleExclusions($input: UpdateDriverVehicleExclusionsInput!) {
    updateDriverVehicleExclusions(input: $input) {
      make
      model
    }
  }
`;
export type UpdateDriverVehicleExclusionsMutationFn = Apollo.MutationFunction<
  UpdateDriverVehicleExclusionsMutation,
  UpdateDriverVehicleExclusionsMutationVariables
>;
export type UpdateDriverVehicleExclusionsMutationResult = Apollo.MutationResult<UpdateDriverVehicleExclusionsMutation>;
export type UpdateDriverVehicleExclusionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverVehicleExclusionsMutation,
  UpdateDriverVehicleExclusionsMutationVariables
>;
export const GetDriverFleetResourceDetails = gql`
  query GetDriverFleetResourceDetails {
    fleetResourceDetails: getDriverFleetResourceDetails {
      results {
        ...VehicleMakeModel
      }
    }
  }
  ${VehicleMakeModelFragmentDoc}
`;
export type GetDriverFleetResourceDetailsQueryResult = Apollo.QueryResult<
  GetDriverFleetResourceDetailsQuery,
  GetDriverFleetResourceDetailsQueryVariables
>;
export const UpdateDriverVehicle = gql`
  mutation UpdateDriverVehicle($driverId: ID!, $input: UpdateInternalDriverProfileInput!) {
    updateInternalDriverProfile(id: $driverId, input: $input) {
      fleetResourceId
    }
  }
`;
export type UpdateDriverVehicleMutationFn = Apollo.MutationFunction<
  UpdateDriverVehicleMutation,
  UpdateDriverVehicleMutationVariables
>;
export type UpdateDriverVehicleMutationResult = Apollo.MutationResult<UpdateDriverVehicleMutation>;
export type UpdateDriverVehicleMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverVehicleMutation,
  UpdateDriverVehicleMutationVariables
>;
export const GetDriverVehiclePreference = gql`
  query GetDriverVehiclePreference($driverId: ID!) {
    getDriverVehiclePreference(driverId: $driverId) {
      preferredVehicleType
    }
  }
`;
export type GetDriverVehiclePreferenceQueryResult = Apollo.QueryResult<
  GetDriverVehiclePreferenceQuery,
  GetDriverVehiclePreferenceQueryVariables
>;
export const UpdateDriverVehiclePreference = gql`
  mutation UpdateDriverVehiclePreference($input: UpdateDriverVehiclePreferenceInput!) {
    updateDriverVehiclePreference(input: $input) {
      preferredVehicleType
    }
  }
`;
export type UpdateDriverVehiclePreferenceMutationFn = Apollo.MutationFunction<
  UpdateDriverVehiclePreferenceMutation,
  UpdateDriverVehiclePreferenceMutationVariables
>;
export type UpdateDriverVehiclePreferenceMutationResult = Apollo.MutationResult<UpdateDriverVehiclePreferenceMutation>;
export type UpdateDriverVehiclePreferenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverVehiclePreferenceMutation,
  UpdateDriverVehiclePreferenceMutationVariables
>;
export const UpdateDriverWeeklyRental = gql`
  mutation UpdateDriverWeeklyRental($driverId: ID!, $input: UpdateInternalDriverProfileInput!) {
    updateInternalDriverProfile(id: $driverId, input: $input) {
      fleetResourceId
    }
  }
`;
export type UpdateDriverWeeklyRentalMutationFn = Apollo.MutationFunction<
  UpdateDriverWeeklyRentalMutation,
  UpdateDriverWeeklyRentalMutationVariables
>;
export type UpdateDriverWeeklyRentalMutationResult = Apollo.MutationResult<UpdateDriverWeeklyRentalMutation>;
export type UpdateDriverWeeklyRentalMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverWeeklyRentalMutation,
  UpdateDriverWeeklyRentalMutationVariables
>;
export const GetReservationBillingSettings = gql`
  query GetReservationBillingSettings($driverId: ID!) {
    getReservationBillingSettings(driverId: $driverId) {
      id
      ezPassRental
      insurancePremiumTier
    }
  }
`;
export type GetReservationBillingSettingsQueryResult = Apollo.QueryResult<
  GetReservationBillingSettingsQuery,
  GetReservationBillingSettingsQueryVariables
>;
export const UpdateRecurringShift = gql`
  mutation UpdateRecurringShift($id: ID!, $input: UpdateRecurringShiftInput!) {
    updateRecurringShift(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateRecurringShiftMutationFn = Apollo.MutationFunction<
  UpdateRecurringShiftMutation,
  UpdateRecurringShiftMutationVariables
>;
export type UpdateRecurringShiftMutationResult = Apollo.MutationResult<UpdateRecurringShiftMutation>;
export type UpdateRecurringShiftMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecurringShiftMutation,
  UpdateRecurringShiftMutationVariables
>;
export const UpdateRecurringShiftSettings = gql`
  mutation UpdateRecurringShiftSettings($id: ID!, $input: ShiftSettingsInput!) {
    updateRecurringShiftSettings(id: $id, input: $input) {
      dynamicRecallExtensionEnabled
      dynamicRecallReductionEnabled
    }
  }
`;
export type UpdateRecurringShiftSettingsMutationFn = Apollo.MutationFunction<
  UpdateRecurringShiftSettingsMutation,
  UpdateRecurringShiftSettingsMutationVariables
>;
export type UpdateRecurringShiftSettingsMutationResult = Apollo.MutationResult<UpdateRecurringShiftSettingsMutation>;
export type UpdateRecurringShiftSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecurringShiftSettingsMutation,
  UpdateRecurringShiftSettingsMutationVariables
>;
export const GetIssueDetailsModalIssue = gql`
  query GetIssueDetailsModalIssue($issueId: ID!) {
    issue: getShiftIssue(id: $issueId) {
      id
      issueType
      reportComment
      imageUrls
      startedAt
      shiftId
      shift {
        ...ShiftInfoCard_shift
      }
    }
  }
  ${ShiftInfoCard_shiftFragmentDoc}
`;
export type GetIssueDetailsModalIssueQueryResult = Apollo.QueryResult<
  GetIssueDetailsModalIssueQuery,
  GetIssueDetailsModalIssueQueryVariables
>;
export const ResolveDriverShiftIssue = gql`
  mutation ResolveDriverShiftIssue($shiftId: ID!) {
    resolveDriverShiftIssue(shiftId: $shiftId) {
      id
      issueType
    }
  }
`;
export type ResolveDriverShiftIssueMutationFn = Apollo.MutationFunction<
  ResolveDriverShiftIssueMutation,
  ResolveDriverShiftIssueMutationVariables
>;
export type ResolveDriverShiftIssueMutationResult = Apollo.MutationResult<ResolveDriverShiftIssueMutation>;
export type ResolveDriverShiftIssueMutationOptions = Apollo.BaseMutationOptions<
  ResolveDriverShiftIssueMutation,
  ResolveDriverShiftIssueMutationVariables
>;
export const SubmitUpdateLicenseReview = gql`
  mutation SubmitUpdateLicenseReview(
    $licenseID: ID!
    $updateLicenseInput: UpdateLicenseInput
    $reviewInput: ReviewLicenseInput
  ) {
    submitUpdateLicenseReview(
      id: $licenseID
      updatedLicense: $updateLicenseInput
      review: $reviewInput
    ) {
      id
    }
  }
`;
export type SubmitUpdateLicenseReviewMutationFn = Apollo.MutationFunction<
  SubmitUpdateLicenseReviewMutation,
  SubmitUpdateLicenseReviewMutationVariables
>;
export type SubmitUpdateLicenseReviewMutationResult = Apollo.MutationResult<SubmitUpdateLicenseReviewMutation>;
export type SubmitUpdateLicenseReviewMutationOptions = Apollo.BaseMutationOptions<
  SubmitUpdateLicenseReviewMutation,
  SubmitUpdateLicenseReviewMutationVariables
>;
export const UpdateDriverShift = gql`
  mutation UpdateDriverShift($input: UpdateDriverShiftInput!) {
    updateDriverShift(input: $input) {
      id
    }
  }
`;
export type UpdateDriverShiftMutationFn = Apollo.MutationFunction<
  UpdateDriverShiftMutation,
  UpdateDriverShiftMutationVariables
>;
export type UpdateDriverShiftMutationResult = Apollo.MutationResult<UpdateDriverShiftMutation>;
export type UpdateDriverShiftMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverShiftMutation,
  UpdateDriverShiftMutationVariables
>;
export const UpdateShiftClockTimes = gql`
  mutation UpdateShiftClockTimes($input: UpdateShiftClockTimesInput!) {
    updateShiftClockTimes(input: $input) {
      id
    }
  }
`;
export type UpdateShiftClockTimesMutationFn = Apollo.MutationFunction<
  UpdateShiftClockTimesMutation,
  UpdateShiftClockTimesMutationVariables
>;
export type UpdateShiftClockTimesMutationResult = Apollo.MutationResult<UpdateShiftClockTimesMutation>;
export type UpdateShiftClockTimesMutationOptions = Apollo.BaseMutationOptions<
  UpdateShiftClockTimesMutation,
  UpdateShiftClockTimesMutationVariables
>;
export const UpdateShiftSettings = gql`
  mutation UpdateShiftSettings($id: ID!, $input: ShiftSettingsInput!) {
    updateShiftSettings(id: $id, input: $input) {
      dynamicRecallExtensionEnabled
      dynamicRecallReductionEnabled
    }
  }
`;
export type UpdateShiftSettingsMutationFn = Apollo.MutationFunction<
  UpdateShiftSettingsMutation,
  UpdateShiftSettingsMutationVariables
>;
export type UpdateShiftSettingsMutationResult = Apollo.MutationResult<UpdateShiftSettingsMutation>;
export type UpdateShiftSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateShiftSettingsMutation,
  UpdateShiftSettingsMutationVariables
>;
export const AssignVehicleToShift = gql`
  mutation AssignVehicleToShift($id: ID!, $fleetResourceId: ID!) {
    assignVehicleToShift(id: $id, fleetResourceId: $fleetResourceId) {
      id
      resourceId
    }
  }
`;
export type AssignVehicleToShiftMutationFn = Apollo.MutationFunction<
  AssignVehicleToShiftMutation,
  AssignVehicleToShiftMutationVariables
>;
export type AssignVehicleToShiftMutationResult = Apollo.MutationResult<AssignVehicleToShiftMutation>;
export type AssignVehicleToShiftMutationOptions = Apollo.BaseMutationOptions<
  AssignVehicleToShiftMutation,
  AssignVehicleToShiftMutationVariables
>;
export const RemoveAssignedVehicleFromShift = gql`
  mutation RemoveAssignedVehicleFromShift($id: ID!) {
    removeAssignedVehicleFromShift(id: $id) {
      id
      resourceId
    }
  }
`;
export type RemoveAssignedVehicleFromShiftMutationFn = Apollo.MutationFunction<
  RemoveAssignedVehicleFromShiftMutation,
  RemoveAssignedVehicleFromShiftMutationVariables
>;
export type RemoveAssignedVehicleFromShiftMutationResult = Apollo.MutationResult<RemoveAssignedVehicleFromShiftMutation>;
export type RemoveAssignedVehicleFromShiftMutationOptions = Apollo.BaseMutationOptions<
  RemoveAssignedVehicleFromShiftMutation,
  RemoveAssignedVehicleFromShiftMutationVariables
>;
export const EndReservationShiftOverride = gql`
  mutation EndReservationShiftOverride($input: CheckInShiftOverrideInput!) {
    endReservationShiftOverride(input: $input) {
      id
      resourceId
    }
  }
`;
export type EndReservationShiftOverrideMutationFn = Apollo.MutationFunction<
  EndReservationShiftOverrideMutation,
  EndReservationShiftOverrideMutationVariables
>;
export type EndReservationShiftOverrideMutationResult = Apollo.MutationResult<EndReservationShiftOverrideMutation>;
export type EndReservationShiftOverrideMutationOptions = Apollo.BaseMutationOptions<
  EndReservationShiftOverrideMutation,
  EndReservationShiftOverrideMutationVariables
>;
export const UpdateShiftNote = gql`
  mutation UpdateShiftNote($id: ID!, $note: String!) {
    updateShiftNote(id: $id, note: $note) {
      createdAt
      createdByIdentityId
      note
    }
  }
`;
export type UpdateShiftNoteMutationFn = Apollo.MutationFunction<
  UpdateShiftNoteMutation,
  UpdateShiftNoteMutationVariables
>;
export type UpdateShiftNoteMutationResult = Apollo.MutationResult<UpdateShiftNoteMutation>;
export type UpdateShiftNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateShiftNoteMutation,
  UpdateShiftNoteMutationVariables
>;
export const UpdateShiftExemptions = gql`
  mutation UpdateShiftExemptions($id: ID!, $input: ShiftExemptionInput!) {
    updateShiftExemptions(id: $id, input: $input) {
      exemptions
    }
  }
`;
export type UpdateShiftExemptionsMutationFn = Apollo.MutationFunction<
  UpdateShiftExemptionsMutation,
  UpdateShiftExemptionsMutationVariables
>;
export type UpdateShiftExemptionsMutationResult = Apollo.MutationResult<UpdateShiftExemptionsMutation>;
export type UpdateShiftExemptionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateShiftExemptionsMutation,
  UpdateShiftExemptionsMutationVariables
>;
export const StandbyReservation = gql`
  mutation StandbyReservation($reservationId: ID!) {
    standbyReservation(reservationId: $reservationId) {
      id
    }
  }
`;
export type StandbyReservationMutationFn = Apollo.MutationFunction<
  StandbyReservationMutation,
  StandbyReservationMutationVariables
>;
export type StandbyReservationMutationResult = Apollo.MutationResult<StandbyReservationMutation>;
export type StandbyReservationMutationOptions = Apollo.BaseMutationOptions<
  StandbyReservationMutation,
  StandbyReservationMutationVariables
>;
export const ResendDocusignDocuments = gql`
  mutation ResendDocusignDocuments($input: ResendDocusignDocumentsInput!) {
    resendDocusignDocuments(input: $input)
  }
`;
export type ResendDocusignDocumentsMutationFn = Apollo.MutationFunction<
  ResendDocusignDocumentsMutation,
  ResendDocusignDocumentsMutationVariables
>;
export type ResendDocusignDocumentsMutationResult = Apollo.MutationResult<ResendDocusignDocumentsMutation>;
export type ResendDocusignDocumentsMutationOptions = Apollo.BaseMutationOptions<
  ResendDocusignDocumentsMutation,
  ResendDocusignDocumentsMutationVariables
>;
export const ResendBackgroundCheck = gql`
  mutation ResendBackgroundCheck($input: ResendBackgroundCheckInput!) {
    resendBackgroundCheck(input: $input)
  }
`;
export type ResendBackgroundCheckMutationFn = Apollo.MutationFunction<
  ResendBackgroundCheckMutation,
  ResendBackgroundCheckMutationVariables
>;
export type ResendBackgroundCheckMutationResult = Apollo.MutationResult<ResendBackgroundCheckMutation>;
export type ResendBackgroundCheckMutationOptions = Apollo.BaseMutationOptions<
  ResendBackgroundCheckMutation,
  ResendBackgroundCheckMutationVariables
>;
export const GetDriverRestrictionsContent = gql`
  query GetDriverRestrictionsContent($filters: DriverRestrictionStatusContentFilters) {
    restrictionsContent: getDriverRestrictionStatusContent(filters: $filters) {
      groups {
        id
        title
        description
        items {
          key
          id
          label
          value
          description
          link {
            text
            href
          }
        }
      }
    }
  }
`;
export type GetDriverRestrictionsContentQueryResult = Apollo.QueryResult<
  GetDriverRestrictionsContentQuery,
  GetDriverRestrictionsContentQueryVariables
>;
export const GetInvoiceTabInvoices = gql`
  query GetInvoiceTabInvoices($filters: DriverInvoiceFilters) {
    invoices: getDriverInvoices(filters: $filters) {
      count
      results {
        ...GetInvoiceTabInvoices_invoice
      }
    }
  }
  ${GetInvoiceTabInvoices_invoiceFragmentDoc}
`;
export type GetInvoiceTabInvoicesQueryResult = Apollo.QueryResult<
  GetInvoiceTabInvoicesQuery,
  GetInvoiceTabInvoicesQueryVariables
>;
export const ListRecurringShifts = gql`
  query ListRecurringShifts($filters: ListRecurringShiftsFilters) {
    listRecurringShifts(filters: $filters) {
      id
      startWeekday
      endWeekday
      startTime
      endTime
      depot {
        id
        name
      }
    }
  }
`;
export type ListRecurringShiftsQueryResult = Apollo.QueryResult<
  ListRecurringShiftsQuery,
  ListRecurringShiftsQueryVariables
>;
export const GetRecurringShift = gql`
  query GetRecurringShift($id: ID!) {
    getRecurringShift(id: $id) {
      id
      startWeekday
      endWeekday
      startTime
      endTime
      depotId
      depot {
        id
        name
        systemId
      }
      driver {
        id
        firstName
        lastName
        dayforceXrefCode
        employmentType
      }
      createdAt
      deletedAt
      generatedUntil
      settings {
        dynamicRecallExtensionEnabled
        dynamicRecallReductionEnabled
      }
    }
  }
`;
export type GetRecurringShiftQueryResult = Apollo.QueryResult<
  GetRecurringShiftQuery,
  GetRecurringShiftQueryVariables
>;
export const GetShiftsTabShifts = gql`
  query GetShiftsTabShifts($filters: DriverShiftFilters!) {
    shifts: getShifts(filters: $filters) {
      count
      results {
        issueType
        currentIssueId
        ...ShiftScheduledEnd_shift
        ...ShiftClockOut_shift
        ...DriverShiftArrival_shift
        ...ShiftActionShortcuts_shift
        ...ShiftActionDropdown_shift
        id
        driver {
          id
          firstName
          lastName
          avatar
        }
        state
        scheduledStartAt
        scheduledEndAt
        actualStartAt
        scheduledRecallAt
        clockIn
        clockOut
        reservationType
        currentStopPoint {
          stopType
        }
      }
    }
  }
  ${ShiftScheduledEnd_shiftFragmentDoc}
  ${ShiftClockOut_shiftFragmentDoc}
  ${DriverShiftArrival_shiftFragmentDoc}
  ${ShiftActionShortcuts_shiftFragmentDoc}
  ${ShiftActionDropdown_shiftFragmentDoc}
`;
export type GetShiftsTabShiftsQueryResult = Apollo.QueryResult<
  GetShiftsTabShiftsQuery,
  GetShiftsTabShiftsQueryVariables
>;
export const ListTrafficViolationTickets = gql`
  query ListTrafficViolationTickets($filters: TrafficViolationTicketFilters) {
    listTrafficViolationTickets(filters: $filters) {
      count
      results {
        id
        fineAmount
        issuedAt
        createdAt
        resourceId
        licensePlate
        revelViolationCode
        summonsNumber
        ticketIssuingAgency
        ticketUrl
        shift {
          id
        }
      }
    }
  }
`;
export type ListTrafficViolationTicketsQueryResult = Apollo.QueryResult<
  ListTrafficViolationTicketsQuery,
  ListTrafficViolationTicketsQueryVariables
>;
export const GetDriversScreen = gql`
  query GetDriversScreen($filters: DriverFilters!) {
    drivers: getDrivers(filters: $filters) {
      count
      results {
        id
        firstName
        lastName
        avatar
        employmentStatus
        dayforceXrefCode
        tlcLicenseNumber
        phoneNumber
        firstShiftCompleted
        terminationDate
        employmentType
        fleetResource {
          id
          licensePlate
        }
      }
    }
  }
`;
export type GetDriversScreenQueryResult = Apollo.QueryResult<
  GetDriversScreenQuery,
  GetDriversScreenQueryVariables
>;
export const GetDriver = gql`
  query GetDriver($driverId: ID!) {
    getDriver(id: $driverId) {
      id
      avatar
      firstName
      lastName
      email
      phoneNumber
      createdAt
      updatedAt
      dayforceXrefCode
      tlcLicenseNumber
      tlcLicenseExpires
      driverLicenseIssuer
      driverLicenseNumber
      terminationDate
      employmentStatus
      deletedAt
      employmentType
      fleetResource {
        id
        licensePlate
      }
      otp {
        expiresAt
      }
      stats {
        performanceStatus
        performanceStatusThresholds
      }
    }
    getDriverInsuranceApprovals(filters: { driverId: $driverId }) {
      results {
        id
        insuranceProvider {
          id
          displayName
        }
      }
    }
    getDriverVehicleExclusions(driverId: $driverId) {
      make
      model
    }
  }
`;
export type GetDriverQueryResult = Apollo.QueryResult<GetDriverQuery, GetDriverQueryVariables>;
export const GetDriverAddress = gql`
  query GetDriverAddress($driverId: ID!, $addressType: DriverAddressTypes) {
    listDriverAddresses(filters: { driverId: $driverId, addressType: $addressType }) {
      results {
        address1
        address2
        city
        state
        postalCode
        addressType
      }
    }
  }
`;
export type GetDriverAddressQueryResult = Apollo.QueryResult<
  GetDriverAddressQuery,
  GetDriverAddressQueryVariables
>;
export const GetDriverLicenses = gql`
  query GetDriverLicenses($driverId: ID!, $licenseType__in: [LicenseTypes!]) {
    listLicenses(filters: { driverId: $driverId, licenseType__in: $licenseType__in }) {
      results {
        id
        updatedAt
        licenseType
        licenseNumber
        expiresAt
        status
        photoFrontUrl
      }
    }
  }
`;
export type GetDriverLicensesQueryResult = Apollo.QueryResult<
  GetDriverLicensesQuery,
  GetDriverLicensesQueryVariables
>;
export const GetDriverShift = gql`
  query GetDriverShift($shiftId: ID!) {
    getShift(shiftId: $shiftId) {
      id
      state
      issueType
      resourceId
      currentIssueId
      scheduledStartAt
      scheduledEndAt
      actualStartAt
      scheduledRecallAt
      clockIn
      clockOut
      reservationId
      driver {
        id
        firstName
        lastName
        avatar
        dayforceXrefCode
        phoneNumber
        firstShiftCompleted
      }
      depotId
      depot {
        id
        name
        vehicleFacility {
          ...AdvancedVehicleSelector_vehicleFacility
        }
      }
      breaks {
        id
        type
        scheduledStartAt
        scheduledEndAt
      }
      note {
        note
      }
      settings {
        dynamicRecallExtensionEnabled
        dynamicRecallReductionEnabled
        phoneNumber
        phoneType
      }
      currentStopPoint {
        id
        stopType
      }
      fleetResourceMetadata {
        vehicle {
          licensePlate
          batteryLevel
          detail {
            make
            model
          }
        }
      }
      stats {
        negativeRatingCount
        negativeRatingCountThreshold
        missedRideCount
        missedRideCountThreshold
        avgPickupDelaySeconds
        avgPickupDelaySecondsThreshold
        driverAvailabilityPercentage
        driverAvailabilityPercentageThreshold
        attendancePercentage
        attendancePercentageThreshold
        endOfShiftRecallDelaySeconds
        endOfShiftRecallDelaySecondsThreshold
        exemptions
        performancePayMultiplier
        driverPerformanceStatus
        performanceStatusThresholds
      }
      ...ShiftInfoCard_shift
    }
  }
  ${AdvancedVehicleSelector_vehicleFacilityFragmentDoc}
  ${ShiftInfoCard_shiftFragmentDoc}
`;
export type GetDriverShiftQueryResult = Apollo.QueryResult<
  GetDriverShiftQuery,
  GetDriverShiftQueryVariables
>;
export const GetDriverOtpValidationCode = gql`
  query GetDriverOtpValidationCode($driverId: ID!) {
    getDriverOTPVerificationCode(id: $driverId) {
      expiresAt
      verificationCode
    }
  }
`;
export type GetDriverOtpValidationCodeQueryResult = Apollo.QueryResult<
  GetDriverOtpValidationCodeQuery,
  GetDriverOtpValidationCodeQueryVariables
>;
export const GenerateDriverAssetPresignedPost = gql`
  mutation GenerateDriverAssetPresignedPost {
    generateDriverAssetPresignedPost {
      url
      key
      fields
    }
  }
`;
export type GenerateDriverAssetPresignedPostMutationFn = Apollo.MutationFunction<
  GenerateDriverAssetPresignedPostMutation,
  GenerateDriverAssetPresignedPostMutationVariables
>;
export type GenerateDriverAssetPresignedPostMutationResult = Apollo.MutationResult<GenerateDriverAssetPresignedPostMutation>;
export type GenerateDriverAssetPresignedPostMutationOptions = Apollo.BaseMutationOptions<
  GenerateDriverAssetPresignedPostMutation,
  GenerateDriverAssetPresignedPostMutationVariables
>;
export const UpdateDriverAvatar = gql`
  mutation UpdateDriverAvatar($driverId: ID!, $input: UpdateInternalDriverProfileInput!) {
    updateInternalDriverProfile(id: $driverId, input: $input) {
      avatar
    }
  }
`;
export type UpdateDriverAvatarMutationFn = Apollo.MutationFunction<
  UpdateDriverAvatarMutation,
  UpdateDriverAvatarMutationVariables
>;
export type UpdateDriverAvatarMutationResult = Apollo.MutationResult<UpdateDriverAvatarMutation>;
export type UpdateDriverAvatarMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverAvatarMutation,
  UpdateDriverAvatarMutationVariables
>;
export const UpdateDriverInfo = gql`
  mutation UpdateDriverInfo($driverId: ID!, $input: UpdateInternalDriverProfileInput!) {
    updateInternalDriverProfile(id: $driverId, input: $input) {
      firstName
      lastName
      email
      phoneNumber
      email
      tlcLicenseNumber
      tlcLicenseExpires
      driverLicenseIssuer
      driverLicenseNumber
      employmentStatus
    }
  }
`;
export type UpdateDriverInfoMutationFn = Apollo.MutationFunction<
  UpdateDriverInfoMutation,
  UpdateDriverInfoMutationVariables
>;
export type UpdateDriverInfoMutationResult = Apollo.MutationResult<UpdateDriverInfoMutation>;
export type UpdateDriverInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverInfoMutation,
  UpdateDriverInfoMutationVariables
>;
export const GetInvoicesScreen = gql`
  query GetInvoicesScreen($filters: DriverInvoiceFilters) {
    invoices: getDriverInvoices(filters: $filters) {
      count
      results {
        ...GetInvoicesScreen_invoice
      }
    }
  }
  ${GetInvoicesScreen_invoiceFragmentDoc}
`;
export type GetInvoicesScreenQueryResult = Apollo.QueryResult<
  GetInvoicesScreenQuery,
  GetInvoicesScreenQueryVariables
>;
export const RetryInvoice = gql`
  mutation RetryInvoice($invoiceId: ID!) {
    retryDriverInvoice(invoiceId: $invoiceId) {
      ...GetInvoicesScreen_invoice
    }
  }
  ${GetInvoicesScreen_invoiceFragmentDoc}
`;
export type RetryInvoiceMutationFn = Apollo.MutationFunction<
  RetryInvoiceMutation,
  RetryInvoiceMutationVariables
>;
export type RetryInvoiceMutationResult = Apollo.MutationResult<RetryInvoiceMutation>;
export type RetryInvoiceMutationOptions = Apollo.BaseMutationOptions<
  RetryInvoiceMutation,
  RetryInvoiceMutationVariables
>;
export const GetSelectedShiftMapLocation = gql`
  query GetSelectedShiftMapLocation($shiftId: ID!) {
    shift: getShift(shiftId: $shiftId) {
      vehicleLocation {
        lat
        lng
      }
    }
  }
`;
export type GetSelectedShiftMapLocationQueryResult = Apollo.QueryResult<
  GetSelectedShiftMapLocationQuery,
  GetSelectedShiftMapLocationQueryVariables
>;
export const GetBalancerMapConfig = gql`
  query GetBalancerMapConfig {
    config: getDriverAppConfig {
      balancerMap {
        calculateRelativeBalance
        showDeficit
        minDeficit
        maxDeficit
        deficitColorLight
        deficitColorDark
        showSurplus
        minSurplus
        maxSurplus
        surplusColorLight
        surplusColorDark
        minOpacity
        maxOpacity
      }
    }
  }
`;
export type GetBalancerMapConfigQueryResult = Apollo.QueryResult<
  GetBalancerMapConfigQuery,
  GetBalancerMapConfigQueryVariables
>;
export const GetBalancerMap = gql`
  query GetBalancerMap {
    getBalancerMap
  }
`;
export type GetBalancerMapQueryResult = Apollo.QueryResult<
  GetBalancerMapQuery,
  GetBalancerMapQueryVariables
>;
export const GetSelectedShiftMapRoute = gql`
  query GetSelectedShiftMapRoute($shiftId: ID!) {
    shift: getShift(shiftId: $shiftId) {
      id
      state
      issueType
      liveRouteUrl
    }
    stopPoints: getShiftStopPoints(shiftId: $shiftId, filters: { isActive: true }) {
      count
      results {
        id
        stopType
        assignedAt
        finalizedAt
        acknowledgedAt
        state
        stopType
        address
        location {
          lat
          lng
        }
      }
    }
  }
`;
export type GetSelectedShiftMapRouteQueryResult = Apollo.QueryResult<
  GetSelectedShiftMapRouteQuery,
  GetSelectedShiftMapRouteQueryVariables
>;
export const GetDriverMapsScreen = gql`
  query GetDriverMapsScreen($filters: DriverShiftFilters!) {
    shifts: getShifts(filters: $filters) {
      count
      results {
        ...ShiftMapMarker_shift
      }
    }
  }
  ${ShiftMapMarker_shiftFragmentDoc}
`;
export type GetDriverMapsScreenQueryResult = Apollo.QueryResult<
  GetDriverMapsScreenQuery,
  GetDriverMapsScreenQueryVariables
>;
export const GetDriversAndScheduleDetails = gql`
  query GetDriversAndScheduleDetails(
    $filters: DriverFilters!
    $ptoFilters: DriverTimeAwayFilters!
    $shiftFilters: DriverShiftFilters!
  ) {
    drivers: getDrivers(filters: $filters) {
      count
      results {
        id
        firstName
        lastName
        avatar
        createdAt
        updatedAt
        employmentType
        shifts(filters: $shiftFilters) {
          id
          state
          issueType
          scheduledStartAt
          scheduledEndAt
          actualStartAt
          actualEndAt
          scheduledRecallAt
          breaks {
            id
            type
            scheduledStartAt
            scheduledEndAt
          }
        }
        timeAway(filters: $ptoFilters) {
          id
          scheduledStartAt
          scheduledEndAt
          status
        }
      }
    }
  }
`;
export type GetDriversAndScheduleDetailsQueryResult = Apollo.QueryResult<
  GetDriversAndScheduleDetailsQuery,
  GetDriversAndScheduleDetailsQueryVariables
>;
export const SearchForDrivers = gql`
  query SearchForDrivers($filters: DriverOpsDriversFilters) {
    getDriverOpsDrivers(filters: $filters) {
      results {
        id
        avatar
        firstName
        lastName
      }
    }
  }
`;
export type SearchForDriversQueryResult = Apollo.QueryResult<
  SearchForDriversQuery,
  SearchForDriversQueryVariables
>;
export const ManipulateStopPoint = gql`
  mutation ManipulateStopPoint($stopPointId: ID!, $manipulationType: StopPointManipulationTypes!) {
    stopPoint: manipulateStopPoint(stopPointId: $stopPointId, manipulationType: $manipulationType) {
      id
    }
  }
`;
export type ManipulateStopPointMutationFn = Apollo.MutationFunction<
  ManipulateStopPointMutation,
  ManipulateStopPointMutationVariables
>;
export type ManipulateStopPointMutationResult = Apollo.MutationResult<ManipulateStopPointMutation>;
export type ManipulateStopPointMutationOptions = Apollo.BaseMutationOptions<
  ManipulateStopPointMutation,
  ManipulateStopPointMutationVariables
>;
export const GetShiftStopPoints = gql`
  query GetShiftStopPoints($shiftId: ID!, $filters: ShiftStopPointsFilter) {
    stopPoints: getShiftStopPoints(shiftId: $shiftId, filters: $filters) {
      count
      results {
        id
        stopType
        assignedAt
        finalizedAt
        acknowledgedAt
        state
        stopType
        consumerRideHailId
        address
        metadata {
          issueType
        }
        ...StopPointActionMenu_stopPoint
      }
    }
  }
  ${StopPointActionMenu_stopPointFragmentDoc}
`;
export type GetShiftStopPointsQueryResult = Apollo.QueryResult<
  GetShiftStopPointsQuery,
  GetShiftStopPointsQueryVariables
>;
export const GetDriverShiftsScreen = gql`
  query GetDriverShiftsScreen($filters: DriverShiftFilters!) {
    shifts: getShifts(filters: $filters) {
      count
      results {
        ...ShiftDriverInfoField_shift
        ...ShiftScheduledEnd_shift
        ...ShiftClockOut_shift
        ...DriverShiftArrival_shift
        ...ShiftActionShortcuts_shift
        ...ShiftActionDropdown_shift
        id
        driver {
          id
          firstName
          lastName
          avatar
        }
        state
        scheduledStartAt
        scheduledEndAt
        scheduledRecallAt
        reservationType
        clockIn
        clockOut
        resourceId
        fleetResourceMetadata {
          vehicle {
            id
            licensePlate
            batteryLevel
          }
        }
      }
    }
  }
  ${ShiftDriverInfoField_shiftFragmentDoc}
  ${ShiftScheduledEnd_shiftFragmentDoc}
  ${ShiftClockOut_shiftFragmentDoc}
  ${DriverShiftArrival_shiftFragmentDoc}
  ${ShiftActionShortcuts_shiftFragmentDoc}
  ${ShiftActionDropdown_shiftFragmentDoc}
`;
export type GetDriverShiftsScreenQueryResult = Apollo.QueryResult<
  GetDriverShiftsScreenQuery,
  GetDriverShiftsScreenQueryVariables
>;
export const GetShiftResourceMetadata = gql`
  query GetShiftResourceMetadata($shiftId: ID!) {
    getShiftResourceMetadata(shiftId: $shiftId) {
      results {
        id
        resourceId
        licensePlate
        driverReachedVehicleAt
        resourceAssignedAt
        resourceRemovedAt
      }
    }
  }
`;
export type GetShiftResourceMetadataQueryResult = Apollo.QueryResult<
  GetShiftResourceMetadataQuery,
  GetShiftResourceMetadataQueryVariables
>;
export const GetDriverFleetResource = gql`
  query GetDriverFleetResource($fleetResourceId: ID!) {
    vehicle: getDriverFleetResource(fleetResourceId: $fleetResourceId) {
      ...VehicleInfoPanel_vehicle
    }
  }
  ${VehicleInfoPanel_vehicleFragmentDoc}
`;
export type GetDriverFleetResourceQueryResult = Apollo.QueryResult<
  GetDriverFleetResourceQuery,
  GetDriverFleetResourceQueryVariables
>;
export const GetVehiclesScreen = gql`
  query GetVehiclesScreen($filters: DriverFleetResourceFilters!) {
    vehicles: getDriverFleetResources(filters: $filters) {
      count
      results {
        id
        licensePlate
        detail {
          make
          model
        }
        vin
        batteryLevel
        status
        location {
          lat
          lng
        }
        updated
      }
    }
  }
`;
export type GetVehiclesScreenQueryResult = Apollo.QueryResult<
  GetVehiclesScreenQuery,
  GetVehiclesScreenQueryVariables
>;
export const GetVehicleCounts = gql`
  query GetVehicleCounts($filters: DriverFleetResourceCountFilters) {
    getDriverFleetResourceCount(filters: $filters)
  }
`;
export type GetVehicleCountsQueryResult = Apollo.QueryResult<
  GetVehicleCountsQuery,
  GetVehicleCountsQueryVariables
>;
export const ZonePageZones = gql`
  query ZonePageZones($filters: ZoneFilters!) {
    zones: getZones(filters: $filters) {
      results {
        zoneType
        ...ZoneGroup_zone
      }
    }
  }
  ${ZoneGroup_zoneFragmentDoc}
`;
export type ZonePageZonesQueryResult = Apollo.QueryResult<
  ZonePageZonesQuery,
  ZonePageZonesQueryVariables
>;
export const GetNycSystemQuery = gql`
  query GetNycSystemQuery {
    nycSystem: getSystem(systemIdentifier: "newyork") {
      id
      settings {
        lowBatteryTagPercentage
      }
    }
  }
`;
export type GetNycSystemQueryQueryResult = Apollo.QueryResult<
  GetNycSystemQueryQuery,
  GetNycSystemQueryQueryVariables
>;
